/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppContext } from "../../../contexts/useStorageContext";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce } from "lodash";
import sampleRecordService from "../../../service/sampleRecord.service";
import {
  ISampleRecordBaseFields,
  ISampleRecordPopulated,
} from "../../../types/sample";
import Chip from "@mui/material/Chip";
import { InputAdornment, MenuItem } from "@mui/material";

const InputThresholdBeforeSearch = 1;
export interface ISampleIdSingleAutocompleteBaseProps {
  label?: string;
  onSelectValue: (id: ISampleRecordPopulated) => void;
  value?: ISampleRecordPopulated;
  error?: boolean;
  helperText?: any;
  companyId?: string;
  includeChemicals?: boolean;
  getErrorText?: (sample: ISampleRecordPopulated) => string;
  startIcon?: React.ReactNode;
}
type OmittedAutocompleteProps =
  | "value"
  | "onChange"
  | "renderInput"
  | "options";
const NewOptionPrefix = "New Sample ID: ";

export type ISampleIdSingleAutocompleteProps =
  ISampleIdSingleAutocompleteBaseProps &
    Omit<
      AutocompleteProps<ISampleRecordPopulated, true, false, true>,
      OmittedAutocompleteProps
    >;

export default function SampleIdSingleAutocomplete({
  value,
  onSelectValue,
  fullWidth = false,
  label,
  companyId,
  includeChemicals,
  getErrorText,
  startIcon,
  ...otherProps
}: ISampleIdSingleAutocompleteProps) {
  const [open, toggleOpen] = React.useState(false);
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [options, setOptions] = React.useState<ISampleRecordPopulated[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searching, setSearching] = React.useState(false);

  async function search(searchText: string): Promise<void> {
    if (searchText.length < InputThresholdBeforeSearch) return;
    setSearching(true);
    try {
      const result = await sampleRecordService.searchSamplesBySampleId(
        companyId || "",
        searchText,
        includeChemicals
      );
      if (result?.length) {
        setOptions(result);
      } else {
        setOptions([]);
      }
    } catch (error) {
      setSearching(false);
    }
    setSearching(false);
  }

  const debouncedSearch = debounce(search, 300);

  const handleSelectOption = (val: ISampleRecordPopulated) => {
    onSelectValue(val);
  };

  return (
    <>
      <Autocomplete<any, any, any, any>
        value={value}
        {...otherProps}
        options={options}
        clearOnBlur
        onChange={(e, v) => {
          handleSelectOption(v as ISampleRecordPopulated);
        }}
        getOptionLabel={(option) =>
          (option as ISampleRecordPopulated)?.sampleNumber || ""
        }
        renderOption={(props, option) => {
          const errorText = getErrorText?.(option);
          return (
            <MenuItem
              {...props}
              sx={{ p: 0, borderTop: "solid 1px #ddd" }}
              disabled={!!errorText}
            >
              <div style={{ flex: 1 }}>
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                  <Box>{option?.sampleNumber || ""}</Box>
                  <div style={{ flex: 1 }} />
                  <Chip label={option.company?.name || ""} size="small" />
                </div>
                {errorText ? (
                  <Box sx={{ flex: 1, color: "error.main", fontSize: "12px" }}>
                    {errorText}
                  </Box>
                ) : null}
              </div>
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <Box
            sx={{ display: "flex", alignItems: "center", position: "relative" }}
          >
            <TextField
              {...params}
              label={label}
              fullWidth
              placeholder={otherProps.placeholder}
              size="small"
              error={otherProps.error}
              helperText={otherProps.helperText}
              focused={value ? true : undefined}
              InputProps={{
                ...params.InputProps,
                startAdornment: startIcon ? (
                  <InputAdornment position="start">{startIcon}</InputAdornment>
                ) : null,
              }}
            />
          </Box>
        )}
        onInputChange={(
          event: React.ChangeEvent<unknown>,
          searchText: string,
          reason: "input" | "reset" | "clear"
        ) => {
          if (
            searchText.length >= InputThresholdBeforeSearch &&
            reason === "input"
          ) {
            debouncedSearch(searchText);
            setSearchTerm(searchText);
          }
        }}
        filterOptions={(options, params) => {
          return options;
        }}
        popupIcon={
          searching ? (
            <CircularProgress size={25} color="primary" />
          ) : (
            <ExpandMoreIcon />
          )
        }
      />
    </>
  );
}
