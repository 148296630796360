export enum MathOperators {
  Add = "+",
  Subtract = "-",
  Multiply = "*",
  Divide = "/",
}

export interface IChemicalCondition {
  chemicalFieldName: string;
  maxValue?: number;
  minValue?: number;
}

export interface IAdditiveEffect {
  chemicalFieldName: string;
  operator: MathOperators;
  isPercentage: boolean;
  value: number;
}

export interface IConditionSet {
  chemicalConditions: IChemicalCondition[];
  additionalCondition?: string;
  specificEffects?: IAdditiveEffect[];
}

export interface IAdditiveEffectAlgorithm {
  _id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  conditionSets: IConditionSet[];
  chemicalConditionTolerancePercentage: number;
  additiveEffects: IAdditiveEffect[];
}
