/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import sampleRecordService from "../../../service/sampleRecord.service";
import { ISampleRecordCreationPayload } from "../../../types/sample";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ICompany } from "../../../types/company";
import CompanyAutoComplete from "../Common/CompanyAutoComplete";
import { useAppContext } from "../../../contexts/useStorageContext";
import { UserRoles } from "../../../types/auth";
import { getIntakeRecordDateString } from "../../../helpers/formatTime";
import SampleMaterialTypeSelect from "../Common/SampleMaterialTypeSelect";
import DebouncedTextInput from "../Common/DebouncedTextInput";
import { getCoordinates } from "../../../service/geocode.service";
import ProgressButton from "../Common/ProgressButton";
import { showSnackbar } from "../../../service/snackbar.service";
import { IFarm } from "../../../types/intake/farm";
import SampleSourceAutoComplete from "../Common/SampleSourceAutoComplete";
import { ISampleSource } from "../../../types/sampleSource";

export default function AddNewSampleQuickActionForm() {
  const { state } = useAppContext(); // Accessing global state
  const { myClients, loggedInUserInfo, myCompanyProfile } = state;
  const [labCompany, setLabCompany] = React.useState<ICompany | undefined>(
    myCompanyProfile
  );
  const [sampleSource, setSampleSource] = React.useState<ISampleSource>();
  const [clientCompany, setClientCompany] = React.useState<ICompany>();
  const [formData, setFormData] = React.useState<ISampleRecordCreationPayload>({
    sampleTakenDate: getIntakeRecordDateString(new Date()),
  });
  const [checkingExistence, setCheckingExistence] = React.useState(false);
  const [existingSampleInfo, setExistingSampleInfo] = React.useState<any>();
  const [coordinates, setCoordinates] = React.useState<any>();
  const [checkingCoordinates, setCheckingCoordinates] = React.useState(false);
  const [addingSample, setAddingSample] = React.useState(false);
  const [elementKey, setElementKey] = React.useState(0);

  const handleCheckExistence = async () => {
    if (!formData.sampleNumber || !clientCompany?._id) return;
    setCheckingExistence(true);
    const result = await sampleRecordService.checkSampleExistence(
      clientCompany?._id,
      [formData.sampleNumber]
    );
    if (result?.existingSamples?.length) {
      setExistingSampleInfo(result?.existingSamples[0]);
    } else {
      setExistingSampleInfo(undefined);
    }
    setFormData({
      ...formData,
      companyUid:
        (result.maxCompanyUid || 0) < 1 ? 1 : (result.maxCompanyUid || 0) + 1,
    });
    setCheckingExistence(false);
  };
  const validateCountryAndRegion = async () => {
    try {
      if (!formData.country) return;
      setCheckingCoordinates(true);
      const coordinates = await getCoordinates(
        formData.country,
        formData.region || ""
      );
      setCoordinates(coordinates);
      setCheckingCoordinates(false);
    } catch (error) {
      setCoordinates(undefined);
      setCheckingCoordinates(false);
    }
  };

  const handleAddSample = async () => {
    if (!formData.sampleNumber || !clientCompany?._id || !labCompany?._id)
      return;
    setAddingSample(true);
    const result = await sampleRecordService.saveSampleRecords({
      companyId: labCompany?._id,
      clientId: clientCompany._id,
      newRecords: [formData],
      dataField: "chemicals",
    });
    setAddingSample(false);
    setFormData({
      sampleTakenDate: getIntakeRecordDateString(new Date()),
    });
    setClientCompany(undefined);
    setExistingSampleInfo(undefined);
    setCoordinates(undefined);
    setElementKey(elementKey + 1);
  };

  React.useEffect(() => {
    if (!formData.sampleNumber || !clientCompany) return;
    handleCheckExistence();
  }, [clientCompany]);

  React.useEffect(() => {
    if (!formData.country) return;
    validateCountryAndRegion();
  }, [formData.country, formData.region]);

  return (
    <div>
      <Stack spacing={2}>
        <CompanyAutoComplete
          fullWidth
          onlyLab
          label={"Lab *"}
          value={labCompany}
          onChange={(selected) => setLabCompany(selected || undefined)}
        />
        {labCompany ? (
          <CompanyAutoComplete
            fullWidth
            enabled={!!clientCompany}
            limitedOptions={
              loggedInUserInfo?.role &&
              [UserRoles.superadmin, UserRoles.adminStaff].includes(
                loggedInUserInfo.role
              )
                ? undefined
                : myClients
            }
            definedVendor={labCompany}
            label={"Client *"}
            value={clientCompany}
            onChange={(selected) => setClientCompany(selected || undefined)}
            isClientAutoComplete
          />
        ) : null}

        <SampleSourceAutoComplete
          fullWidth
          value={sampleSource}
          onChange={(selected) => {
            setSampleSource(selected || undefined);
            setFormData({
              ...formData,
              sampleSource: selected?.name,
            });
          }}
          label={"Farm *"}
        />

        <TextField
          size="small"
          fullWidth
          label={"Sample Number *"}
          value={formData.sampleNumber || ""}
          onChange={(e) =>
            setFormData({ ...formData, sampleNumber: e.target.value })
          }
          onBlur={handleCheckExistence}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {checkingExistence ? <CircularProgress size={20} /> : null}
              </InputAdornment>
            ),
          }}
          error={!!existingSampleInfo}
          helperText={
            existingSampleInfo
              ? `"${clientCompany?.name}" already has a sample with this number`
              : ""
          }
        />
        <TextField
          size="small"
          multiline
          rows={3}
          fullWidth
          label={"Sample Description *"}
          value={formData.description || ""}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            fullWidth
            label={"Wet Sample Weight (g) *"}
            value={formData.wetSampleWeight || ""}
            onChange={(e) =>
              setFormData({
                ...formData,
                wetSampleWeight:
                  e.target.value == "" ? undefined : Number(e.target.value),
              })
            }
          />

          <TextField
            size="small"
            fullWidth
            label={"Pan Weight (g) *"}
            value={formData.panWeight || ""}
            onChange={(e) =>
              setFormData({
                ...formData,
                panWeight:
                  e.target.value == "" ? undefined : Number(e.target.value),
              })
            }
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <SampleMaterialTypeSelect
            value={formData.type || undefined}
            onChange={(selected) =>
              setFormData({ ...formData, type: selected })
            }
            label="Crop Type *"
          />
          {/* <TextField
            size="small"
            type="number"
            fullWidth
            label={"Bag Weight (mg) *"}
            value={formData.bagWeight || ""}
            onChange={(e) =>
              setFormData({
                ...formData,
                bagWeight:
                  e.target.value != "" ? Number(e.target.value) : undefined,
              })
            }
          /> */}
        </Stack>
        <Box>
          <Stack
            direction="row"
            spacing={2}
            key={`${elementKey}-country-region`}
          >
            <DebouncedTextInput
              size="small"
              fullWidth
              label={"Country *"}
              value={formData.country || ""}
              onChange={(v) => setFormData({ ...formData, country: v })}
            />
            <DebouncedTextInput
              size="small"
              fullWidth
              label={"Region"}
              value={formData.region || ""}
              onChange={(v) => setFormData({ ...formData, region: v })}
            />
          </Stack>
          {formData.country && !checkingCoordinates && !coordinates ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="caption" color="error">
                Not recognized as a valid country and region
              </Typography>
            </Box>
          ) : null}
        </Box>

        <TextField
          size="small"
          fullWidth
          label={"Product"}
          value={formData.product || ""}
          onChange={(e) =>
            setFormData({ ...formData, product: e.target.value })
          }
        />
        <TextField
          size="small"
          fullWidth
          label={"Application"}
          value={formData.application || ""}
          onChange={(e) =>
            setFormData({ ...formData, application: e.target.value })
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ProgressButton
            disabled={
              !formData.sampleNumber ||
              !formData.description ||
              !formData.country ||
              !formData.type ||
              !formData.bagWeight ||
              !clientCompany ||
              !formData.wetSampleWeight ||
              !formData.panWeight ||
              !formData.sampleSource
            }
            title="Add Sample"
            onClick={handleAddSample}
            inProgress={addingSample}
          />
        </Box>
      </Stack>
    </div>
  );
}
