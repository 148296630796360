/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

export const GeneralPopoverWrapperCloseTrigger =
  "GeneralPopoverWrapperCloseTrigger";

export default function GeneralPopoverWrapper({
  transformVertical,
  transformHorizontal,
  anchorVertical,
  anchorHorizontal,
  triggerElement,
  popoverContent,
  tooltipTitle,
  closeOnClickContent,
  disableCloseOnClickOutside,
  onClickTriggerElement,
  disablePop,
  onClose,
  transitionDuration,
  stopPropagationOnClick,
  fullWidth,
}: {
  transformVertical?: "top" | "bottom" | "center";
  transformHorizontal?: "center" | "right" | "left";
  anchorVertical?: "top" | "bottom" | "center";
  anchorHorizontal?: "center" | "right" | "left";
  triggerElement?: JSX.Element;
  popoverContent?: JSX.Element;
  tooltipTitle?: string;
  closeOnClickContent?: boolean;
  disableCloseOnClickOutside?: boolean;
  onClickTriggerElement?: () => void;
  disablePop?: boolean;
  onClose?: () => void;
  transitionDuration?: number;
  stopPropagationOnClick?: boolean;
  fullWidth?: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    if (disablePop) return;
    if (stopPropagationOnClick) event.stopPropagation();
    onClickTriggerElement && onClickTriggerElement();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title={tooltipTitle} arrow placement="top">
        <Box
          onClick={handleClick}
          aria-describedby={id}
          sx={{
            flex: fullWidth ? 1 : undefined,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {triggerElement}
        </Box>
      </Tooltip>

      <Popover
        transitionDuration={
          transitionDuration != undefined ? transitionDuration : undefined
        }
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={!disableCloseOnClickOutside ? handleClose : undefined}
        anchorOrigin={{
          vertical: anchorVertical || "bottom",
          horizontal: anchorHorizontal || "left",
        }}
        transformOrigin={{
          vertical: transformVertical || "top",
          horizontal: transformHorizontal || "left",
        }}
      >
        <Box
          onClick={
            closeOnClickContent
              ? handleClose
              : (e) => {
                  const className: string = (e.target as any).className;
                  className?.includes &&
                    className?.includes(GeneralPopoverWrapperCloseTrigger) &&
                    handleClose();
                }
          }
        >
          {popoverContent}
        </Box>
      </Popover>
    </Box>
  );
}
