/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import WhitePanel from "../../components/Common/WhitePanel";
import AdditiveAlgorithmsManagement from "../../components/AdditiveAlgorithm/AdditiveAlgorithmsManagement";

export default function AdditiveAlgorithmsPage() {
  return (
    <>
      <WhitePanel>
        <AdditiveAlgorithmsManagement />
      </WhitePanel>
    </>
  );
}
