import { ISampleRecordUpdatePayload } from "../service/sampleRecord.service";
import { ChemicalFields } from "../types/chemicals";
import { ISampleRecordPopulated } from "../types/sample";

export const getSampleRecordUpdates = (
  originalSampleRecord: ISampleRecordPopulated,
  updatedRecord: ISampleRecordPopulated
) => {
  const updates: ISampleRecordUpdatePayload[] = [];
  if (!originalSampleRecord) return [];
  (
    [
      "sampleClient",
      "sampleSource",
      "dataSource",
      "sampleTakenDate",
      "bagWeight",
      "drySampleWeight",
      "bagWeight48",
      "panWeight",
      "wetSampleWeight",
      "dietRegion",
      "dataset",
      "reportType",
      "country",
      "region",
      "year",
      "product",
      "application",
      "description",
    ] as (keyof ISampleRecordPopulated)[]
  ).map((fieldName) => {
    if (
      (updatedRecord[fieldName] || "") !=
      (originalSampleRecord[fieldName] || "")
    ) {
      updates.push({
        fieldName,
        oldValue: originalSampleRecord[fieldName],
        newValue: updatedRecord[fieldName],
      });
    }
  });
  ChemicalFields.map((chemField) => {
    if (
      (updatedRecord.chemicals?.[chemField.fieldName] || "") !=
      (originalSampleRecord.chemicals?.[chemField.fieldName] || "")
    ) {
      updates.push({
        isChemical: true,
        fieldName: chemField.fieldName,
        oldValue: originalSampleRecord.chemicals?.[chemField.fieldName],
        newValue: updatedRecord.chemicals?.[chemField.fieldName],
      });
    }
  });
  return updates;
};
