/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";
import { IAdditiveEffectAlgorithm } from "../types/additiveAlgorithm";

const routePrefix = "datalake/additive-effect-algorithm";

export default {
  async getAdditiveAlgorithms(): Promise<IAdditiveEffectAlgorithm[]> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      throw error;
    }
  },

  async addAdditiveAlgorithm(
    algorithmData: IAdditiveEffectAlgorithm
  ): Promise<IAdditiveEffectAlgorithm> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/create`,
        algorithmData
      );
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Additive algorithm successfully created"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      throw error;
    }
  },

  async updateAdditiveAlgorithm(
    algorithmData: IAdditiveEffectAlgorithm
  ): Promise<IAdditiveEffectAlgorithm | null> {
    try {
      const result = await backendApi()?.put(
        `${routePrefix}/update`,
        algorithmData
      );
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Additive algorithm successfully updated"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      throw error;
    }
  },

  async deleteAdditiveAlgorithm(id: string): Promise<boolean> {
    try {
      const result = await backendApi()?.delete(`${routePrefix}/delete/${id}`);
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Additive algorithm successfully deleted"
      );
      return true;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      throw error;
    }
  },
};
