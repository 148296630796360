import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IConditionSet } from "../../../types/additiveAlgorithm";
import { useState } from "react";
import { ChemicalFields } from "../../../types/chemicals";
import { cloneDeep } from "lodash";

export default function AdditiveConditionSetForm({
  initialConditionSet,
  onSave,
}: {
  initialConditionSet?: IConditionSet;
  onSave: (conditionSet: IConditionSet) => void;
}) {
  const [conditionSet, setConditionSet] = useState<Partial<IConditionSet>>(
    initialConditionSet || {
      chemicalConditions: [],
    }
  );
  return (
    <Stack>
      <Stack spacing={2}>
        <Stack spacing={1}>
          {conditionSet.chemicalConditions?.map((condition, index) => (
            <Box
              key={`${condition.chemicalFieldName}-${index}`}
              sx={{
                display: "flex",
              }}
            >
              <div style={{ flex: 3, marginRight: "8px" }}>
                <Autocomplete
                  fullWidth
                  value={condition?.chemicalFieldName ?? ""}
                  onChange={(e, val) => {
                    setConditionSet({
                      ...conditionSet,
                      chemicalConditions: conditionSet.chemicalConditions?.map(
                        (c, i) =>
                          i === index
                            ? { ...c, chemicalFieldName: val || "" }
                            : c
                      ),
                    });
                  }}
                  getOptionDisabled={(option) =>
                    (conditionSet.chemicalConditions || []).some(
                      (c) => c.chemicalFieldName === option
                    )
                  }
                  options={ChemicalFields.map((f) => f.fieldName)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Field name"
                      size="small"
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </div>
              <div style={{ flex: 2, marginRight: "8px" }}>
                <TextField
                  fullWidth
                  label="Min"
                  type="number"
                  size="small"
                  value={condition?.minValue || ""}
                  onChange={(e) =>
                    setConditionSet({
                      ...conditionSet,
                      chemicalConditions: conditionSet.chemicalConditions?.map(
                        (c, i) =>
                          i === index
                            ? {
                                ...c,
                                minValue: e.target.value?.length
                                  ? Number(e.target.value)
                                  : undefined,
                              }
                            : c
                      ),
                    })
                  }
                />
              </div>
              <div style={{ flex: 2 }}>
                <TextField
                  fullWidth
                  label="Max"
                  type="number"
                  size="small"
                  value={condition?.maxValue || ""}
                  onChange={(e) =>
                    setConditionSet({
                      ...conditionSet,
                      chemicalConditions: conditionSet.chemicalConditions?.map(
                        (c, i) =>
                          i === index
                            ? {
                                ...c,
                                maxValue: e.target.value?.length
                                  ? Number(e.target.value)
                                  : undefined,
                              }
                            : c
                      ),
                    })
                  }
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    const tempConditionSet = cloneDeep(conditionSet);
                    const updated = tempConditionSet.chemicalConditions?.filter(
                      (_, i) => i !== index
                    );
                    setConditionSet({
                      ...conditionSet,
                      chemicalConditions: updated,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Box>
          ))}
        </Stack>
        <Button
          onClick={() => {
            setConditionSet({
              ...conditionSet,
              chemicalConditions: [
                ...(conditionSet.chemicalConditions || []),
                {
                  chemicalFieldName: "",
                  minValue: undefined,
                  maxValue: undefined,
                },
              ],
            });
          }}
          fullWidth
          size="small"
          sx={{
            background: "#eee",
            ":hover": {
              backgroundColor: "#ddd",
            },
          }}
        >
          Add Field
        </Button>
        <TextField
          multiline
          rows={2}
          fullWidth
          label="Additional Condition"
          size="small"
          value={conditionSet.additionalCondition || ""}
          onChange={(e) =>
            setConditionSet({
              ...conditionSet,
              additionalCondition: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          disabled={!conditionSet?.chemicalConditions?.length}
          fullWidth
          size="small"
          sx={{ my: 1 }}
          onClick={() => {
            onSave({
              ...conditionSet,
              chemicalConditions: conditionSet.chemicalConditions?.filter(
                (c) => c.chemicalFieldName && (c.maxValue || c.minValue)
              ),
            } as IConditionSet);
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
}
