/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import TaskIcon from "@mui/icons-material/Task";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  IResearchLabDevice,
  ResearchLabDeviceType,
} from "../../../types/researchLabSchedule";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import researchLabScheduleService from "../../../service/researchLabSchedule.service";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddIconButton from "../Common/AddIconButton";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import LiquorIcon from "@mui/icons-material/Liquor";
import DeviceTypeSelection from "./DeviceTypeSelection";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ConfirmDialog from "../Common/ConfirmDialog";
import { cloneDeep, sortBy, uniqBy } from "lodash";
import ToNowHoverTime from "../Common/ToNowHoverTime";
import AttachedFileItem, { AddFileBox } from "../Common/AttachedFileItem";
import AnyAssetFileUploader, {
  AwsLocation,
} from "../Common/AnyAssetFileUploader";
import ProgressButton from "../Common/ProgressButton";

const findNextTypeIndex = (
  devices: IResearchLabDevice[],
  type: ResearchLabDeviceType
) => {
  const maxTypeIndex = devices
    .filter((d) => d.type == type)
    .reduce((max, device) => {
      return Math.max(max, device.typeIndex || 0);
    }, 0);
  let nextTypeIndex = maxTypeIndex + 1;
  for (let i = 1; i < maxTypeIndex; i++) {
    if (!devices.filter((d) => d.type == type).find((d) => d.typeIndex == i)) {
      nextTypeIndex = i;
      break;
    }
  }
  return nextTypeIndex;
};

export default function LabDeviceManagement() {
  const [devices, setDevices] = useState<IResearchLabDevice[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addingDevice, setAddingDevice] =
    useState<Partial<IResearchLabDevice>>();
  const [popoverKey, setPopoverKey] = useState<number>(0);
  const [editingDevice, setEditingDevice] = useState<IResearchLabDevice>();
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openFilesUploadDialog, setOpenFilesUploadDialog] =
    React.useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [bulkCalibrationDate, setBulkCalibrationDate] = useState<Date>();
  const [updatingBulkCalibrationDate, setUpdatingBulkCalibrationDate] =
    useState(false);

  const handleSaveEditingDevice = async () => {
    if (!editingDevice) return;
    setIsLoading(true);
    await researchLabScheduleService.updateLabDevice(
      editingDevice as IResearchLabDevice
    );
    setEditingDevice(undefined);
    setIsLoading(false);
    getLabDevices();
  };

  const handleDeleteDevice = async () => {
    if (!editingDevice?._id) return;
    setIsLoading(true);
    await researchLabScheduleService.deleteLabDevice(editingDevice._id);
    setEditingDevice(undefined);
    setOpenDeleteConfirmDialog(false);
    setIsLoading(false);
    getLabDevices();
  };

  const updateBulkCalibrationDate = async () => {
    if (!bulkCalibrationDate) return;
    setUpdatingBulkCalibrationDate(true);
    await researchLabScheduleService.updateLastCalibratedDateOfMultipleDevices(
      selectedIds,
      bulkCalibrationDate
    );
    setUpdatingBulkCalibrationDate(false);
    setPopoverKey(popoverKey + 1);
    getLabDevices();
  };

  const columns = [
    {
      field: "type",
      headerName: "Type",
      width: 70,
      valueGetter: (params: any) =>
        Object.keys(ResearchLabDeviceType)[
          Object.values(ResearchLabDeviceType).findIndex(
            (v) => v == params.value
          )
        ],
    },
    {
      field: "typeIndex",
      headerName: "Type #",
      width: 70,
      renderCell: (params: any) => {
        return editingDevice?._id == params.row._id ? (
          <TextField
            sx={{
              "& input::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            size="small"
            type="number"
            fullWidth
            value={editingDevice?.typeIndex || ""}
            onChange={(e) =>
              setEditingDevice({
                ...(editingDevice || {}),
                typeIndex: e.target.value
                  ? parseInt(e.target.value)
                  : undefined,
              } as IResearchLabDevice)
            }
            error={
              !!devices.find(
                (d) =>
                  d._id != editingDevice?._id &&
                  d.type == editingDevice?.type &&
                  d.typeIndex == editingDevice?.typeIndex
              )
            }
          />
        ) : (
          <>{params.value}</>
        );
      },
    },
    {
      field: "deviceId",
      headerName: "Device ID",
      flex: 1,
      renderCell: (params: any) => {
        return editingDevice?._id == params.row._id ? (
          <TextField
            size="small"
            fullWidth
            value={editingDevice?.deviceId || ""}
            onChange={(e) =>
              setEditingDevice({
                ...(editingDevice || {}),
                deviceId: e.target.value,
              } as IResearchLabDevice)
            }
            error={
              !!devices.find(
                (d) =>
                  d._id != editingDevice?._id &&
                  d.deviceId == editingDevice?.deviceId
              )
            }
          />
        ) : (
          <>{params.value}</>
        );
      },
    },
    {
      field: "lastCalibratedDate",
      headerName: "Last Calibrated Date",
      flex: 1,
      renderCell: (params: any) => {
        return editingDevice?._id == params.row._id ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={`Upload ${
                params.row.calibrationFiles?.length ? "Updated" : ""
              } Calibration File`}
              placement="top"
              arrow
            >
              <Box
                mr={1}
                sx={{
                  background: "#e4e4e4",
                  padding: "0px 4px",
                  paddingTop: "8px",
                  paddingBottom: "4px",
                  borderRadius: "5px",
                }}
              >
                {editingDevice?.calibrationFiles?.length ? (
                  editingDevice?.calibrationFiles?.length ==
                  params.row.calibrationFiles?.length ? (
                    <DescriptionIcon
                      onClick={() => {
                        setOpenFilesUploadDialog(true);
                      }}
                      sx={{ cursor: "pointer", opacity: 0.7 }}
                    />
                  ) : (
                    <TaskIcon
                      onClick={() => {
                        setOpenFilesUploadDialog(true);
                      }}
                      sx={{ cursor: "pointer", opacity: 0.7 }}
                    />
                  )
                ) : (
                  <UploadFileIcon
                    onClick={() => {
                      setOpenFilesUploadDialog(true);
                    }}
                    sx={{ cursor: "pointer", opacity: 0.7 }}
                  />
                )}
              </Box>
            </Tooltip>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Last Calibrated Date"
                value={
                  !editingDevice?.lastCalibratedDate
                    ? null
                    : dayjs(editingDevice.lastCalibratedDate)
                }
                onChange={(newValue) =>
                  setEditingDevice({
                    ...(editingDevice || {}),
                    lastCalibratedDate: newValue?.toDate(),
                  } as IResearchLabDevice)
                }
                slotProps={{
                  textField: { size: "small", fullWidth: true },
                }}
              />
            </LocalizationProvider>
          </Box>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.row.calibrationFiles?.length ? (
              <GeneralPopoverWrapper
                triggerElement={
                  <DescriptionIcon
                    sx={{ cursor: "pointer", opacity: 0.7, mr: 1 }}
                  />
                }
                popoverContent={
                  <AttachedFileItem
                    url={
                      params.row.calibrationFiles[
                        params.row.calibrationFiles.length - 1
                      ]
                    }
                    mb={8}
                  />
                }
              />
            ) : null}
            {params.value ? (
              <Chip
                label={<ToNowHoverTime time={params.value} />}
                size="small"
                variant={
                  params.value && dayjs().diff(dayjs(params.value), "day") <= 90
                    ? "outlined"
                    : undefined
                }
                color={
                  dayjs().diff(dayjs(params.value), "day") > 90
                    ? "error"
                    : dayjs().diff(dayjs(params.value), "day") > 80
                    ? "warning"
                    : "success"
                }
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {editingDevice?._id == params.row._id ? (
              <IconButton
                color="primary"
                disabled={
                  !editingDevice?.deviceId ||
                  !editingDevice?.typeIndex ||
                  !!devices.find(
                    (d) =>
                      d._id != editingDevice?._id &&
                      d.deviceId == editingDevice?.deviceId
                  ) ||
                  !!devices.find(
                    (d) =>
                      d._id != editingDevice?._id &&
                      d.type == editingDevice?.type &&
                      d.typeIndex == editingDevice?.typeIndex
                  )
                }
                onClick={() => handleSaveEditingDevice()}
              >
                <SaveIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => setEditingDevice(params.row)}>
                <EditIcon />
              </IconButton>
            )}
            {editingDevice?._id == params.row._id ? (
              <Tooltip title="Cancel Edition" placement="top" arrow>
                <IconButton onClick={() => setEditingDevice(undefined)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                sx={{ ml: 1 }}
                onClick={() => {
                  setEditingDevice(params.row);
                  setOpenDeleteConfirmDialog(true);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const getLabDevices = async () => {
    setIsLoading(true);
    const devices = await researchLabScheduleService.getLabDevices();
    setDevices(sortBy(devices, "type", "typeIndex"));
    setIsLoading(false);
  };

  const handleAddDevice = async () => {
    if (!addingDevice?.type || !addingDevice?.deviceId) return;
    setPopoverKey(popoverKey + 1);
    setIsLoading(true);
    await researchLabScheduleService.addLabDevice(
      cloneDeep(addingDevice) as IResearchLabDevice
    );
    setAddingDevice(undefined);
    setIsLoading(false);
    getLabDevices();
  };

  const addCalibrationFilesToSelectedDevices = async (urls: string[]) => {
    setIsLoading(true);
    await researchLabScheduleService.addCalibrationFilesToDevices(
      selectedIds,
      urls
    );
    setIsLoading(false);
    getLabDevices();
  };

  useEffect(() => {
    if (addingDevice?.type) {
      setAddingDevice({
        ...(addingDevice || {}),
        typeIndex: findNextTypeIndex(devices, addingDevice.type),
      });
    }
  }, [addingDevice?.type, devices]);

  useEffect(() => {
    getLabDevices();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <GeneralPopoverWrapper
          key={popoverKey}
          triggerElement={
            <AddIconButton
              onClick={() => {
                setAddingDevice({});
                setEditingDevice(undefined);
              }}
            />
          }
          popoverContent={
            <Box p={2} sx={{ minWidth: "250px" }}>
              <Stack spacing={2} direction={"column"}>
                <DeviceTypeSelection
                  onChange={(newValue) =>
                    setAddingDevice({
                      ...(addingDevice || {}),
                      type: newValue,
                    })
                  }
                  value={addingDevice?.type}
                />
                <TextField
                  size="small"
                  type="number"
                  label="Device Number In Type"
                  onChange={(e) =>
                    setAddingDevice({
                      ...(addingDevice || {}),
                      typeIndex: e.target.value
                        ? parseInt(e.target.value)
                        : undefined,
                    })
                  }
                  value={addingDevice?.typeIndex || ""}
                  error={
                    !!devices.find(
                      (d) =>
                        d.type == addingDevice?.type &&
                        d.typeIndex == addingDevice?.typeIndex
                    )
                  }
                  helperText={
                    !!devices.find(
                      (d) =>
                        d.type == addingDevice?.type &&
                        d.typeIndex == addingDevice?.typeIndex
                    )
                      ? "Device number in type already exists"
                      : ""
                  }
                />
                <TextField
                  size="small"
                  label="Device ID"
                  onChange={(e) =>
                    setAddingDevice({
                      ...(addingDevice || {}),
                      deviceId: e.target.value || "",
                    })
                  }
                  value={addingDevice?.deviceId}
                  error={
                    !!devices.find((d) => d.deviceId == addingDevice?.deviceId)
                  }
                  helperText={
                    devices.find((d) => d.deviceId == addingDevice?.deviceId)
                      ? "Device ID already exists"
                      : ""
                  }
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Last Calibration Date"
                    value={
                      addingDevice?.lastCalibratedDate
                        ? dayjs(addingDevice?.lastCalibratedDate)
                        : null
                    }
                    onChange={(newValue) =>
                      setAddingDevice({
                        ...(addingDevice || {}),
                        lastCalibratedDate: newValue?.toDate(),
                      })
                    }
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                  />
                </LocalizationProvider>
                {addingDevice?.calibrationFiles?.length ? (
                  addingDevice?.calibrationFiles?.map((f) => (
                    <AttachedFileItem
                      url={f}
                      onRemove={() =>
                        setAddingDevice({
                          ...(addingDevice || {}),
                          calibrationFiles: (
                            addingDevice?.calibrationFiles || []
                          ).filter((rf) => rf != f),
                        })
                      }
                      mb={8}
                    />
                  ))
                ) : (
                  <AddFileBox onClick={() => setOpenFilesUploadDialog(true)}>
                    <NoteAddIcon />
                  </AddFileBox>
                )}

                <Button
                  disabled={
                    !addingDevice?.type ||
                    !addingDevice?.deviceId ||
                    !addingDevice?.typeIndex ||
                    !!devices.find(
                      (d) => d.deviceId == addingDevice?.deviceId
                    ) ||
                    !!devices.find(
                      (d) =>
                        d.type == addingDevice?.type &&
                        d.typeIndex == addingDevice?.typeIndex
                    )
                  }
                  size="small"
                  variant="contained"
                  fullWidth
                  onClick={handleAddDevice}
                >
                  Add
                </Button>
              </Stack>
            </Box>
          }
        />
        <h2
          style={{
            margin: "0px 0px 0px 10px",
            display: "flex",
            alignItems: "center",
            opacity: 0.8,
          }}
        >
          Equipments
          <LiquorIcon sx={{ ml: "12px" }} />
        </h2>
        {selectedIds?.length ? (
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ ml: 2, display: "flex", alignItems: "center" }}
          >
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setEditingDevice(undefined);
                setAddingDevice(undefined);
                setOpenFilesUploadDialog(true);
              }}
            >
              Upload Calibration File
            </Button>
            <GeneralPopoverWrapper
              key={`bulk-calibration-date-popover-${popoverKey}`}
              triggerElement={
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setBulkCalibrationDate(undefined);
                  }}
                >
                  Update Calibration Date
                </Button>
              }
              popoverContent={
                <Box sx={{ minWidth: "200px", maxWidth: "230px", p: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Last Calibrated Date"
                      value={
                        !bulkCalibrationDate ? null : dayjs(bulkCalibrationDate)
                      }
                      onChange={(newValue) =>
                        setBulkCalibrationDate(newValue?.toDate())
                      }
                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                    />
                  </LocalizationProvider>
                  <ProgressButton
                    disabled={!bulkCalibrationDate}
                    sx={{ mt: 2 }}
                    fullWidth
                    title="Update Calibration Date"
                    inProgress={updatingBulkCalibrationDate}
                    onClick={() => {
                      updateBulkCalibrationDate();
                    }}
                  />
                </Box>
              }
            />
          </Stack>
        ) : null}
      </div>
      <Box
        sx={{
          "& .disabled-row": {
            backgroundColor: "#ff000011",
          },
          height: "calc(100vh - 200px)",
        }}
      >
        <DataGridPro
          checkboxSelection
          rowSelectionModel={selectedIds}
          onRowSelectionModelChange={(changed) =>
            setSelectedIds(changed as string[])
          }
          getRowClassName={(params) => {
            return params.row.lastCalibratedDate &&
              dayjs().diff(dayjs(params.row.lastCalibratedDate), "day") <= 90
              ? ""
              : "disabled-row";
          }}
          rows={devices}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
          getRowId={(row) => row._id as string}
        />
      </Box>
      {openDeleteConfirmDialog && (
        <ConfirmDialog
          onConfirm={handleDeleteDevice}
          onCancel={() => setOpenDeleteConfirmDialog(false)}
          title="Delete Device"
          content="Are you sure you want to delete this device?"
        />
      )}
      {openFilesUploadDialog && (
        <AnyAssetFileUploader
          open
          onClose={() => setOpenFilesUploadDialog(false)}
          onSave={(urls) => {
            if (editingDevice?._id) {
              setEditingDevice({
                ...(editingDevice || {}),
                calibrationFiles: uniqBy(
                  [...(editingDevice?.calibrationFiles || []), ...urls],
                  (v) => v
                ),
              });
            } else if (addingDevice) {
              setAddingDevice({
                ...(addingDevice || {}),
                calibrationFiles: uniqBy(
                  [...(addingDevice?.calibrationFiles || []), ...urls],
                  (v) => v
                ),
              });
            } else if (selectedIds?.length) {
              addCalibrationFilesToSelectedDevices(urls);
            }
            setOpenFilesUploadDialog(false);
          }}
          title="Upload Calibration Files"
          location={AwsLocation.OrugenDatalake}
          directory={`sensor-calibration-files`}
          multiple
        />
      )}
    </div>
  );
}
