/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { Layer } from "leaflet";
import {
  AnalysisValueRanges,
  AnalysisValueUnit,
  getChemValueColor,
} from "../Common/GasAnalysisReport/AnalysisConstants";
import ValueColorSlider from "../Common/GasAnalysisReport/Sliders/ValueColorSlider";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { IAdditiveEffectAlgorithm } from "../../../types/additiveAlgorithm";
import AdditiveAlgorithmAutocomplete from "../AdditiveAlgorithm/AdditiveAlgorithmAutocomplete";
import { DietRegions, ISampleRecordPopulated } from "../../../types/sample";
import { SampleRecordFilterParams } from "../../../types/common";
import sampleRecordService from "../../../service/sampleRecord.service";
import { cloneDeep, uniqBy } from "lodash";
import {
  checkAdditiveApplicableToSample,
  getAdditiveAppliedSampleRecord,
} from "../AdditiveAlgorithm/AdditiveAlgorithmUtils";
import { calculateAverage } from "../../../helpers/numbers";

export interface ChoroplethMapRegion {
  region: string;
  value: number;
  oldValue?: number;
  geojson?: any;
}

// Custom Info Control Component
const InfoControl: React.FC<{
  selectedRegion?: {
    region: string;
    value: number;
    oldValue?: number;
  } | null;
  errorMessage?: string;
  chemicalFieldName: string;
}> = ({ selectedRegion, errorMessage, chemicalFieldName }) => {
  const map = useMap();

  React.useEffect(() => {
    const infoControl = new L.Control({ position: "bottomleft" });

    infoControl.onAdd = () => {
      const div = L.DomUtil.create("div", "info");
      div.innerHTML = `<div style="min-width: 210px; display: flex; align-items: center; padding: 2px 8px; padding-top: 35px; background-color: white; border-radius: 5px; box-shadow: 0 0 15px rgba(0,0,0,0.2);">${
        selectedRegion
          ? `<div style="flex:1; margin-right: 8px;">${selectedRegion.region?.replaceAll(
              "::",
              " "
            )}</div>${
              selectedRegion.oldValue
                ? `<div style="margin-right: 8px; opacity: 0.5;">${selectedRegion.oldValue}</div>`
                : ""
            }
            
            <b>${selectedRegion.value}</b> 
            ${
              selectedRegion.value && AnalysisValueUnit[chemicalFieldName]
                ? `<span style="font-size: 12px; margin-left: 4px;">${AnalysisValueUnit[chemicalFieldName]}</span>`
                : ""
            }
            ${
              errorMessage
                ? `<div style="color: red; font-size: 12px; font-weight: 600; margin-left: 8px;">${errorMessage}</div>`
                : ""
            }`
          : "Hover over a region"
      }</div>`;
      return div;
    };

    infoControl.addTo(map);

    return () => {
      infoControl.remove();
    };
  }, [map, selectedRegion]);

  return null;
};

const ChemicalChoroplethMap: React.FC<{
  regions: ChoroplethMapRegion[];
  chemicalFieldName: any;
  center?: [number, number];
  zoom?: number;
  appliedAdditive?: IAdditiveEffectAlgorithm;
  onClose?: () => void;
  allAdditiveAlgorithms: IAdditiveEffectAlgorithm[];
  selectedSamples?: ISampleRecordPopulated[];
  filterModel?: SampleRecordFilterParams;
}> = ({
  regions,
  chemicalFieldName,
  center,
  zoom,
  onClose,
  appliedAdditive,
  allAdditiveAlgorithms,
  selectedSamples,
  filterModel,
}) => {
  const [selectedRegion, setSelectedRegion] = useState<{
    region: string;
    value: number;
    oldValue?: number;
  } | null>(null);
  const [selectedAdditive, setSelectedAdditive] = useState<
    IAdditiveEffectAlgorithm | undefined
  >(appliedAdditive);
  const [additiveAppliedStatus, setAdditiveAppliedStatus] = useState(true);
  const [samples, setSamples] = useState<ISampleRecordPopulated[]>([]);
  const [fetchingSamples, setFetchingSamples] = useState(false);

  const additiveConsideredRegions = React.useMemo(() => {
    if (!selectedAdditive || !additiveAppliedStatus || !samples.length) {
      return regions;
    }
    const affectedSamples = samples.map((sample) => {
      if (
        !checkAdditiveApplicableToSample(selectedAdditive, sample).satisfied
      ) {
        return sample;
      }
      return getAdditiveAppliedSampleRecord(selectedAdditive, sample);
    });
    return regions.map((region) => {
      const dietRegionValue = DietRegions.find(
        (dr) => dr.regionName == region.region
      )?.value;
      if (!dietRegionValue) return region;
      const samplesInThisRegion = affectedSamples.filter(
        (sample) => sample?.dietRegion == dietRegionValue
      );
      const averageValue = calculateAverage(
        samplesInThisRegion
          .filter((s) => s?.chemicals?.[chemicalFieldName])
          .map((sample) => sample?.chemicals?.[chemicalFieldName] as number),
        2
      );
      return {
        ...region,
        oldValue: region.value,
        value: averageValue,
      };
    });
  }, [regions, selectedAdditive, additiveAppliedStatus, samples]);

  // Function to determine color based on region value
  const getColor = (value: number): string => {
    return getChemValueColor(chemicalFieldName, value);
  };

  // Define onEachFeature to add interactivity
  const onEachFeature = (feature: any, layer: Layer) => {
    layer.on({
      mouseover: (e: any) => {
        setSelectedRegion({
          region: feature.region,
          value: feature.value,
          oldValue: feature.oldValue,
        });
        const layer = e.target;
        layer?.bringToFront && layer.bringToFront();
      },
      mouseout: (e: any) => {
        setSelectedRegion(null);
      },
    });
  };

  const onAdditiveChange = async () => {
    if (!selectedAdditive) return;
    if (!selectedSamples) {
      setFetchingSamples(true);
    }
    const fetchedSamples =
      selectedSamples ||
      (await sampleRecordService.getFieldsData({
        ...filterModel,
        fields: uniqBy(
          [
            ...(selectedAdditive.conditionSets || [])
              ?.map((cs) =>
                (cs.chemicalConditions || [])?.map((cc) => cc.chemicalFieldName)
              )
              .flat(),
            chemicalFieldName,
          ],
          (v) => v
        ).map((f) => `chemicals.${f}`),
      }));
    setSamples(fetchedSamples);
    setFetchingSamples(false);
  };

  React.useEffect(() => {
    onAdditiveChange();
  }, [selectedAdditive]);

  return (
    <Box mt={2}>
      <Box mb={1} sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h6"
          ml={2}
          mr={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {`${chemicalFieldName}`}
        </Typography>
        <div style={{ minWidth: "350px" }}>
          <AdditiveAlgorithmAutocomplete
            fieldName={chemicalFieldName}
            allAdditiveAlgorithms={allAdditiveAlgorithms}
            label="Additive"
            value={selectedAdditive}
            onSelectValue={(value) => setSelectedAdditive(value)}
          />
        </div>
        {fetchingSamples ? (
          <CircularProgress size={20} sx={{ ml: 1 }} />
        ) : selectedAdditive ? (
          <FormControlLabel
            sx={{ ml: 1 }}
            control={
              <Switch
                checked={additiveAppliedStatus}
                onChange={(e, checked) => setAdditiveAppliedStatus(checked)}
              />
            }
            label="Show Additive Effect"
          />
        ) : null}
      </Box>
      <Box
        sx={{
          margin: "auto",
          border: "solid 1px #ddd",
          borderRadius: "7px",
          mb: 3,
          p: 1,
          position: "relative",
          "&:hover .closeButton": {
            opacity: 1,
          },
          maxHeight: "550px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            "& .leaflet-bottom.leaflet-right": { display: "none" },
          }}
        >
          <MapContainer
            center={center || [47.2868352, -120.212613]} // Center on one of the regions
            zoom={zoom || 1}
            style={{ height: "550px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              // attribution="&copy; OpenStreetMap contributors"
              attribution=""
            />
            {additiveConsideredRegions
              .filter((r) => r.geojson)
              .map((region, index) => {
                return (
                  <GeoJSON
                    key={`geo-region-${selectedAdditive?.name}-${additiveAppliedStatus}-${region.region}-${index}`}
                    data={{
                      ...region.geojson,
                      region: region.region,
                      value: region.value,
                      oldValue: region.oldValue,
                    }}
                    style={(feature: any) => {
                      const value = region.value;
                      return feature.geometry?.region == selectedRegion?.region
                        ? {
                            weight: 4,
                            color: "#537efd",
                            dashArray: "5",
                            fillOpacity: 0.65,
                          }
                        : {
                            fillColor: getColor(value),
                            weight: 2,
                            opacity: 1,
                            color: "white",
                            dashArray: "3",
                            fillOpacity: 0.65,
                          };
                    }}
                    onEachFeature={onEachFeature}
                  />
                );
              })}
            <InfoControl
              chemicalFieldName={chemicalFieldName}
              selectedRegion={selectedRegion}
              errorMessage={
                AnalysisValueRanges[chemicalFieldName] &&
                selectedRegion?.value &&
                (AnalysisValueRanges[chemicalFieldName].min >
                  selectedRegion.value ||
                  AnalysisValueRanges[chemicalFieldName].max <
                    selectedRegion.value)
                  ? "(Out of range)"
                  : ""
              }
            />
          </MapContainer>
          {chemicalFieldName ? (
            <div
              style={{
                position: "absolute",
                bottom: 28,
                left: 14,
                zIndex: 1000,
              }}
            >
              <ValueColorSlider
                key={`value-slider-${selectedAdditive?.name}-${selectedRegion?.region}-${selectedRegion?.value}`}
                oldValue={selectedRegion?.oldValue}
                sliderWidth={200}
                chemFieldName={chemicalFieldName}
                value={selectedRegion?.value}
              />
            </div>
          ) : null}
        </Box>
        {onClose ? (
          <IconButton
            onClick={() => onClose()}
            className="closeButton"
            size="small"
            sx={{
              transition: "0.2s",
              opacity: 0.2,
              position: "absolute",
              top: "-13px",
              right: "-13px",
              background: "#ddd",
              width: "25px",
              height: "25px",
              backgroundColor: "error.main",
              color: "white",
              "&:hover": {
                opacity: 1,
                backgroundColor: "error.main",
                color: "white",
              },
            }}
          >
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default ChemicalChoroplethMap;
