/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import CloseIcon from "@mui/icons-material/Close";
import { SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import * as React from "react";

export default function DebouncedTextInput({
  value,
  onChange,
  sx,
  placeholder,
  fullWidth,
  variant,
  label,
  size,
  delay,
  error,
  helperText,
}: {
  value: string;
  onChange: (changed: string) => void;
  sx?: SxProps<Theme>;
  placeholder?: string;
  fullWidth?: boolean;
  variant?: any;
  label?: string;
  size?: any;
  delay?: number;
  error?: boolean;
  helperText?: string;
}) {
  const [inputKey, setInputKey] = React.useState(0);

  const debouncedSearch = debounce(onChange, delay || 500);

  return (
    <TextField
      sx={sx}
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      key={inputKey}
      value={undefined}
      onChange={(e) => debouncedSearch(e.target.value)}
      placeholder={placeholder || "Search..."}
      size={size || "small"}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <IconButton
                size="small"
                onClick={() => {
                  onChange("");
                  setInputKey(inputKey + 1);
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </InputAdornment>
        ),
      }}
    />
  );
}
