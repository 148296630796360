import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const TableTitle = styled(Box)(() => ({
  background: "#dfdfdf",
  color: "#282828",
  padding: "3px 9px",
  fontWeight: "500",
}));

export const AnalysisStyledTableCell = styled(TableCell)(() => ({
  // borderBottom: "none",
  padding: "3.5px 10px",
  fontWeight: 500,
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    backgroundColor: "#efefef",
  },
  [`&.${tableCellClasses.head}.transparent`]: {
    backgroundColor: "#0000 !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const AnalysisStyledTableRow = styled(TableRow)(({}) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: "#eff3f7",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
