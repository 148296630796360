/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import ReactApexChart from "react-apexcharts";

const DistributionChart = ({
  fieldName,
  numbers,
  onClose,
}: {
  fieldName: string;
  numbers: number[];
  onClose: () => void;
}) => {
  function generateDistributionData(values: number[], numRanges = 10) {
    // Step 1: Define the ranges
    const min = Math.min(...values);
    const max = Math.max(...values);
    const rangeSize = (max - min) / numRanges;
    // Step 2: Count the number of elements in each range
    const distribution = Array.from({ length: numRanges + 1 }, (_, index) => {
      const rangeStart = min + index * rangeSize;
      const rangeEnd = rangeStart + rangeSize;
      const count = values.filter(
        (value) => value >= rangeStart && value < rangeEnd
      ).length;

      return {
        start: rangeStart,
        end: rangeEnd,
        middle: rangeStart + (rangeEnd - rangeStart) / 2,
        count: count,
      };
    });
    return distribution;
  }

  const [mainChartOptions] = React.useState<ApexCharts.ApexOptions>({
    title: {
      text: `${fieldName} Distribution`,
      align: "left",
    },
    chart: {
      id: `distribution${fieldName}`,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val || "0";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    series: [
      {
        name: `${fieldName}` || "",
        data: generateDistributionData(numbers)?.map(
          (item) => item.count || null
        ),
      },
    ],
    xaxis: {
      categories: generateDistributionData(numbers)?.map(
        (item) => item.middle || null
      ),
      type: "numeric",
    },
    legend: {
      position: "top",
    },
  });

  return (
    <Box>
      <ReactApexChart
        options={mainChartOptions}
        series={mainChartOptions.series}
        type="bar"
        height={300}
      />
    </Box>
  );
};

export default DistributionChart;
