/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ScaleIcon from "@mui/icons-material/Scale";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Typography,
  MenuItem,
  Tooltip,
  Paper,
} from "@mui/material";
import AddNewSampleQuickActionForm from "./AddNewSampleQuickActionForm";
import PostWeightInputForm from "./PostWeightInputForm";
import DrySampleForm from "./DrySampleForm";
import BagWeightForm from "./BagWeightForm";

export enum QuickActionType {
  AddNewSample = "AddNewSample",
  Enter48hrWeight = "Enter48hrWeight",
  DrySample = "DrySample",
  BagWeight = "BagWeight",
}

const quickActionIcons = {
  [QuickActionType.AddNewSample]: <AddCircleOutlineIcon />,
  [QuickActionType.Enter48hrWeight]: <ScaleIcon />,
  [QuickActionType.DrySample]: <FormatColorResetIcon />,
  [QuickActionType.BagWeight]: <ShoppingBagIcon />,
};

export default function QuickActionsDialog({
  onClose,
}: {
  onClose: () => void;
}) {
  const [selectedAction, setSelectedAction] = React.useState<QuickActionType>();
  return (
    <Dialog
      open
      onClose={onClose}
      PaperComponent={(props) => {
        return (
          <Paper
            {...props}
            sx={{
              margin: "8px !important",
            }}
          />
        );
      }}
    >
      <DialogTitle sx={{ borderBottom: "solid 1px #e5e5e5", py: 1, px: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "320px",
          }}
        >
          {selectedAction ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip title="Back To Quick Actions List" placement="top">
                <IconButton
                  sx={{ mr: 2 }}
                  onClick={() => setSelectedAction(undefined)}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: 1,
                  color: "primary.main",
                }}
              >
                {quickActionIcons[selectedAction]}
              </Box>
              <Typography variant="h6" color="primary.main">
                {selectedAction.replace(/([A-Z])/g, " $1").trim()}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <RocketLaunchIcon sx={{ mr: "12px" }} color="warning" />
              <Typography variant="h6">Ledger (Quick Actions)</Typography>
            </Box>
          )}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ px: 2, pt: 2 }}>
        {selectedAction ? (
          <Box sx={{ pt: 2 }}>
            {selectedAction === QuickActionType.AddNewSample ? (
              <AddNewSampleQuickActionForm />
            ) : selectedAction === QuickActionType.Enter48hrWeight ? (
              <PostWeightInputForm />
            ) : selectedAction === QuickActionType.DrySample ? (
              <DrySampleForm />
            ) : selectedAction === QuickActionType.BagWeight ? (
              <BagWeightForm />
            ) : null}
          </Box>
        ) : (
          <Box py={2}>
            <Box>
              {Object.values(QuickActionType).map((action) => (
                <MenuItem
                  key={action}
                  onClick={() => setSelectedAction(action)}
                  sx={{
                    py: "12px",
                    background: "#eee",
                    mb: 1,
                    borderRadius: "5px",
                    "&:hover>div": {
                      color: "primary.main",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#444",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {quickActionIcons[action]}
                    </div>
                    {action
                      .replace(/([A-Z])/g, " $1")
                      .replace(/(\d+)/g, " $1")
                      .trim()}
                  </Box>
                </MenuItem>
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
