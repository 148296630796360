import { Box, DialogTitle, Dialog, IconButton } from "@mui/material";
import ResearchScheduleForm from "./ResearchScheduleForm";
import CloseIcon from "@mui/icons-material/Close";
import { IResearchSchedule } from "../../../types/researchLabSchedule";

export default function ResearchScheduleFormDialog({
  onClose,
  schedules,
  editingSchedule,
  onUpdated,
  onNewCreated,
}: {
  onClose: () => void;
  schedules: IResearchSchedule[];
  editingSchedule?: IResearchSchedule;
  onUpdated: (updated: IResearchSchedule) => void;
  onNewCreated: (created: IResearchSchedule) => void;
}) {
  return (
    <Dialog open onClose={() => onClose()} fullWidth maxWidth="md">
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {editingSchedule?._id ? "Edit" : "Create"} R&D Trial
          <IconButton onClick={() => onClose()} sx={{ ml: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box p={3} pt={1}>
        <ResearchScheduleForm
          otherFutureSchedules={schedules.filter(
            (s) => s._id != editingSchedule?._id
          )}
          initialData={editingSchedule}
          onUpdated={onUpdated}
          onNewCreated={onNewCreated}
        />
      </Box>
    </Dialog>
  );
}
