import {
  Autocomplete,
  Button,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { IAdditiveEffect } from "../../../types/additiveAlgorithm";
import { useState } from "react";
import { ChemicalFields } from "../../../types/chemicals";
import MathOperatorSelect from "./MathOperatorSelect";

export default function AdditiveEffectItemForm({
  initialEffect,
  existingFieldNames,
  onSave,
}: {
  initialEffect?: IAdditiveEffect;
  existingFieldNames: string[];
  onSave: (effect: IAdditiveEffect) => void;
}) {
  const [effect, setEffect] = useState<Partial<IAdditiveEffect> | undefined>(
    initialEffect || {
      isPercentage: true,
    }
  );
  return (
    <Stack spacing={2}>
      <Autocomplete
        fullWidth
        value={effect?.chemicalFieldName ?? ""}
        onChange={(e, val) => {
          setEffect({ ...effect, chemicalFieldName: val || "" });
        }}
        getOptionDisabled={(option) => existingFieldNames?.includes(option)}
        options={ChemicalFields.map((f) => f.fieldName)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Field name"
            size="small"
            sx={{ flex: 1 }}
          />
        )}
      />
      <MathOperatorSelect
        value={effect?.operator}
        onChange={(newVal) => setEffect({ ...effect, operator: newVal })}
      />
      <TextField
        label="Value"
        type="number"
        value={effect?.value}
        onChange={(e) =>
          setEffect({
            ...effect,
            value: !e.target.value?.length ? undefined : Number(e.target.value),
          })
        }
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {effect?.isPercentage
                ? "%"
                : ChemicalFields.find(
                    (f) => f.fieldName === effect?.chemicalFieldName
                  )?.unit || ""}
            </InputAdornment>
          ),
        }}
      />
      <RadioGroup
        row
        onChange={(e) =>
          setEffect({
            ...effect,
            isPercentage: e.target.value === "percentage",
          })
        }
      >
        <FormControlLabel
          value="percentage"
          control={<Radio checked={effect?.isPercentage} />}
          label="Percentage Value"
        />
        <FormControlLabel
          value="pure"
          control={<Radio checked={!effect?.isPercentage} />}
          label="Pure Value"
        />
      </RadioGroup>
      <Button
        disabled={
          !effect?.chemicalFieldName ||
          !effect?.operator ||
          !effect?.value ||
          JSON.stringify(initialEffect || {}) == JSON.stringify(effect || {})
        }
        size="small"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          onSave(effect as IAdditiveEffect);
        }}
      >
        Save
      </Button>
    </Stack>
  );
}
