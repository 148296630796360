import WidgetsIcon from "@mui/icons-material/Widgets";
import GroupIcon from "@mui/icons-material/Group";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import GiteIcon from "@mui/icons-material/Gite";
import BiotechIcon from "@mui/icons-material/Biotech";
import CalculateIcon from "@mui/icons-material/Calculate";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ExploreIcon from "@mui/icons-material/Explore";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { SpecialPermissions } from "../../../types/user";

const NavLinks = [
  {
    menu_title: "Dashboard",
    menu_icon: (props = {}) => <WidgetsIcon {...props} />,
    new_item: false,
    path: "/dashboard",
  },
  {
    menu_title: "Manage Users",
    menu_icon: (props = {}) => <GroupIcon {...props} />,
    new_item: false,
    path: "/users",
    onlyCompanyAdmin: true,
  },
  {
    menu_title: "Manage Labs",
    superAdminTitle: "Manage Labs & Clients",
    companyAdminTitle: "Manage Clients",
    menu_icon: (props = {}) => <LocationCityIcon {...props} />,
    new_item: false,
    path: "/companies",
    onlyCompanyAdmin: true,
    onlyVendors: true,
  },
  {
    menu_title: "Sample Sources (farm)",
    menu_icon: (props = {}) => <GiteIcon {...props} />,
    new_item: false,
    path: "/sample-sources",
    onlySuperAdmin: true,
  },
  {
    menu_title: "Gas Analysis",
    menu_icon: (props = {}) => <BiotechIcon {...props} />,
    new_item: false,
    path: "/gas-analysis",
    onlySuperAdmin: true,
  },
  {
    menu_title: "Onboarding Guides",
    menu_icon: (props = {}) => <ExploreIcon {...props} />,
    new_item: false,
    path: "/onboarding-guides",
  },
  {
    menu_title: "Documentation",
    menu_icon: (props = {}) => <MenuBookIcon {...props} />,
    new_item: false,
    path: "/documentation",
    onlySuperAdmin: true,
    requiredPermissions: [SpecialPermissions.Documentation],
  },
  {
    menu_title: "Prediction Model",
    menu_icon: (props = {}) => <PsychologyIcon {...props} />,
    new_item: false,
    path: "/prediction-model",
    onlySuperAdmin: true,
    requiredPermissions: [SpecialPermissions.PredictionModel],
  },
  {
    menu_title: "R&D Schedule",
    menu_icon: (props = {}) => <PendingActionsIcon {...props} />,
    new_item: false,
    path: "/rd-schedule",
    onlySuperAdmin: true,
    // requiredPermissions: [SpecialPermissions.RdSchedule],
  },
  {
    menu_title: "Additive Algorithms",
    menu_icon: (props = {}) => <CalculateIcon {...props} />,
    new_item: false,
    path: "/additive-algorithms",
    onlySuperAdmin: true,
  },
];

export default NavLinks;
