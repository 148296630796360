import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useAppContext } from "../../../contexts/useStorageContext";
import { UserRoles } from "../../../types/auth";
import { ICompany } from "../../../types/company";
import companyService from "../../../service/company.service";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ProgressButton from "./ProgressButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";

const filter = createFilterOptions<ICompany>();

export default function CompanyAutoComplete({
  value,
  onChange,
  fullWidth = false,
  label,
  py,
  definedVendor,
  onlyDirectPartners,
  limitedOptions,
  enabled,
  isClientAutoComplete,
  onlyLab,
}: {
  value?: ICompany;
  definedVendor?: ICompany;
  onlyDirectPartners?: boolean;
  onChange?: (selected: ICompany | null) => void;
  fullWidth?: boolean;
  label?: string;
  py?: number;
  limitedOptions?: ICompany[];
  enabled?: boolean;
  isClientAutoComplete?: boolean;
  onlyLab?: boolean;
}) {
  const [open, toggleOpen] = React.useState(false);
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [options, setOptions] = React.useState<ICompany[]>(
    limitedOptions || []
  );
  const [isNewCompanyMyClient, setIsNewCompanyMyClient] = React.useState(
    isClientAutoComplete ||
      ![UserRoles.adminStaff, UserRoles.superadmin].includes(
        loggedInUserInfo?.role || UserRoles.user
      )
  );

  const fetchCompanyShallowList = async () => {
    if (limitedOptions) return;
    const result = await companyService.fetchCompanyShallowList();
    const filteredResult = (
      definedVendor
        ? result.data?.filter(
            (c: ICompany) => definedVendor?._id == c.vendorCompanyId
          ) || []
        : onlyDirectPartners
        ? result.data?.filter((c: ICompany) => !c.vendorCompanyId) || []
        : result.data || []
    ).filter((c: ICompany) => !onlyLab || !c.vendorCompanyId);
    setOptions(filteredResult);
  };
  const [creatingNewCompany, setCreatingNewCompany] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState<ICompany>({ name: "" });

  const handleClose = () => {
    setDialogValue({ name: "" });
    toggleOpen(false);
  };

  const handleSubmit = async () => {
    setCreatingNewCompany(true);
    const result = await companyService.addNewCompany({
      ...dialogValue,
      vendorCompanyId: isNewCompanyMyClient
        ? [UserRoles.adminStaff, UserRoles.superadmin].includes(
            loggedInUserInfo?.role || UserRoles.user
          ) && definedVendor?._id
          ? definedVendor?._id
          : loggedInUserInfo?.company?._id
        : undefined,
    });
    setOptions([...options, result.data]);
    setCreatingNewCompany(false);
    onChange && onChange(result.data);
    handleClose();
  };

  const isAdmin =
    loggedInUserInfo?.role &&
    [UserRoles.superadmin, UserRoles.adminStaff].includes(
      loggedInUserInfo?.role as UserRoles
    );

  React.useEffect(() => {
    if (isAdmin) fetchCompanyShallowList();
  }, [definedVendor]);
  return (
    <>
      <Autocomplete
        freeSolo
        disabled={enabled ? false : !isAdmin}
        value={value || null}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue._id == "new") {
            toggleOpen(true);
            setDialogValue({
              name: newValue.name?.replaceAll('"', "").replaceAll("Add ", ""),
            });
          } else {
            onChange && onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              _id: "new",
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option && option._id == "new") {
            return "new";
          }
          return option.name || "";
        }}
        options={options}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth={fullWidth}
            placeholder={
              isClientAutoComplete
                ? "Select Client (Or Add New)"
                : "Select Lab (Or Add New)"
            }
            size="small"
            sx={{
              minWidth: "200px",
              "& .MuiInputBase-root": {
                py: `${py}px !important`,
              },
            }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isClientAutoComplete ||
          ![UserRoles.adminStaff, UserRoles.superadmin].includes(
            loggedInUserInfo?.role || UserRoles.user
          )
            ? "Add a new client"
            : "Add a new lab"}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "300px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.name}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                name: event.target.value,
              })
            }
            label={isClientAutoComplete ? "Client Company Name" : "Lab Name"}
            type="text"
            fullWidth
            size="small"
          />
          {isClientAutoComplete ? (
            <FormControlLabel
              disabled={
                isClientAutoComplete ||
                ![UserRoles.adminStaff, UserRoles.superadmin].includes(
                  loggedInUserInfo?.role || UserRoles.user
                )
              }
              control={
                <Checkbox
                  checked={isNewCompanyMyClient}
                  onChange={(event) =>
                    setIsNewCompanyMyClient(event.target.checked)
                  }
                />
              }
              label={`This is <${definedVendor?.name}>'s client`}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton
            onClick={handleSubmit}
            title="Add"
            inProgress={creatingNewCompany}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
