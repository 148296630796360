export interface IAnalysisValuesDynamicTableRowKeyValueData {
  title: string;
  fieldName: string;
}

export const DmTableRowsData: IAnalysisValuesDynamicTableRowKeyValueData[] = [
  {
    title: "Dry Matter %",
    fieldName: "DM",
  },
  {
    title: "Crude Protein %",
    fieldName: "CP",
  },
  {
    title: "ND-ICP % CP",
    fieldName: "NDICP",
  },
  {
    title: "Lignin %",
    fieldName: "Lignin",
  },
  {
    title: "ADF %",
    fieldName: "ADF",
  },
  {
    title: "aNDF %",
    fieldName: "aNDF",
  },
  {
    title: "EE %",
    fieldName: "Fat_EE",
  },
  {
    title: "Sugar (WSC) %",
    fieldName: "Sugar_WSC",
  },
  {
    title: "Starch %",
    fieldName: "Starch",
  },
  {
    title: "NSC",
    fieldName: "NSC",
  },
  {
    title: "aPF",
    fieldName: "APF",
  },
  {
    title: "aOMD (% DM)",
    fieldName: "OMD",
  },
  {
    title: "Dietary Sodium %",
    fieldName: "NA",
  },
  {
    title: "uNDF48 % NDF",
    fieldName: "UNDF",
  },
  {
    title: "Ash %",
    fieldName: "Ash",
  },
];

export const DmTableHeaders = ["", "DM"];

export const DigestionRatesTableHeaders = ["", ""];
export const DigestionRatesTableRowsData: IAnalysisValuesDynamicTableRowKeyValueData[] =
  [
    {
      title: "Fast Pool / C:B0 (Kd/hr)",
      fieldName: "Fast_Pool",
    },
    {
      title: "Slow Pool / C:B3 (Kd/hr)",
      fieldName: "Slow_Pool",
    },
    {
      title: "C:B1",
      fieldName: "C:B1",
    },
  ];

export const TimeToMaxRatesTableHeaders = ["", ""];
export const TimeToMaxRatesTableRowsData: IAnalysisValuesDynamicTableRowKeyValueData[] =
  [
    {
      title: "Fast Pool",
      fieldName: "Fast_HR",
    },
    {
      title: "Slow Pool",
      fieldName: "Slow_HR",
    },
  ];
