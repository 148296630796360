/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import sampleSourceService from "../../../service/sampleSource.service";
import {
  ISampleSource,
  ISampleSourceBaseFields,
} from "../../../types/sampleSource";
import ProgressButton from "./ProgressButton";

const filter = createFilterOptions<ISampleSource>();

export default function SampleSourceAutoComplete({
  value,
  onChange,
  fullWidth = false,
  label,
  py,
}: {
  value?: ISampleSource;
  onChange?: (selected: ISampleSource | null) => void;
  fullWidth?: boolean;
  label?: string;
  py?: number;
}) {
  const [open, toggleOpen] = React.useState(false);
  const [options, setOptions] = React.useState<ISampleSource[]>([]);
  const fetchSampleSourceShallowList = async () => {
    const result = await sampleSourceService.fetchAllSampleSources();
    setOptions(result || []);
  };
  const [creatingNewSampleSource, setCreatingNewSampleSource] =
    React.useState(false);

  const [dialogValue, setDialogValue] = React.useState<ISampleSourceBaseFields>(
    { name: "" }
  );

  const handleClose = () => {
    setDialogValue({ name: "" });
    toggleOpen(false);
  };

  const handleSubmit = async () => {
    setCreatingNewSampleSource(true);
    const result = await sampleSourceService.addNewSampleSource(dialogValue);
    setOptions([...options, result]);
    setCreatingNewSampleSource(false);
    onChange && onChange(result);
    handleClose();
  };

  React.useEffect(() => {
    fetchSampleSourceShallowList();
  }, []);

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue._id == "new") {
            toggleOpen(true);
            setDialogValue({
              name: newValue.name.replaceAll('"', "").replaceAll("Add ", ""),
            });
          } else {
            onChange && onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              _id: "new",
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={options}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option && option._id == "new") {
            return "new";
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            placeholder={label ? "Find or create" : "Sample Source"}
            sx={{
              minWidth: "200px",
              "& .MuiInputBase-root": {
                py: `${py}px !important`,
              },
            }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a new sample source</DialogTitle>
        <DialogContent sx={{ minWidth: "300px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.name}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                name: event.target.value,
              })
            }
            label="Farm Name"
            type="text"
            fullWidth
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton
            onClick={handleSubmit}
            title="Add"
            inProgress={creatingNewSampleSource}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

interface FilmOptionType {
  inputValue?: string;
  title: string;
  year?: number;
}
