/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { IAdditiveEffectAlgorithm } from "../../../types/additiveAlgorithm";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import AdditiveEffectItemForm from "./AdditiveEffectItemForm";
import AdditiveConditionSetForm from "./AdditiveConditionSetForm";
import additiveAlgorithmService from "../../../service/additiveAlgorithm.service";
import { showSnackbar } from "../../../service/snackbar.service";
import ProgressButton from "../Common/ProgressButton";

interface AdditiveAlgorithmFormDialogProps {
  open: boolean;
  onClose: () => void;
  initialFormData?: IAdditiveEffectAlgorithm;
  handleUpdate: (updated: IAdditiveEffectAlgorithm) => void;
  handleCreate: (updated: IAdditiveEffectAlgorithm) => void;
  existingAlgorithmNames?: string[];
}

const AdditiveAlgorithmFormDialog: React.FC<
  AdditiveAlgorithmFormDialogProps
> = ({
  open,
  onClose,
  initialFormData,
  handleUpdate,
  handleCreate,
  existingAlgorithmNames,
}) => {
  const [formData, setFormData] = React.useState<
    Partial<IAdditiveEffectAlgorithm> | undefined
  >(
    initialFormData || {
      chemicalConditionTolerancePercentage: 5,
    }
  );
  const [popoverKey, setPopoverKey] = React.useState<number>(0);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  const onCreate = async () => {
    if (!formData?.name) return;
    try {
      setIsProcessing(true);
      const result = await additiveAlgorithmService.addAdditiveAlgorithm(
        formData as IAdditiveEffectAlgorithm
      );
      if (result) {
        handleCreate(result);
        onClose();
      }
      setIsProcessing(false);
    } catch (e) {
      showSnackbar("danger", "Error", "Failed to create additive algorithm");
    } finally {
      setIsProcessing(false);
    }
  };

  const onUpdate = async () => {
    if (!formData?.name) return;
    try {
      setIsProcessing(true);
      const result = await additiveAlgorithmService.updateAdditiveAlgorithm(
        formData as IAdditiveEffectAlgorithm
      );
      if (result) {
        handleUpdate(formData as IAdditiveEffectAlgorithm);
        onClose();
      }
      setIsProcessing(false);
    } catch (e) {
      showSnackbar("danger", "Error", "Failed to update additive algorithm");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="additive-algorithm-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="additive-algorithm-dialog-title">
        Additive Algorithm Details
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ minWidth: "350px", mt: 1 }}>
          <TextField
            size="small"
            label="Name"
            fullWidth
            variant="outlined"
            value={formData?.name || ""}
            onChange={(e) =>
              setFormData({ ...formData, name: e.target.value || "" })
            }
            error={existingAlgorithmNames?.includes(formData?.name || "")}
            helperText={
              existingAlgorithmNames?.includes(formData?.name || "")
                ? "Algorithm name already exists"
                : ""
            }
          />
          <TextField
            size="small"
            label="Description"
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            value={formData?.description || ""}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value || "" })
            }
          />
          <Box
            sx={{
              border: "solid 2px #ddd",
              borderRadius: "6px",
              position: "relative",
              p: 1,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "-10px",
                left: "10px",
                backgroundColor: "white",
                padding: "0 4px",
              }}
            >
              Additive Effects
            </Typography>
            {formData?.additiveEffects?.map((effect, i) => (
              <Box
                key={`${popoverKey}-effect-${i}`}
                sx={{
                  p: 1,
                  borderBottom:
                    formData?.additiveEffects?.length == i + 1
                      ? undefined
                      : "solid 1px #ddd",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  "& .icon-buttons": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "0px",
                    overflow: "hidden",
                    transition: "0.2s",
                  },
                  "&:hover .icon-buttons": {
                    width: "85px",
                  },
                }}
              >
                <Box>{effect.chemicalFieldName}</Box>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      background:
                        effect.operator == "+"
                          ? "#caffda"
                          : effect.operator == "-"
                          ? "#ffe0ca"
                          : undefined,
                      padding: "1px 8px",
                      borderRadius: "5px",
                    }}
                  >{`${effect.operator} ${effect.value} ${
                    effect.isPercentage ? "%" : ""
                  }`}</Box>
                  <Box className="icon-buttons">
                    <GeneralPopoverWrapper
                      key={`${popoverKey}-effect-${i}`}
                      triggerElement={
                        <IconButton size="small" sx={{ mr: "4px" }}>
                          <EditIcon />
                        </IconButton>
                      }
                      popoverContent={
                        <Box p={2}>
                          <AdditiveEffectItemForm
                            initialEffect={effect}
                            existingFieldNames={
                              formData?.additiveEffects?.map(
                                (e) => e.chemicalFieldName
                              ) || []
                            }
                            onSave={(updatedEffect) => {
                              setFormData({
                                ...formData,
                                additiveEffects: formData?.additiveEffects?.map(
                                  (e, effectIndex) =>
                                    effectIndex === i ? updatedEffect : e
                                ),
                              });
                              setPopoverKey((prev) => prev + 1);
                            }}
                          />
                        </Box>
                      }
                    />

                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          additiveEffects: formData?.additiveEffects?.filter(
                            (_, index) => index !== i
                          ),
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </div>
              </Box>
            ))}
            <Box>
              <GeneralPopoverWrapper
                key={`${popoverKey}-add-effect`}
                fullWidth
                triggerElement={
                  <Button
                    fullWidth
                    size="small"
                    sx={{
                      background: "#eee",
                      ":hover": {
                        backgroundColor: "#ddd",
                      },
                      mt: 1,
                    }}
                  >
                    Add Effect
                  </Button>
                }
                popoverContent={
                  <Box p={2}>
                    <AdditiveEffectItemForm
                      existingFieldNames={
                        formData?.additiveEffects?.map(
                          (e) => e.chemicalFieldName
                        ) || []
                      }
                      onSave={(effect) => {
                        setFormData({
                          ...formData,
                          additiveEffects: [
                            ...(formData?.additiveEffects || []),
                            effect,
                          ],
                        });
                        setPopoverKey((prev) => prev + 1);
                      }}
                    />
                  </Box>
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              border: "solid 2px #ddd",
              borderRadius: "6px",
              position: "relative",
              p: 1,
              pt: 2,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "-10px",
                left: "10px",
                backgroundColor: "white",
                padding: "0 4px",
              }}
            >
              Additive Conditions
            </Typography>
            <Stack spacing={1}>
              {formData?.conditionSets?.map((conditionSet, setIndex) => (
                <Box
                  key={`${popoverKey}-effect-${setIndex}`}
                  sx={{
                    p: 1,
                    background: "#e6f0f4",
                    border: "solid 1px #e2eaed",
                    borderRadius: "4px",
                    position: "relative",
                    "& .icon-buttons": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: "0px",
                      overflow: "hidden",
                      transition: "0.2s",
                      marginBottom: "-5px",
                    },
                    "&:hover .icon-buttons": {
                      height: "35px",
                    },
                  }}
                >
                  {conditionSet.chemicalConditions?.map((condition, i) => (
                    <Box
                      key={`${popoverKey}-condition-${i}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "3px 0px",
                        borderBottom:
                          conditionSet.chemicalConditions?.length == i + 1
                            ? undefined
                            : "solid 1px #ddd",
                      }}
                    >
                      <div style={{ flex: 3, marginRight: "8px" }}>
                        {condition.chemicalFieldName}
                      </div>
                      <div
                        style={{
                          flex: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {condition.minValue}
                      </div>
                      <div>~</div>
                      <div
                        style={{
                          flex: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {condition.maxValue}
                      </div>
                    </Box>
                  ))}
                  {conditionSet.additionalCondition ? (
                    <Box
                      sx={{
                        color: "warning.main",
                        mt: 1,
                      }}
                    >
                      {conditionSet.additionalCondition}
                    </Box>
                  ) : null}
                  <Box className="icon-buttons">
                    <GeneralPopoverWrapper
                      key={`${popoverKey}-condition-set-${setIndex}`}
                      triggerElement={
                        <IconButton size="small" sx={{ mr: "4px" }}>
                          <EditIcon />
                        </IconButton>
                      }
                      popoverContent={
                        <Box p={2}>
                          <AdditiveConditionSetForm
                            initialConditionSet={conditionSet}
                            onSave={(updatedConditionSet) => {
                              setFormData({
                                ...formData,
                                conditionSets: formData?.conditionSets?.map(
                                  (e, conditionSetIndex) =>
                                    conditionSetIndex === setIndex
                                      ? updatedConditionSet
                                      : e
                                ),
                              });
                              setPopoverKey((prev) => prev + 1);
                            }}
                          />
                        </Box>
                      }
                    />
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          conditionSets: formData?.conditionSets?.filter(
                            (_, index) => index !== setIndex
                          ),
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Stack>
            <Box mb={3}>
              <GeneralPopoverWrapper
                key={`${popoverKey}-add-condition-set`}
                transformVertical="bottom"
                anchorVertical="top"
                fullWidth
                triggerElement={
                  <Button
                    fullWidth
                    size="small"
                    sx={{
                      background: "#eee",
                      ":hover": {
                        backgroundColor: "#ddd",
                      },
                      mt: 1,
                    }}
                  >
                    Add Condition Set
                  </Button>
                }
                popoverContent={
                  <Box p={2} sx={{ minWidth: "350px" }}>
                    <AdditiveConditionSetForm
                      onSave={(conditionSet) => {
                        setFormData({
                          ...formData,
                          conditionSets: [
                            ...(formData?.conditionSets || []),
                            conditionSet,
                          ],
                        });
                        setPopoverKey((prev) => prev + 1);
                      }}
                    />
                  </Box>
                }
              />
            </Box>
            <TextField
              size="small"
              label="Condition Tolerance *"
              type="number"
              fullWidth
              value={formData?.chemicalConditionTolerancePercentage || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  chemicalConditionTolerancePercentage: e.target.value?.length
                    ? Number(e.target.value)
                    : undefined,
                })
              }
              error={
                formData?.chemicalConditionTolerancePercentage === undefined
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <ProgressButton
          disabled={
            existingAlgorithmNames?.includes(formData?.name || "") ||
            formData?.chemicalConditionTolerancePercentage === undefined ||
            !formData?.name
          }
          color="primary"
          variant="contained"
          disableElevation
          onClick={() => {
            initialFormData ? onUpdate() : onCreate();
          }}
          title={initialFormData ? "Update" : "Create"}
          inProgress={isProcessing}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AdditiveAlgorithmFormDialog;
