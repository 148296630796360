/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PublicIcon from "@mui/icons-material/Public";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StopIcon from "@mui/icons-material/Stop";
import PrintIcon from "@mui/icons-material/Print";
import ArchiveIcon from "@mui/icons-material/Archive";
import SummarizeIcon from "@mui/icons-material/Summarize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UploadIcon from "@mui/icons-material/Upload";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { useNavigate } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalculateIcon from "@mui/icons-material/Calculate";
import BarChartIcon from "@mui/icons-material/BarChart";
import * as XLSX from "xlsx";
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderEditCellParams,
  GridRowId,
  GridSortItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import {
  ChemicalDataBaseFields,
  ChemicalField,
  ChemicalFields,
} from "../../../types/chemicals";
import {
  DietRegions,
  ISampleRecordGroup,
  ISampleRecordPopulated,
  SampleGroupType,
  SampleRecordManualUpdateReason,
} from "../../../types/sample";
import Box from "@mui/material/Box";
import {
  DefaultRowsPerPageOptions,
  defaultPaginationParams,
} from "../../../helpers/defaults/pagination";
import ClassIcon from "@mui/icons-material/Class";
import { PaginationParams } from "../../../types/tables";
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SimpleSearchInputWithDebounce from "../Common/SimpleSearchInputWithDebounce";
import { highLightWords } from "../../../helpers/utils";
import ChemicalValueFilter, {
  IChemicalValueFilterItem,
} from "./ChemicalValueFilter";
import {
  FilterOperators,
  SampleRecordFilterParams,
} from "../../../types/common";
import { uniqBy } from "lodash";
import HighlightableRefreshButton from "../Common/HighlightableRefreshButton";
import SpectraChart from "./SpectraChart";
import { ISpectraItem } from "../../../types/spectra";
import DebouncedRangeInput from "../Common/DebouncedRangeInput";
import {
  calculateAverage,
  calculateStandardDeviation,
} from "../../../helpers/numbers";
import DistributionChart from "./DistributionChart";
import ExportDatasetFormDialog from "./ExportDatasetFormDialog";
import exportService from "../../../service/export.service";
import CollectionBucketPopover from "./CollectionBucketPopover";
import ExportLogPopover from "./ExportLogPopover";
import sampleRecordService, {
  ISampleRecordUpdatePayload,
} from "../../../service/sampleRecord.service";
import { ICompany } from "../../../types/company";
import { useAppContext } from "../../../contexts/useStorageContext";
import { UserRoles } from "../../../types/auth";
import CompanyAutoComplete from "../Common/CompanyAutoComplete";
import { ISampleSource } from "../../../types/sampleSource";
import WhitePanel from "../Common/WhitePanel";
import CreateNewSampleGroupDialog from "./CreateNewSampleGroupDialog";
import sampleGroupService from "../../../service/sampleGroup.service";
import ConfirmDialog from "../Common/ConfirmDialog";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import GasAnalysisReportDialog from "../Common/GasAnalysisReport/GasAnalysisReportDialog";
import { IGasAnalysis } from "../../../types/gasAnalysis";
import GasAnalysisFormDialog from "../GasAnalysis/GasAnalysisFormDialog";
import SampleGroupPopover from "../SampleGroup/SampleGroupPopover";
import useWindowHeight from "../../../hooks/useWindowHeight";
import AttachedFileItem from "../Common/AttachedFileItem";
import usePrevious from "../../../hooks/usePrevious";
import BatchReportDialog from "./BatchReportDialog";
import ReactDOM from "react-dom";
import GasReport2 from "../Common/GasAnalysisReport/AnalysisReportVariations/GasReport2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SampleRecordDetailPanel from "./SampleRecordDetailPanel/SampleRecordDetailPanel";
import SampleBagCoverPrintDialog from "../Common/SampleBagCoverPrintDialog";
import SampleRecordManualUpdateItem from "./SampleRecordManualUpdateItem";
import { getSampleRecordUpdates } from "../../../helpers/sampleRecord";
import UpdateSampleRecordConfirmDialog from "./UpdateSampleRecordConfirmDialog";
import { AnalysisValueRanges } from "../Common/GasAnalysisReport/AnalysisConstants";
import CustomMuiDataGrid, { TableIds } from "../Common/CustomMuiDataGrid";
import ProgressButton from "../Common/ProgressButton";
import ReportTypeSelect, { ReportTypes } from "../Common/ReportTypeSelect";
import { HeatmapDataPoint } from "./ChemicalHeatmap";
import { getRegionBoundaryCoordinates } from "../../../service/geocode.service";
import ChemicalChoroplethMap, {
  ChoroplethMapRegion,
} from "./ChemicalChoroplethMap";
import { IAdditiveEffectAlgorithm } from "../../../types/additiveAlgorithm";
import additiveAlgorithmService from "../../../service/additiveAlgorithm.service";

export enum SampleDataHealthStatus {
  Success = "Good",
  Warning = "Incomplete",
  Invalid = "Invalid",
  Blank = "Empty",
}
interface IRangeFilterOption {
  title: string;
  fieldName: string;
}
export const rangeFilterOptions: IRangeFilterOption[] = [
  {
    title: "Datalake UUID",
    fieldName: "uid",
  },
  {
    title: "Lab SSID",
    fieldName: "companyUid",
  },
  {
    title: "Sample #",
    fieldName: "sampleNumber",
  },
];

const DataHealthMark = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  border: "solid 1px",
  padding: "3px 15px",
  "&:hover": {
    border: "solid 2px",
  },
  [`&.${SampleDataHealthStatus.Success}`]: {
    backgroundColor: `${theme.palette.success.main}44`,
    borderColor: theme.palette.success.main,
  },
  [`&.${SampleDataHealthStatus.Warning}`]: {
    backgroundColor: `${theme.palette.warning.main}44`,
    borderColor: theme.palette.warning.main,
  },
  [`&.${SampleDataHealthStatus.Invalid}`]: {
    backgroundColor: `${theme.palette.error.main}44`,
    borderColor: theme.palette.error.main,
  },
  [`&.${SampleDataHealthStatus.Blank}`]: {
    backgroundColor: `${theme.palette.grey[400]}44`,
    borderColor: theme.palette.grey[400],
  },
}));

export default function SampleRecordsDataTable({
  initialSampleGroup,
}: {
  initialSampleGroup?: ISampleRecordGroup;
}) {
  const reportPdfRef = React.useRef();
  const navigate = useNavigate();
  const { state } = useAppContext();
  const windowHeight = useWindowHeight();
  const { myCompanyProfile, loggedInUserInfo, myClients } = state;
  const isAdmin =
    loggedInUserInfo?.role &&
    [UserRoles.superadmin, UserRoles.adminStaff].includes(
      loggedInUserInfo?.role as UserRoles
    );
  const [paginationParams, setPaginationParams] =
    React.useState<PaginationParams>({
      ...defaultPaginationParams,
      pageSize: 25,
    });
  const [rows, setRows] = React.useState<ISampleRecordPopulated[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [hideMetaData, setHideMetaData] = React.useState(true);
  const [hideDataHealthColumns, setHideDataHealthColumns] =
    React.useState(true);
  const [hideCalibrationColumns, setHideCalibrationColumns] =
    React.useState(true);
  const [chemicalValueFilters, setChemicalValueFilters] = React.useState<
    IChemicalValueFilterItem[]
  >([]);
  const [minUid, setMinUid] = React.useState<string>();
  const [maxUid, setMaxUid] = React.useState<string>();
  const [selectedUids, setSelectedUids] = React.useState<GridRowId[]>([]);
  const [collectedRows, setCollectedRows] = React.useState<
    ISampleRecordPopulated[]
  >([]);
  const [highlightRefreshButton, setHighlightRefreshButton] =
    React.useState(false);
  const [loadingSpectraData, setLoadingSpectraData] = React.useState(false);
  const [uidsLoadingSpectraData, setUidsLoadingSpectraData] = React.useState<
    number[]
  >([]);
  const [spectraData, setSpectraData] = React.useState<
    ISampleRecordPopulated[]
  >([]);
  const [pinnedRows, setPinnedRows] = React.useState<ISampleRecordPopulated[]>(
    []
  );
  const [distributions, setDistributions] = React.useState<
    { field: string; numbers: number[]; isSelectedRows: boolean }[]
  >([]);
  const [heatmaps, setHeatmaps] = React.useState<
    {
      field: string;
      points: HeatmapDataPoint[];
    }[]
  >([]);

  const [choroplethMaps, setChoroplethMaps] = React.useState<
    {
      field: string;
      regions: ChoroplethMapRegion[];
      dietRegions?: ChoroplethMapRegion[];
      onlySelectedRows?: boolean;
      selectedSamples?: ISampleRecordPopulated[];
      filterModel?: SampleRecordFilterParams;
    }[]
  >([]);
  const [collapseDistributions, setCollapseDistributions] =
    React.useState(false);
  const [collapseHeatmaps, setCollapseHeatmaps] = React.useState(false);
  const [openExportDialog, setOpenExportDialog] = React.useState(false);
  const [openGroupCreationDialog, setOpenGroupCreationDialog] =
    React.useState(false);
  const [exporting, setExporting] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState<
    ICompany | undefined
  >(isAdmin ? undefined : myCompanyProfile);
  const [waveLengths, setWaveLengths] = React.useState<number[]>([]);
  const [minWavelength, setMinWavelength] = React.useState<number>();
  const [maxWavelength, setMaxWavelength] = React.useState<number>();
  const [showChemicals, setShowChemicals] = React.useState(true);
  const [showSpectra, setShowSpectra] = React.useState(false);
  const [componentsRefreshKey, setComponentsRefreshKey] = React.useState(0);
  const [sampleGroupListRefreshKey, setSampleGroupListRefreshKey] =
    React.useState(0);
  const [groupingInProgress, setGroupingInProgress] = React.useState(false);
  const [selectedSampleSource, setSelectedSampleSource] =
    React.useState<ISampleSource>();
  const [editingRow, setEditingRow] = React.useState<ISampleRecordPopulated>();
  const [sampleGroup, setSampleGroup] = React.useState<
    ISampleRecordGroup | undefined
  >(initialSampleGroup);
  const [sortModel, setSortModel] = React.useState<GridSortItem[]>([]);
  const [gettingAllFieldValues, setGettingAllFieldValues] = React.useState("");
  const [creatingGroup, setCreatingGroup] = React.useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    React.useState(false);
  const [targetSampleRecord, setTargetSampleRecord] =
    React.useState<ISampleRecordPopulated>();
  const [openGasAnalysisReportDialog, setOpenGasAnalysisReportDialog] =
    React.useState(false);
  const [openGasAnalysisViewDialog, setOpenGasAnalysisViewDialog] =
    React.useState(false);
  const [openSampleBagCoverPrintDialog, setOpenSampleBagCoverPrintDialog] =
    React.useState(false);
  const [targetGasAnalysis, setTargetGasAnalysis] =
    React.useState<IGasAnalysis>();
  const [chemDataOfAllRows, setChemDataOfAllRows] = React.useState<
    ISampleRecordPopulated[]
  >([]);
  const [fetchingBatchReportData, setFetchingBatchReportData] =
    React.useState(false);
  const [openBatchReportDialog, setOpenBatchReportDialog] =
    React.useState(false);
  const prevStates = usePrevious({ sampleGroup });
  const [batchReportDownloadingProgress, setBatchReportDownloadingProgress] =
    React.useState<{ total: number; done: number }>();
  const [batchReportProcessing, setBatchReportProcessing] =
    React.useState(false);
  const [batchReportAllowed, setBatchReportAllowed] = React.useState(true);
  const [currentRangeFilterOption, setCurrentRangeFilterOption] =
    React.useState<IRangeFilterOption>(rangeFilterOptions[0]);
  const [minUidInvalid, setMinUidInvalid] = React.useState(false);
  const [maxUidInvalid, setMaxUidInvalid] = React.useState(false);
  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] =
    React.useState(false);
  const [spectraPopoverKey, setSpectraPopoverKey] = React.useState(0);
  const [populatingCoordinates, setPopulatingCoordinates] =
    React.useState(false);
  const [choroplethMapAddingFieldName, setChoroplethMapAddingFieldName] =
    React.useState<string>("");
  const [mapRegionMode, setMapRegionMode] = React.useState<
    "region" | "dietRegion"
  >("dietRegion");
  const [allAdditiveAlgorithms, setAllAdditiveAlgorithms] = React.useState<
    IAdditiveEffectAlgorithm[]
  >([]);

  const currentFilterModel: SampleRecordFilterParams = React.useMemo(
    () => ({
      searchTerm: searchTerm,
      chemicalFilters: chemicalValueFilters,
      rangeFilterFieldName: currentRangeFilterOption.fieldName,
      minUid,
      maxUid,
      companyId: selectedCompany?._id,
      sampleSource: selectedSampleSource?.name,
      uids: sampleGroup?.uids,
      sortModel: sortModel?.length
        ? {
            [sortModel[0].field]: sortModel[0].sort == "desc" ? -1 : 1,
          }
        : undefined,
    }),
    [
      searchTerm,
      chemicalValueFilters,
      currentRangeFilterOption,
      minUid,
      maxUid,
      selectedCompany,
      selectedSampleSource,
      sampleGroup,
      sortModel,
    ]
  );

  const getSpectraNumberValueColumn = (wavelength: number) => {
    return {
      field: wavelength?.toString() || "",
      headerName: wavelength?.toString() || "",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <div>
            {row.spectraItems?.find((s) => s.wavelength == wavelength)?.value ||
              ""}
          </div>
        );
      },
    } as any;
  };

  const calculateAnalysisNumbers = () => {
    const selectedRows = rows.filter(
      (r) => r.uid && selectedUids.includes(r.uid)
    );
    const minValuesRow: ISampleRecordPopulated = {
      uid: 0.1,
      sampleNumber: "Min",
      chemicals: {},
    };
    ChemicalFields.map((field) => {
      if (!minValuesRow.chemicals) return;
      minValuesRow.chemicals[field.fieldName] = Math.min(
        ...(selectedRows
          .map((r) => r.chemicals?.[field.fieldName])
          .filter((v) => v) as number[])
      );
    });

    const maxValuesRow: ISampleRecordPopulated = {
      uid: 0.2,
      sampleNumber: "Max",
      chemicals: {},
    };
    ChemicalFields.map((field) => {
      if (!maxValuesRow.chemicals) return;
      maxValuesRow.chemicals[field.fieldName] = Math.max(
        ...(selectedRows
          .map((r) => r.chemicals?.[field.fieldName])
          .filter((v) => v) as number[])
      );
    });

    const avgValuesRow: ISampleRecordPopulated = {
      uid: 0.3,
      sampleNumber: "Average",
      chemicals: {},
    };
    ChemicalFields.map((field) => {
      if (!avgValuesRow.chemicals) return;
      avgValuesRow.chemicals[field.fieldName] = calculateAverage(
        selectedRows
          .map((r) => r.chemicals?.[field.fieldName])
          .filter((v) => v) as number[]
      );
    });

    const deviationValuesRow: ISampleRecordPopulated = {
      uid: 0.4,
      sampleNumber: "Std Deviation",
      chemicals: {},
    };
    ChemicalFields.map((field) => {
      if (!deviationValuesRow.chemicals) return;
      deviationValuesRow.chemicals[field.fieldName] =
        calculateStandardDeviation(
          selectedRows
            .map((r) => r.chemicals?.[field.fieldName])
            .filter((v) => v) as number[]
        );
    });
    setPinnedRows([
      minValuesRow,
      maxValuesRow,
      avgValuesRow,
      deviationValuesRow,
    ]);
  };

  const addFieldToDistributions = async ({
    fieldName,
    prefix,
    onlySelectedRows = true,
  }: {
    fieldName: string;
    prefix?: string;
    onlySelectedRows?: boolean;
  }) => {
    if (!fieldName) return;
    setGettingAllFieldValues(fieldName);
    const result = onlySelectedRows
      ? rows.filter((r) => r.uid && selectedUids.includes(r.uid))
      : await sampleRecordService.getFieldsData({
          searchTerm: searchTerm,
          chemicalFilters: chemicalValueFilters,
          rangeFilterFieldName: currentRangeFilterOption.fieldName,
          minUid,
          maxUid,
          companyId: selectedCompany?._id,
          sampleSource: selectedSampleSource?.name,
          uids: sampleGroup?.uids,
          fields: [`${prefix ? `${prefix}.` : ""}${fieldName}`],
        });

    if (!result) return;
    const numberValues = result
      .map((record: ISampleRecordPopulated) => record.chemicals?.[fieldName])
      .filter((v: any) => v) as number[];

    setDistributions(
      uniqBy(
        [
          ...distributions,
          {
            field: fieldName,
            numbers: numberValues,
            isSelectedRows: onlySelectedRows,
          },
        ],
        (v) => v.field
      )
    );
    setGettingAllFieldValues("");
    const appLayoutContainer = document.getElementById("app-layout-container");
    if (appLayoutContainer) {
      appLayoutContainer.scrollTo({
        top: appLayoutContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  // const addFieldToHeatmaps = async (fieldName: string, prefix?: string) => {
  //   if (!fieldName) return;
  //   // Plot only selected rows
  //   const result = rows.filter((r) => r.uid && selectedUids.includes(r.uid));
  //   if (!result?.length) return;
  //   const points = result
  //     .filter((r) => r.coordinates && r.chemicals?.[fieldName])
  //     .map((r) => ({
  //       lat: r.coordinates?.lat,
  //       lon: r.coordinates?.lon,
  //       value: r.chemicals?.[fieldName],
  //     }));

  //   setHeatmaps(
  //     uniqBy(
  //       [
  //         ...heatmaps,
  //         {
  //           field: fieldName,
  //           points: points,
  //         },
  //       ],
  //       (v) => v.field
  //     )
  //   );
  // };

  const addFieldToChoroplethMap = async ({
    fieldName,
    prefix,
    onlySelectedRows,
  }: {
    fieldName: string;
    prefix?: string;
    onlySelectedRows?: boolean;
  }) => {
    if (!fieldName) return;
    setChoroplethMapAddingFieldName(fieldName);
    // Plot only selected rows
    const displayRows: ISampleRecordPopulated[] = onlySelectedRows
      ? rows.filter((r) => r.uid && selectedUids.includes(r.uid))
      : await sampleRecordService.getFieldsData({
          searchTerm: searchTerm,
          chemicalFilters: chemicalValueFilters,
          rangeFilterFieldName: currentRangeFilterOption.fieldName,
          minUid,
          maxUid,
          companyId: selectedCompany?._id,
          sampleSource: selectedSampleSource?.name,
          uids: sampleGroup?.uids,
          fields: [`${prefix ? `${prefix}.` : ""}${fieldName}`],
        });
    if (!displayRows) return;

    const uniqueRegions = uniqBy(
      displayRows
        .filter((r) => r.country)
        .map((r) => `${r.country || ""}::${r.region || ""}`),
      (v) => v
    );

    const uniqueDietRegions = uniqBy(
      displayRows.filter((r) => r.dietRegion).map((r) => Number(r.dietRegion)),
      (v) => v
    );

    const dietRegionNames = uniqBy(
      DietRegions.filter((r) => uniqueDietRegions.includes(r.value)).map(
        (r) => r.regionName
      ),
      (v) => v
    );

    const dietRegionMapData = dietRegionNames.length
      ? await fetch("/dietRegionMapData.json").then((res) => res.json())
      : [];

    const geoJsons = await Promise.all(
      [...uniqueRegions].map((r) => {
        return getRegionBoundaryCoordinates(
          r.includes("::") ? r.split("::")[0] || "" : "USA",
          r.includes("::") ? r.split("::")[1] || "" : r
        );
      })
    );

    const regionToGeoJson = new Map<string, any>();
    geoJsons.forEach((geoJson, index) => {
      regionToGeoJson.set([...uniqueRegions][index], geoJson);
    });

    const regionValues: {
      region: string;
      value: number;
      geojson?: any;
    }[] = uniqueRegions.map((uniqueRegion) => {
      const samplesInUniqRegion = displayRows.filter(
        (r) => `${r.country || ""}::${r.region || ""}` == uniqueRegion
      );
      const averageValue = calculateAverage(
        samplesInUniqRegion
          .map((r) => r.chemicals?.[fieldName])
          .filter((v) => v) as number[],
        2
      );
      return {
        region: uniqueRegion,
        value: averageValue,
        geojson: regionToGeoJson.get(uniqueRegion),
      };
    });

    const dietRegionValues: {
      region: string;
      value: number;
      geojson?: any;
    }[] = dietRegionNames.map((uniqueRegion) => {
      const samplesInUniqRegion = displayRows.filter((r) =>
        DietRegions.find(
          (d) => d.value == Number(r.dietRegion) && d.regionName == uniqueRegion
        )
      );
      const averageValue = calculateAverage(
        samplesInUniqRegion
          .map((r) => r.chemicals?.[fieldName])
          .filter((v) => v) as number[],
        2
      );
      return {
        region: uniqueRegion,
        value: averageValue,
        geojson: dietRegionMapData.find(
          (r: any) => r.regionName == uniqueRegion
        )?.geoJson,
      };
    });

    setChoroplethMaps([
      ...choroplethMaps.filter((c) => c.field != fieldName),
      {
        field: fieldName,
        regions: regionValues,
        dietRegions: dietRegionValues,
        onlySelectedRows: onlySelectedRows,
        selectedSamples: onlySelectedRows
          ? rows.filter((r) => r.uid && selectedUids.includes(r.uid))
          : undefined,
        filterModel: onlySelectedRows ? undefined : currentFilterModel,
      },
    ]);
    setChoroplethMapAddingFieldName("");
  };

  const fetchBatchGasReportData = async () => {
    setFetchingBatchReportData(true);
    const chemFieldNames = ChemicalFields.map((f) => f.fieldName);
    const result = selectedUids.length
      ? rows.filter((r) => r.uid && selectedUids.includes(r.uid))
      : await sampleRecordService.getFieldsData({
          searchTerm: searchTerm,
          chemicalFilters: chemicalValueFilters,
          rangeFilterFieldName: currentRangeFilterOption.fieldName,
          minUid,
          maxUid,
          companyId: selectedCompany?._id,
          sampleSource: selectedSampleSource?.name,
          uids: sampleGroup?.uids,
          fields: [
            ...ChemicalDataBaseFields.map((f) => f.dbField),
            ...chemFieldNames.map((f) => `chemicals.${f}`),
          ],
        });
    setChemDataOfAllRows(result || []);
    setFetchingBatchReportData(false);
    setOpenBatchReportDialog(true);
  };

  const executeBatchReport = async (
    samplesToReport: ISampleRecordPopulated[]
  ) => {
    setBatchReportProcessing(true);
    for (let i = 0; i < samplesToReport.length; i++) {
      if (!batchReportAllowed) break;
      const sample = samplesToReport[i];
      const element: any = reportPdfRef.current;
      if (!element) continue;
      // Render the component into the hidden div
      element.innerHTML = "";
      const div = document.createElement("div");
      element.appendChild(div);
      ReactDOM.render(<GasReport2 sampleRecord={sample} />, div);
      // Convert to canvas and then to PDF
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${sample.sampleNumber}_gas_report.pdf`);
      setBatchReportDownloadingProgress({
        total: samplesToReport.length,
        done: i + 1,
      });
    }
    setBatchReportProcessing(false);
    setBatchReportDownloadingProgress(undefined);
  };

  const TextCellEditElement = (
    params: GridRenderEditCellParams<
      ISampleRecordPopulated,
      any,
      any,
      GridTreeNodeWithRender
    >
  ) => {
    return (
      <TextField
        size="small"
        value={editingRow?.[params.field as keyof ISampleRecordPopulated]}
        onChange={(e) => {
          if (editingRow?.uid)
            setEditingRow({
              ...editingRow,
              [params.field as keyof ISampleRecordPopulated]: e.target.value,
            });
        }}
        sx={{
          "& input": {
            py: "6.7px !important",
          },
        }}
        inputProps={{
          tabIndex: columns.findIndex(
            (column) => column.field === params.field
          ),
        }}
      />
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          px: "12px",
          py: 1,
          borderBottom: "solid 1px #ddd",
          "& button": { height: "30px" },
        }}
      >
        <GridToolbarColumnsButton />
        <Button
          onClick={() => setHideMetaData(!hideMetaData)}
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          startIcon={!hideMetaData ? <VisibilityIcon /> : <VisibilityOffIcon />}
        >
          Metadata
        </Button>
        <Button
          onClick={() => setHideDataHealthColumns(!hideDataHealthColumns)}
          variant="outlined"
          size="small"
          startIcon={
            !hideDataHealthColumns ? <VisibilityIcon /> : <VisibilityOffIcon />
          }
        >
          Data Health
        </Button>
        <Button
          onClick={() => setHideCalibrationColumns(!hideCalibrationColumns)}
          variant="outlined"
          size="small"
          startIcon={
            !hideCalibrationColumns ? <VisibilityIcon /> : <VisibilityOffIcon />
          }
        >
          Calibration
        </Button>

        {selectedUids?.length ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="small"
              variant="contained"
              startIcon={<ShoppingBasketIcon />}
              sx={{ mr: 1 }}
              onClick={() =>
                setCollectedRows(
                  uniqBy(
                    [
                      ...collectedRows,
                      ...rows.filter((r) => selectedUids.includes(r.uid)),
                    ],
                    (v) => v.uid
                  )
                )
              }
            >
              + Add to collection
            </Button>

            <ProgressButton
              inProgress={loadingSpectraData}
              title="+ Add to graph"
              size="small"
              variant="contained"
              startIcon={<SsidChartIcon />}
              disableElevation={false}
              sx={{ mr: 1 }}
              onClick={() => handleAddSpectraData(selectedUids as number[])}
            />
            <Button
              size="small"
              variant="contained"
              startIcon={<CalculateIcon />}
              onClick={() => calculateAnalysisNumbers()}
              sx={{ mr: 1 }}
            >
              Analyze
            </Button>
            <Button
              size="small"
              variant="contained"
              startIcon={<PrintIcon />}
              onClick={() => {
                setOpenSampleBagCoverPrintDialog(true);
              }}
              sx={{ mr: 1 }}
            >
              Print Covers
            </Button>
            <Button
              onClick={() => setOpenDeleteConfirmationDialog(true)}
              startIcon={<DeleteIcon />}
              size="small"
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </div>
        ) : null}
        {pinnedRows?.length ? (
          <Button
            onClick={() => setPinnedRows([])}
            size="small"
            color="error"
            variant="outlined"
            startIcon={<CloseIcon />}
          >
            Clear Analysis
          </Button>
        ) : null}

        <Box sx={{ flex: 1 }} />
        {/* <ButtonGroup aria-label="Basic button group" disableElevation>
          <Button
            variant={showChemicals ? "contained" : "outlined"}
            onClick={() => setShowChemicals(true)}
          >
            Chem
          </Button>
          <Button
            variant={showSpectra ? "contained" : "outlined"}
            onClick={() => setShowSpectra(true)}
          >
            Spectra
          </Button>
        </ButtonGroup> */}
      </GridToolbarContainer>
    );
  }

  const getChemicalNumberValueColumn = (
    field: ChemicalField
  ): GridColDef<ISampleRecordPopulated> => {
    return {
      field: `chemicals.${field.fieldName}`, // added chemicals. for recognizing that it's chemical column especially in sortModel
      headerName: field.fieldName,
      width: 120,
      sortable: true,
      filterable: false,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        const updates = row.manualUpdates?.filter((u) =>
          u.updatedFields?.some((f) => f.fieldName == field.fieldName)
        );
        let value: string | number | undefined =
          row.chemicals?.[
            [field.fieldName, ...(field.aliases || [])].find(
              (fName) => row.chemicals?.[fName]
            ) || "none"
          ];

        const numberOfDecimals = field.numberOfDecimals;

        if (value && numberOfDecimals != undefined) {
          value = Number(value).toFixed(numberOfDecimals);
        }

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {value || ""}
            {updates?.length ? (
              <GeneralPopoverWrapper
                triggerElement={
                  <Chip
                    color="warning"
                    size="small"
                    variant="outlined"
                    sx={{ cursor: "pointer", ml: 1, display: "none" }}
                    className="field-update-history"
                    label={updates.length}
                  />
                }
                popoverContent={
                  <Box py={1} sx={{ px: "12px", background: "#eee" }}>
                    {updates.map((update, i) => (
                      <Paper key={`update-${i}`} sx={{ my: "4px" }}>
                        <SampleRecordManualUpdateItem update={update} />
                      </Paper>
                    ))}
                  </Box>
                }
              />
            ) : null}
          </div>
        );
      },
      renderHeader: (
        params: GridColumnHeaderParams<ISampleRecordPopulated, any, any>
      ) => {
        return (
          <Box
            sx={{
              minWidth: "80px",
              display: "flex",
              alignItems: "center",
              "&:hover .fieldName": {
                display: "none",
              },
              "&:hover .actionButton": { display: "flex" },
            }}
          >
            <div className="fieldName">{field.fieldName}</div>

            {gettingAllFieldValues == field.fieldName ? (
              <CircularProgress size={19} />
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <GeneralPopoverWrapper
                  closeOnClickContent
                  anchorVertical="top"
                  transformVertical="bottom"
                  stopPropagationOnClick
                  triggerElement={
                    <Tooltip
                      title={`${field.fieldName} Distribution Graph`}
                      placement="bottom"
                      arrow
                    >
                      <IconButton
                        className="actionButton"
                        size="small"
                        sx={{ display: "none", width: "27px", height: "27px" }}
                      >
                        <BarChartIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  popoverContent={
                    <Box>
                      {selectedUids?.length ? (
                        <MenuItem
                          onClick={(e) => {
                            addFieldToDistributions({
                              fieldName: field.fieldName,
                              prefix: "chemicals",
                              onlySelectedRows: true,
                            });
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <AssessmentIcon sx={{ mr: 1 }} />
                            {`${field.fieldName} Distribution of Only Selected Rows (${selectedUids?.length})`}
                          </div>
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        onClick={(e) => {
                          addFieldToDistributions({
                            fieldName: field.fieldName,
                            prefix: "chemicals",
                            onlySelectedRows: false,
                          });
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <BarChartIcon sx={{ mr: 1 }} />
                          {`${field.fieldName} Distribution of All Search Results (${totalCount})`}
                        </div>
                      </MenuItem>
                    </Box>
                  }
                />
                <GeneralPopoverWrapper
                  closeOnClickContent
                  anchorVertical="top"
                  transformVertical="bottom"
                  stopPropagationOnClick
                  triggerElement={
                    <Tooltip
                      title={`${field.fieldName} Heatmap`}
                      placement="bottom"
                      arrow
                    >
                      <IconButton
                        disabled={
                          choroplethMapAddingFieldName == field.fieldName
                        }
                        className="actionButton"
                        size="small"
                        sx={{ display: "none", width: "27px", height: "27px" }}
                      >
                        {choroplethMapAddingFieldName == field.fieldName ? (
                          <CircularProgress size={19} />
                        ) : (
                          <PublicIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  }
                  popoverContent={
                    <Box>
                      {selectedUids?.length ? (
                        <MenuItem
                          onClick={(e) => {
                            addFieldToChoroplethMap({
                              fieldName: field.fieldName,
                              prefix: "chemicals",
                              onlySelectedRows: true,
                            });
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <PublicIcon sx={{ mr: 1 }} />
                            {`${field.fieldName} Map of Only Selected Rows (${selectedUids?.length})`}
                          </div>
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        onClick={(e) => {
                          addFieldToChoroplethMap({
                            fieldName: field.fieldName,
                            prefix: "chemicals",
                            onlySelectedRows: false,
                          });
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <PublicIcon sx={{ mr: 1 }} />
                          {`${field.fieldName} Map of All Search Results (${totalCount})`}
                        </div>
                      </MenuItem>
                    </Box>
                  }
                />
              </div>
            )}
          </Box>
        );
      },
      editable: true,
      renderEditCell: (params) => (
        <TextField
          size="small"
          value={editingRow?.chemicals?.[field.fieldName]}
          onChange={(e) => {
            if (editingRow?.uid)
              setEditingRow({
                ...editingRow,
                chemicals: {
                  ...(editingRow?.chemicals || {}),
                  [field.fieldName]: Number(e.target.value) || undefined,
                },
              });
          }}
          type="number"
          sx={{
            "& input": {
              py: "6.7px !important",
            },
          }}
        />
      ),
    } as GridColDef<ISampleRecordPopulated>;
  };

  const calibrationColumns: GridColDef<ISampleRecordPopulated>[] = [
    {
      field: "scannerCalibrationFiles",
      headerName: "Calibration",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <GeneralPopoverWrapper
            disablePop={!row.scannerCalibrationFiles?.length}
            triggerElement={
              <Chip
                variant="outlined"
                sx={{ cursor: "pointer" }}
                size="small"
                label={`${row.scannerCalibrationFiles?.length || 0} File`}
              />
            }
            popoverContent={
              <Box p={2}>
                <div style={{ marginBottom: "12px" }}>
                  Scanner Calibration Files
                </div>
                <div>
                  {row.scannerCalibrationFiles?.map((calFile) => (
                    <AttachedFileItem key={calFile} url={calFile} mb={8} />
                  ))}
                </div>
              </Box>
            }
          />
        );
      },
      sortable: false,
      filterable: false,
    },
  ];

  const metadataColumns: GridColDef<ISampleRecordPopulated>[] = [
    {
      field: "company",
      headerName: "Lab",
      width: 130,
      valueGetter: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return row.vendorCompany?.name || row.company?.name?.toString() || "";
      },
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return row.uid % 1 ? (
          ""
        ) : (
          <div>
            {highLightWords(
              row.vendorCompany?.name || row.company?.name?.toString() || "",
              [searchTerm]
            )}
          </div>
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "sampleClient",
      headerName: "Client",
      width: 130,
      valueGetter: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return !row.vendorCompany
          ? row.sampleClient || ""
          : row.company?._id && row.company._id != myCompanyProfile?._id
          ? row.company.name
          : row.sampleClient || "";
      },
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <div>
            {highLightWords(
              !row.vendorCompany
                ? row.sampleClient || ""
                : row.company?._id && row.company._id != myCompanyProfile?._id
                ? row.company.name || ""
                : row.sampleClient || "",
              [searchTerm]
            )}
          </div>
        );
      },
      sortable: false,
      filterable: false,
      // editable: false,
      // renderEditCell: TextCellEditElement,
    },
    {
      field: "sampleSource",
      headerName: "Farm",
      width: 130,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <div>{highLightWords(row.sampleSource || "", [searchTerm])}</div>
        );
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "dataSource",
      headerName: "Data Source",
      width: 130,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return <div>{highLightWords(row.dataSource || "", [searchTerm])}</div>;
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "description",
      headerName: "Description",
      width: 130,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return <div>{highLightWords(row.description || "", [searchTerm])}</div>;
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "bagWeight",
      headerName: "Bag Weight (mg)",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.value ? Number(params.value).toFixed(3) : ""}
            <div style={{ marginLeft: "8px", opacity: 0.5 }}>
              {params.value ? "mg" : ""}
            </div>
          </div>
        );
      },
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "bagWeight48",
      headerName: "Bag Weight 48 Hr (mg)",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.value ? Number(params.value).toFixed(3) : ""}
            <div style={{ marginLeft: "8px", opacity: 0.5 }}>
              {params.value ? "mg" : ""}
            </div>
          </div>
        );
      },
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "wetSampleWeight",
      headerName: "Wet Sample Weight (g)",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.value ? Number(params.value).toFixed(3) : ""}
            <div style={{ marginLeft: "8px", opacity: 0.5 }}>
              {params.value ? "g" : ""}
            </div>
          </div>
        );
      },
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "drySampleWeight",
      headerName: "Dry Sample Weight (g)",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.value ? Number(params.value).toFixed(3) : ""}
            <div style={{ marginLeft: "8px", opacity: 0.5 }}>
              {params.value ? "g" : ""}
            </div>
          </div>
        );
      },
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "panWeight",
      headerName: "Pan Weight (g)",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.value ? Number(params.value).toFixed(3) : ""}
            <div style={{ marginLeft: "8px", opacity: 0.5 }}>
              {params.value ? "g" : ""}
            </div>
          </div>
        );
      },
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "predictionAiModel",
      headerName: "Prediction Model",
      width: 150,
      valueGetter: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return row.predictionAiModel?.title || row.predictionAiModel?.date;
      },
    },
    {
      field: "dataset",
      headerName: "Dataset",
      width: 70,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return <div>{highLightWords(row.dataset || "", [searchTerm])}</div>;
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "country",
      headerName: "Country",
      width: 80,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return <div>{highLightWords(row.country || "", [searchTerm])}</div>;
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "region",
      headerName: "Region",
      width: 120,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return <div>{highLightWords(row.region || "", [searchTerm])}</div>;
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "dietRegion",
      headerName: "Diet Region",
      width: 120,
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <Tooltip
            title={
              DietRegions.filter((r) => r.value == row.dietRegion)
                ?.map((r) => r.regionName)
                .join(", ") || ""
            }
            placement="right"
            arrow
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                display: "flex",
              }}
            >
              {row.dietRegion || ""}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "year",
      headerName: "Year",
      width: 65,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <div>{highLightWords(row.year?.toString() || "", [searchTerm])}</div>
        );
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "type",
      headerName: "Crop Type",
      width: 90,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return <div>{highLightWords(row.type || "", [searchTerm])}</div>;
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "reportType",
      headerName: "Report Type",
      width: 120,
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <ReportTypeSelect
            value={editingRow?.reportType as ReportTypes}
            onChange={(newVal) => {
              if (editingRow?.uid) {
                setEditingRow({
                  ...editingRow,
                  reportType: newVal,
                });
              }
            }}
          />
        );
      },
    },
    {
      field: "sampleTakenDate",
      headerName: "Sampled Date",
      width: 120,
      sortable: true,
      filterable: false,
      editable: true,
    },
    {
      field: "product",
      headerName: "Product",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return <div>{highLightWords(row.product || "", [searchTerm])}</div>;
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "application",
      headerName: "Application",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return <div>{highLightWords(row.application || "", [searchTerm])}</div>;
      },
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
  ];

  const dataHealthColumns: GridColDef<ISampleRecordPopulated>[] = [
    {
      field: "Gas",
      headerName: "Gas",
      width: 80,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        const status = row.gasAnalysis
          ? SampleDataHealthStatus.Success
          : SampleDataHealthStatus.Blank;
        return (
          <DataHealthMark
            className={status}
            onClick={() => {
              setTargetGasAnalysis(row.gasAnalysis);
              setOpenGasAnalysisViewDialog(true);
            }}
          >
            Gas
          </DataHealthMark>
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "GHG",
      headerName: "GHG",
      width: 80,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        const gasRequiredFieldNames = ChemicalFields.filter(
          (f) => f.requiredForGHG
        ).map((f) => f.fieldName);
        const missingRequiredFieldNames = gasRequiredFieldNames.filter(
          (f) => !row.chemicals?.[f]
        );
        const status =
          gasRequiredFieldNames.length == missingRequiredFieldNames.length
            ? SampleDataHealthStatus.Blank
            : missingRequiredFieldNames.length
            ? SampleDataHealthStatus.Warning
            : SampleDataHealthStatus.Success;
        return <DataHealthMark className={status}>GHG</DataHealthMark>;
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "Spectra",
      headerName: "Spectra",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        const status =
          row.spectraItems?.length || row.spectraData
            ? SampleDataHealthStatus.Success
            : SampleDataHealthStatus.Warning;
        return <DataHealthMark className={status}>Spectra</DataHealthMark>;
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "Chem",
      headerName: "Chem",
      width: 90,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        const gasRequiredFieldNames = ChemicalFields.filter(
          (f) => f.requiredForChemAnalysis
        ).map((f) => f.fieldName);
        const missingRequiredFieldNames = gasRequiredFieldNames.filter(
          (f) => !row.chemicals?.[f]
        );
        const status =
          gasRequiredFieldNames.length == missingRequiredFieldNames.length
            ? SampleDataHealthStatus.Blank
            : missingRequiredFieldNames.length
            ? SampleDataHealthStatus.Warning
            : SampleDataHealthStatus.Success;
        return <DataHealthMark className={status}>Chem</DataHealthMark>;
      },
      sortable: false,
      filterable: false,
    },
  ];

  const columns: GridColDef<ISampleRecordPopulated>[] = [
    {
      field: "uid",
      headerName: "UUID",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return row.uid % 1 ? (
          ""
        ) : (
          <div>{highLightWords(row.uid?.toString() || "", [searchTerm])}</div>
        );
      },
      sortable: true,
      filterable: false,
      editable: false,
    },
    {
      field: "companyUid",
      headerName: "Lab SSID",
      width: 130,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return row.uid % 1 ? (
          ""
        ) : (
          <div>
            {highLightWords(row.companyUid?.toString() || "", [searchTerm])}
          </div>
        );
      },
      sortable: true,
      filterable: false,
      editable: false,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "sampleNumber",
      headerName: "Sample #",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <div>{highLightWords(row.sampleNumber || "", [searchTerm])}</div>
        );
      },
      sortable: false,
      filterable: false,
      editable: false,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "spectra",
      headerName: "Spectra",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <div>
            {row.spectraData || row.spectraItems?.length ? (
              <GeneralPopoverWrapper
                transitionDuration={0}
                triggerElement={
                  <Tooltip
                    title="See spectra data in graph"
                    arrow
                    placement="right"
                  >
                    <Chip
                      onClick={() => {
                        handleAddSpectraData([row.uid], true);
                        setSpectraPopoverKey(spectraPopoverKey + 1);
                      }}
                      sx={{ cursor: "pointer" }}
                      size="small"
                      variant="outlined"
                      color="success"
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {uidsLoadingSpectraData.includes(row.uid) ? (
                            <CircularProgress size={16} />
                          ) : (
                            "Spectra"
                          )}
                        </div>
                      }
                    />
                  </Tooltip>
                }
                anchorHorizontal="right"
                popoverContent={
                  <Box p={2}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Typography variant="h6">Spectra Data for</Typography>
                      <Typography variant="h6" ml={1} color="warning.main">
                        {row.sampleNumber}
                      </Typography>
                    </Box>
                    {uidsLoadingSpectraData.includes(row.uid) ||
                    !row.spectraItems?.length ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "400px",
                          width: "500px",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Box>
                        <div
                          style={{
                            maxWidth: "800px",
                            border: "solid 1px #ddd",
                            borderRadius: "5px",
                            overflow: "hidden",
                          }}
                        >
                          <DataGridPro
                            columnHeaderHeight={40}
                            hideFooter
                            rows={[row]}
                            columns={[
                              ...getSpectraWaveLengths(row)
                                .filter(
                                  (wl) => !minWavelength || minWavelength < wl
                                )
                                .filter(
                                  (wl) => !maxWavelength || maxWavelength > wl
                                )
                                .map((wavelength) =>
                                  getSpectraNumberValueColumn(wavelength)
                                ),
                            ]}
                          />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            minWidth: "800px",
                          }}
                        >
                          <SpectraChart
                            key={`spectra-chart-${spectraPopoverKey}`}
                            height={400}
                            spectraDataItems={[row]}
                            hideVisibilityButton
                          />
                        </div>
                      </Box>
                    )}
                  </Box>
                }
              />
            ) : (
              <Tooltip title="No Spectra Data" arrow placement="right">
                <Chip
                  size="small"
                  variant="outlined"
                  color="error"
                  label="Spectra"
                />
              </Tooltip>
            )}
          </div>
        );
      },
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: "manualUpdates",
      headerName: "",
      width: 70,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        const manualUpdates = row.manualUpdates;
        if (!manualUpdates?.length) return null;
        return (
          <GeneralPopoverWrapper
            triggerElement={
              <Chip
                color="warning"
                size="small"
                variant="outlined"
                sx={{ cursor: "pointer" }}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <EditNoteIcon style={{ marginRight: "4px" }} />
                    {manualUpdates.length}
                  </div>
                }
              />
            }
            popoverContent={
              <Box py={1} sx={{ px: "12px", background: "#eee" }}>
                {manualUpdates.map((update, i) => (
                  <Paper key={`update-${i}`} sx={{ my: "4px" }}>
                    <SampleRecordManualUpdateItem update={update} />
                  </Paper>
                ))}
              </Box>
            }
          />
        );
      },
      sortable: false,
      filterable: false,
      editable: false,
    },
    ...(!hideMetaData ? metadataColumns : []),
    ...(!hideDataHealthColumns ? dataHealthColumns : []),
    ...(!hideCalibrationColumns ? calibrationColumns : []),
    ...(showChemicals
      ? ChemicalFields.map((field) => getChemicalNumberValueColumn(field))
      : []),
    ...(showSpectra
      ? waveLengths
          .filter((wl) => !minWavelength || minWavelength < wl)
          .filter((wl) => !maxWavelength || maxWavelength > wl)
          .map((wavelength) => getSpectraNumberValueColumn(wavelength))
      : []),
    {
      field: "actions",
      headerName: "Action",
      width: 65,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        const gasRequiredFieldNames = ChemicalFields.filter(
          (f) => f.requiredForGasReport
        ).map((f) => f.fieldName);
        const missingRequiredFieldNames = gasRequiredFieldNames.filter(
          (f) => !params.row.chemicals?.[f]
        );
        const outOfRangeFieldNames = Object.keys(AnalysisValueRanges).filter(
          (rangeKey) => {
            if (!params.row.chemicals) return false;
            const matchedChemicalField = ChemicalFields.find((cf) =>
              [cf.fieldName, ...(cf.aliases || [])].includes(rangeKey)
            );
            if (!matchedChemicalField) return false;
            const fieldValue =
              params.row.chemicals[matchedChemicalField.fieldName];
            if (fieldValue == undefined || fieldValue == 0) return false;
            if (
              (AnalysisValueRanges as any)[rangeKey]?.min &&
              fieldValue < (AnalysisValueRanges as any)[rangeKey]?.min
            ) {
              return true;
            }
            if (
              (AnalysisValueRanges as any)[rangeKey]?.max &&
              fieldValue > (AnalysisValueRanges as any)[rangeKey]?.max
            ) {
              return true;
            }
          }
        );
        return params.row.uid % 1 ? (
          ""
        ) : (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreHorizIcon
                    color={outOfRangeFieldNames?.length ? "error" : undefined}
                  />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    disabled={
                      !!missingRequiredFieldNames.length ||
                      (loggedInUserInfo?.role != UserRoles.superadmin &&
                        !!outOfRangeFieldNames?.length)
                    }
                    onClick={() => {
                      setTargetSampleRecord(params.row);
                      setOpenGasAnalysisReportDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <SummarizeIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <div>Gas Analysis Report</div>
                      {missingRequiredFieldNames.length ? (
                        <Box
                          sx={{
                            maxWidth: "300px",
                            whiteSpace: "break-spaces",
                            fontSize: "13px",
                            color: "error.main",
                          }}
                        >{`Missing Required Fields: ${missingRequiredFieldNames.join(
                          ", "
                        )}`}</Box>
                      ) : null}
                      {outOfRangeFieldNames.length ? (
                        <Box
                          sx={{
                            maxWidth: "300px",
                            whiteSpace: "break-spaces",
                            fontSize: "13px",
                            color: "error.main",
                          }}
                        >{`Out of range: ${outOfRangeFieldNames.join(
                          ", "
                        )}`}</Box>
                      ) : null}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetSampleRecord(params.row);
                      setOpenSampleBagCoverPrintDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <ClassIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <div>Print Sample Bag Cover</div>
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];
  const getSpectraWaveLengths = (record: ISampleRecordPopulated) => {
    const tempWaveLengths: number[] = [];
    record.spectraItems?.map((specItem) => {
      if (
        specItem.wavelength &&
        !tempWaveLengths.includes(specItem.wavelength)
      ) {
        tempWaveLengths.push(specItem.wavelength);
      }
    });
    return tempWaveLengths;
  };
  const extractSpectraData = (data: ISampleRecordPopulated[]) => {
    const tempWaveLengths: number[] = [];
    data.map((dataItem) => {
      dataItem.spectraItems?.map((specItem) => {
        if (
          specItem.wavelength &&
          !tempWaveLengths.includes(specItem.wavelength)
        ) {
          tempWaveLengths.push(specItem.wavelength);
        }
      });
    });
    setWaveLengths(tempWaveLengths);
  };

  const fetchData = async (pagination?: PaginationParams) => {
    setHighlightRefreshButton(false);
    setSelectedUids([]);
    try {
      const currentPaginationParams = pagination || paginationParams;
      setLoading(true);
      const result: {
        data: ISampleRecordPopulated[];
        count: number;
        minUidInvalid?: boolean;
        maxUidInvalid?: boolean;
      } = await sampleRecordService.getData({
        page: currentPaginationParams.page || 0,
        pageSize: currentPaginationParams.pageSize || 25,
        ...currentFilterModel,
      });
      setLoading(false);
      setRows(result.data);
      setTotalCount(result.count);
      extractSpectraData(result.data);
      setMinUidInvalid(result.minUidInvalid || false);
      setMaxUidInvalid(result.maxUidInvalid || false);
    } catch {}
  };

  const handleDeleteSampleRecords = async () => {
    setLoading(true);
    await sampleRecordService.delete(selectedUids as number[]);
    fetchData();
  };

  const handleAddSpectraData = async (uids: number[], onlyLoad?: boolean) => {
    setLoadingSpectraData(true);
    setUidsLoadingSpectraData(uids);
    const samplesWithSpectraItems = rows.filter(
      (r) => r.spectraItems?.length && uids.includes(r.uid)
    );
    const samplesWithoutSpectraItems = rows.filter(
      (r) => !r.spectraItems?.length && r.spectraData && uids.includes(r.uid)
    );
    let newlyLoadedSpectraData: ISampleRecordPopulated[] = [];
    if (samplesWithoutSpectraItems.length) {
      const sampleSpectraData = await sampleRecordService.getSpectraData(
        samplesWithoutSpectraItems.map((r) => r.spectraData || "")
      );
      setRows(
        rows.map((r) =>
          sampleSpectraData.some((s: { _id: string }) => s._id == r.spectraData)
            ? {
                ...r,
                spectraItems: sampleSpectraData.find(
                  (s: { _id: string }) => s._id == r.spectraData
                ).spectraItems,
              }
            : r
        )
      );
      newlyLoadedSpectraData = rows
        .filter((r) =>
          sampleSpectraData.some((s: { _id: string }) => s._id == r.spectraData)
        )
        .map((r) => ({
          ...r,
          spectraItems: sampleSpectraData.find(
            (s: { _id: string }) => s._id == r.spectraData
          ).spectraItems,
        }));
    }
    if (!onlyLoad) {
      setSpectraData(
        uniqBy(
          [
            ...spectraData,
            ...samplesWithSpectraItems,
            ...newlyLoadedSpectraData,
          ],
          (v) => v.uid
        )
      );
    }

    setLoadingSpectraData(false);
    setUidsLoadingSpectraData([]);
  };

  const handleExportData = async (
    fileName: string,
    description: string,
    groupName?: string,
    groupDescription?: string
  ) => {
    setExporting(true);
    const data: (ISampleRecordPopulated & { spectraItems: ISpectraItem[] })[] =
      await exportService.exportData({
        groupDescription,
        groupName,
        searchTerm,
        chemicalFilters: chemicalValueFilters,
        rangeFilterFieldName: currentRangeFilterOption.fieldName,
        minUid,
        maxUid,
        uids: selectedUids as number[],
        fileName,
        description,
        companyId: selectedCompany?._id,
        sampleSource: selectedSampleSource?.name,
        sortModel: sortModel?.length
          ? {
              [sortModel[0].field]: sortModel[0].sort == "desc" ? -1 : 1,
            }
          : undefined,
      });
    const exportRows = data.map((record) => {
      const row: any = { ...record.chemicals };
      // delete row.chemicals;
      // const spectraObject: any = {};
      // row.spectraItems?.map((spec: ISpectraItem) => {
      //   spectraObject[`${spec.wavelength}`] = spec.value;
      // });
      // delete row.spectraItems;
      // delete row._id;
      // delete row.id;
      // delete row.__v;
      // delete row.createdAt;
      // delete row.updatedAt;
      ChemicalDataBaseFields.map((field) => {
        if (field.fieldName != field.dbField) {
          row[field.fieldName] = record[field.dbField] || "";
        }
      });
      return { ...row, "Datalake UID": record.uid };
    });
    const ws = XLSX.utils.json_to_sheet(exportRows, {
      header: [
        "Datalake UID",
        ...ChemicalDataBaseFields.map((field) => field.fieldName),
        ...ChemicalFields.map((field) => field.fieldName),
      ],
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName.trim()}.xlsx`);
    setExporting(false);
    setComponentsRefreshKey(componentsRefreshKey + 1);
    if (groupName) {
      setSampleGroupListRefreshKey(sampleGroupListRefreshKey + 1);
    }
  };

  const saveSearchResultsAsGroup = async (
    groupName: string,
    description: string
  ) => {
    setGroupingInProgress(true);
    const uidsForGroup = selectedUids.length
      ? selectedUids
      : (
          await sampleRecordService.getFieldsData({
            searchTerm: searchTerm,
            chemicalFilters: chemicalValueFilters,
            rangeFilterFieldName: currentRangeFilterOption.fieldName,
            minUid,
            maxUid,
            companyId: selectedCompany?._id,
            sampleSource: selectedSampleSource?.name,
            uids: sampleGroup?.uids,
            fields: [],
          })
        ).map((record: ISampleRecordPopulated) => record.uid);

    if (!uidsForGroup?.length) {
      return;
    }
    const createdGroup = await sampleGroupService.createNewGroup({
      name: groupName,
      uids: uidsForGroup,
      description,
    });
    setGroupingInProgress(false);
    setSampleGroupListRefreshKey(componentsRefreshKey + 1);
  };

  const handleRowUpdated = (uid: number, row: ISampleRecordPopulated) => {
    const tempRows = [...rows];
    const targetIndex = tempRows.findIndex((r) => r.uid == uid);
    if (targetIndex < 0) return;
    tempRows.splice(targetIndex, 1, row);
    setRows(tempRows);
  };

  const handleUpdateRow = async (
    uid: number,
    updatedRecord: ISampleRecordPopulated,
    reason?: string
  ) => {
    if (!editingRow) return;
    const originalSampleRecord = rows.find((r) => r.uid == uid);
    if (!originalSampleRecord) return;
    const updates: ISampleRecordUpdatePayload[] = getSampleRecordUpdates(
      originalSampleRecord,
      updatedRecord
    );
    if (!updates.length) return;
    setLoading(true);
    const updateData: any = {
      sampleClient: updatedRecord.sampleClient,
      sampleSource: updatedRecord.sampleSource,
      dataSource: updatedRecord.dataSource,
      sampleTakenDate: updatedRecord.sampleTakenDate,
      bagWeight: updatedRecord.bagWeight,
      drySampleWeight: updatedRecord.drySampleWeight,
      bagWeight48: updatedRecord.bagWeight48,
      panWeight: updatedRecord.panWeight,
      wetSampleWeight: updatedRecord.wetSampleWeight,
      dietRegion: updatedRecord.dietRegion,
      dataset: updatedRecord.dataset,
      reportType: updatedRecord.reportType,
      country: updatedRecord.country,
      region: updatedRecord.region,
      // coordinates: updatedRecord.coordinates,
      year: updatedRecord.year,
      product: updatedRecord.product,
      application: updatedRecord.application,
      chemicals: updatedRecord.chemicals,
      description: updatedRecord.description,
    };
    const result: ISampleRecordPopulated =
      await sampleRecordService.updateRecord(
        uid,
        updateData,
        updates.length ? updates : undefined,
        reason
      );
    if (result?._id) {
      handleRowUpdated(uid, result);
    }
    setLoading(false);
    setEditingRow(undefined);
    return result;
  };

  const fetchAllAdditiveAlgorithms = async () => {
    const result = await additiveAlgorithmService.getAdditiveAlgorithms();
    setAllAdditiveAlgorithms(result);
  };

  React.useEffect(() => {
    if (showChemicals) {
      setShowSpectra(false);
    }
  }, [showChemicals]);

  React.useEffect(() => {
    if (showSpectra) {
      setShowChemicals(false);
    }
  }, [showSpectra]);

  React.useEffect(() => {
    fetchData();
  }, [
    searchTerm,
    chemicalValueFilters,
    minUid,
    maxUid,
    selectedCompany,
    selectedSampleSource,
    sortModel,
  ]);

  React.useEffect(() => {
    if (searchTerm) {
      setSearchTerm("");
    }
    if (chemicalValueFilters?.length) {
      setChemicalValueFilters([]);
    } else if (
      JSON.stringify(prevStates?.sampleGroup || {}) !=
        JSON.stringify(sampleGroup || {}) && sampleGroup
        ? JSON.stringify(sampleGroup)
        : "" != JSON.stringify(initialSampleGroup || {})
    ) {
      setChemicalValueFilters([]);
    }
    if (minUid) {
      setMinUid(undefined);
    }
    if (maxUid) {
      setMaxUid(undefined);
    }
    if (selectedCompany) {
      setSelectedCompany(undefined);
    }
    if (selectedSampleSource) {
      setSelectedSampleSource(undefined);
    }
    setComponentsRefreshKey(componentsRefreshKey + 1);
    if (sampleGroup?._id) {
      const urlQueryName =
        sampleGroup.type == SampleGroupType.Collection
          ? "sample-group-id"
          : sampleGroup.type == SampleGroupType.DownloadLog
          ? "download-log-id"
          : sampleGroup.type == SampleGroupType.UploadLog
          ? "upload-log-id"
          : "";
      navigate(`/dashboard?${urlQueryName}=${sampleGroup._id}`);
    } else {
      navigate(`/dashboard`);
    }
  }, [sampleGroup]);

  React.useEffect(() => {
    fetchAllAdditiveAlgorithms();
  }, []);

  return (
    <div>
      {sampleGroup ? (
        <WhitePanel
          sx={{
            display: "inline-flex",
            marginBottom: "-15px",
            borderRadius: "0px",
            borderBottom: "none",
            borderRight: "none",
            alignItems: "center",
            padding: "3px 8px 20px 18px",
            borderTop: "solid 3px #0288d1",
          }}
        >
          <div style={{ color: "#666" }}>
            {sampleGroup.type == SampleGroupType.Collection ? (
              <FolderZipIcon sx={{ mr: 1 }} />
            ) : sampleGroup.type == SampleGroupType.DownloadLog ? (
              <FileDownloadIcon sx={{ mr: 1, marginBottom: "-6px" }} />
            ) : sampleGroup.type == SampleGroupType.UploadLog ? (
              <UploadIcon sx={{ mr: 1, marginBottom: "-6px" }} />
            ) : null}
          </div>
          <Box sx={{ color: "info.main", fontWeight: "500" }}>
            {sampleGroup.name}
          </Box>
          <IconButton
            size="small"
            sx={{ ml: 2 }}
            onClick={() => setSampleGroup(undefined)}
          >
            <CloseIcon />
          </IconButton>
        </WhitePanel>
      ) : null}
      <WhitePanel>
        <Box
          sx={{
            pb: 2,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& .MuiIconButton-root": { padding: "3px" },
          }}
        >
          <HighlightableRefreshButton
            highlighted={highlightRefreshButton}
            execRefresh={() => {
              setComponentsRefreshKey(componentsRefreshKey + 1);
              fetchData();
            }}
            loading={loading}
          />
          <Box sx={{ mr: 1, my: "4px", width: "220px" }}>
            <CompanyAutoComplete
              enabled={myClients?.length ? true : false}
              limitedOptions={
                ![UserRoles.superadmin, UserRoles.adminStaff].includes(
                  loggedInUserInfo?.role || ("" as any)
                ) && myClients?.length
                  ? myClients
                  : undefined
              }
              key={componentsRefreshKey}
              fullWidth
              py={2.5}
              value={selectedCompany}
              onChange={(selected) => setSelectedCompany(selected || undefined)}
            />
          </Box>
          {/* <Box sx={{ mr: 1, my: "4px", width: "220px" }}>
            <SampleSourceAutoComplete
              key={componentsRefreshKey}
              fullWidth
              py={2.5}
              value={selectedSampleSource}
              onChange={(selected) =>
                setSelectedSampleSource(selected || undefined)
              }
            />
          </Box> */}
          <div style={{ maxWidth: "180px" }}>
            <SimpleSearchInputWithDebounce
              key={componentsRefreshKey}
              searchTerm={searchTerm}
              onChangeSearchTerm={(changed) => {
                setSearchTerm(changed);
              }}
              sx={{
                "& input": { padding: "5px 10px 5px 0px" },
                mr: 1,
                my: "4px",
              }}
            />
          </div>
          <Box mr={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                paddingLeft: "4px",
                background: "#ebebeb",
              }}
            >
              <GeneralPopoverWrapper
                triggerElement={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      padding: "4px 6px",
                      borderRadius: "4px",
                      border: "solid 1px #e6e6e6",
                      marginRight: "4px",
                    }}
                  >
                    <div style={{ fontSize: "14px" }}>
                      {currentRangeFilterOption.title}
                    </div>
                    <KeyboardArrowDownIcon
                      sx={{ ml: "4px", fontSize: "17px" }}
                    />
                  </Box>
                }
                closeOnClickContent
                popoverContent={
                  <Box p={1}>
                    {rangeFilterOptions.map((option) => (
                      <MenuItem
                        key={option.fieldName}
                        onClick={() => setCurrentRangeFilterOption(option)}
                        selected={
                          currentRangeFilterOption.fieldName == option.fieldName
                        }
                        sx={{
                          borderLeft:
                            currentRangeFilterOption.fieldName ==
                            option.fieldName
                              ? "solid 2px #29736f"
                              : "",
                        }}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </Box>
                }
              />

              <DebouncedRangeInput
                minInvalid={minUidInvalid}
                maxInvalid={maxUidInvalid}
                key={componentsRefreshKey}
                minTitle="from ~"
                maxTitle="~ to"
                min={minUid}
                max={maxUid}
                onChangeMin={(changed) => setMinUid(changed)}
                onChangeMax={(changed) => setMaxUid(changed)}
              />
            </div>
          </Box>
          <ChemicalValueFilter
            currentFilters={chemicalValueFilters}
            onSubmit={(newFilter) =>
              setChemicalValueFilters([...chemicalValueFilters, newFilter])
            }
            triggerElement={
              <Button
                startIcon={<FilterAltIcon />}
                variant="outlined"
                sx={{
                  height: "32px",
                  fontSize: "15px !important",
                  border: "solid 1px #777",
                  opacity: "0.8",
                  mr: 1,
                  color: "#464646",
                  my: "4px",
                }}
                size="small"
              >
                Chem Filter
              </Button>
            }
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "solid 1px #949494",
              borderRadius: "4px",
              padding: "0px 3px",
              mr: 1,
              my: "4px",
            }}
          >
            <Tooltip
              title={`Download ${
                selectedUids?.length ? selectedUids.length : totalCount
              } sample data`}
              placement="top"
              arrow
            >
              <IconButton
                size="small"
                onClick={() => setOpenExportDialog(true)}
                sx={{ mr: "4px" }}
              >
                {exporting ? <CircularProgress size={18} /> : <SaveAltIcon />}
              </IconButton>
            </Tooltip>
            <ExportLogPopover
              key={componentsRefreshKey}
              viewSampleGroup={(group) => setSampleGroup(group)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "solid 1px #949494",
              borderRadius: "4px",
              padding: "0px 3px",
              mr: 1,
              my: "4px",
            }}
          >
            <Tooltip
              title={`Create a group of ${
                selectedUids?.length ? selectedUids.length : totalCount
              } samples`}
              placement="top"
              arrow
            >
              <IconButton
                size="small"
                onClick={() => setOpenGroupCreationDialog(true)}
                sx={{ mr: "4px" }}
              >
                {groupingInProgress ? (
                  <CircularProgress size={18} />
                ) : (
                  <CreateNewFolderIcon />
                )}
              </IconButton>
            </Tooltip>
            <SampleGroupPopover
              key={sampleGroupListRefreshKey}
              onSelectGroup={(group) =>
                setSampleGroup({ ...group, type: SampleGroupType.Collection })
              }
            />
          </Box>
          <Tooltip
            title={
              batchReportProcessing
                ? ""
                : `Generate reports for ${
                    selectedUids?.length ? selectedUids.length : totalCount
                  } samples`
            }
            placement="top"
            arrow
          >
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              sx={{
                borderColor: "#949494",
                color: "#6c6c6c",
                my: "4px",
                "&.Mui-disabled .batch-report-count": {
                  display: "none",
                },
              }}
              startIcon={
                fetchingBatchReportData || batchReportProcessing ? (
                  <CircularProgress size={16} />
                ) : (
                  <SummarizeIcon />
                )
              }
              endIcon={
                batchReportProcessing ? (
                  <Tooltip
                    title="Stop Downloading Reports"
                    placement="top"
                    arrow
                  >
                    <IconButton
                      onClick={() => {
                        setBatchReportAllowed(false);
                        setTimeout(() => {
                          setBatchReportAllowed(true);
                        }, 3000);
                      }}
                      size="small"
                      sx={{
                        width: "22px",
                        height: "22px",
                        border: "solid 2px",
                      }}
                      color="error"
                    >
                      <StopIcon sx={{ fontSize: "20px" }} color="error" />
                    </IconButton>
                  </Tooltip>
                ) : undefined
              }
              disabled={
                (!selectedUids.length &&
                  !searchTerm &&
                  !chemicalValueFilters?.length &&
                  !minUid &&
                  !maxUid &&
                  !selectedCompany &&
                  !selectedSampleSource &&
                  !sampleGroup?.uids?.length) ||
                !batchReportAllowed ||
                fetchingBatchReportData
              }
              onClick={() => {
                if (batchReportProcessing) return;
                fetchBatchGasReportData();
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {batchReportProcessing
                  ? `${
                      batchReportDownloadingProgress?.total
                        ? `${batchReportDownloadingProgress?.done || 0}/${
                            batchReportDownloadingProgress?.total || 0
                          } finished...`
                        : "Downloading..."
                    }`
                  : `Batch Report`}
                {!batchReportProcessing && (
                  <Box
                    className="batch-report-count"
                    sx={{ color: "warning.main", ml: 1 }}
                  >{`(${
                    selectedUids?.length ? selectedUids.length : totalCount
                  })`}</Box>
                )}
              </div>
            </Button>
          </Tooltip>

          <div style={{ flex: 1 }} />
          <CollectionBucketPopover
            collectedRows={collectedRows}
            onChangeCollectedRows={(changedRows) =>
              setCollectedRows(changedRows)
            }
            displayRowsInTable={(rowsToDisplay) => {
              setHighlightRefreshButton(true);
              setRows(rowsToDisplay);
              setSelectedUids([]);
            }}
            addRowIdsToSpectraGraph={(rowIds) => handleAddSpectraData(rowIds)}
            loadingSpectraData={loadingSpectraData}
          />
        </Box>
        {chemicalValueFilters?.length ? (
          <Box>
            {chemicalValueFilters.map((filter, i) => {
              const operator =
                filter.max && filter.min
                  ? FilterOperators.between
                  : filter.max
                  ? FilterOperators.lessThan
                  : FilterOperators.greaterThan;
              return (
                <Chip
                  key={`filter-${i}`}
                  label={`${filter.field} ${
                    operator == FilterOperators.between ? `  ` : operator
                  } ${
                    operator == FilterOperators.between
                      ? `${filter.min} ~ ${filter.max}`
                      : filter.min || filter.max
                  }`}
                  onDelete={() => {
                    const tempFilters = [...chemicalValueFilters];
                    tempFilters.splice(i, 1);
                    setChemicalValueFilters(tempFilters);
                  }}
                  size="small"
                  color="primary"
                  sx={{ mr: 1, mb: 1, fontWeight: "400" }}
                />
              );
            })}
          </Box>
        ) : null}
        <Box
          key={windowHeight}
          sx={{
            height: `${(windowHeight || 800) - 195}px`,
            margin: "auto",
            border: "solid 1px #ddd",
            borderRadius: "7px",
            overflow: "hidden",
            "& .MuiDataGrid-sortIcon": {
              color: "#ff2100",
              fontSize: "24px",
            },
            "& .MuiDataGrid-pinnedRows": {
              backgroundColor: "#faf0e1",
            },
            "& .analytics-label": {
              backgroundColor: "#e3cba8",
            },
            "& .MuiDataGrid-row:hover .field-update-history": {
              display: "flex !important",
            },
          }}
        >
          <CustomMuiDataGrid
            tableId={TableIds.DataLakeDashboardTable}
            fixedHeight={(windowHeight || 800) - 195}
            getCellClassName={(params) => {
              const isAnalyticsRow = params.row.uid % 1 != 0;
              const isSampleNumberField = params.field == "sampleNumber";
              if (isAnalyticsRow && isSampleNumberField) {
                return "analytics-label";
              } else {
                return "";
              }
            }}
            onRowDoubleClick={(params) => {
              if (editingRow?.uid != params.id) {
                setEditingRow(params.row);
              }
            }}
            processRowUpdate={async (newRow, oldRow) => {
              if (editingRow) {
                const originalSampleRecord = rows.find(
                  (r) => r.uid == editingRow.uid
                );
                if (!originalSampleRecord) return newRow;

                const updates = getSampleRecordUpdates(
                  originalSampleRecord,
                  editingRow
                );
                if (!updates.length) return newRow;
                // const updatedRow = cloneDeep(editingRow);
                // if (
                //   `${editingRow.country}__${editingRow.region}` !=
                //   `${oldRow.country}__${oldRow.region}`
                // ) {
                //   const changedCoordinates = await getCoordinates(
                //     editingRow.country || "",
                //     editingRow.region || ""
                //   );
                //   updatedRow.coordinates = changedCoordinates;
                //   setEditingRow(updatedRow);
                // }
                setOpenUpdateConfirmDialog(true);
                return newRow;
              } else {
                setEditingRow(undefined);
                return newRow;
              }
            }}
            editMode="row"
            getRowId={(row) => row.uid}
            columns={columns}
            loading={loading}
            rows={rows}
            rowHeight={38}
            disableRowSelectionOnClick
            pinnedColumns={{
              left: [
                GRID_CHECKBOX_SELECTION_COL_DEF.field,
                "actions",
                "uid",
                "companyUid",
                "sampleNumber",
              ],
            }}
            pinnedRows={{
              top: pinnedRows,
            }}
            pagination
            paginationMode="server"
            paginationModel={{
              page: paginationParams.page || 0,
              pageSize: paginationParams.pageSize || 25,
            }}
            pageSizeOptions={
              paginationParams && paginationParams.rowsPerPageOptions
                ? paginationParams.rowsPerPageOptions
                : DefaultRowsPerPageOptions
            }
            onPaginationModelChange={(changedModel) => {
              const newPaginationParams = { ...paginationParams };
              newPaginationParams.page = changedModel.page;
              newPaginationParams.pageSize = changedModel.pageSize;
              fetchData(newPaginationParams);
              setPaginationParams(newPaginationParams);
            }}
            rowCount={totalCount}
            slots={{
              toolbar: CustomToolbar,
              detailPanelExpandIcon: () => {
                return (
                  <Tooltip title="See archived records" placement="top" arrow>
                    <ArchiveIcon />
                  </Tooltip>
                );
              },
              detailPanelCollapseIcon: () => <ExpandLessIcon />,
            }}
            checkboxSelection
            rowSelectionModel={selectedUids}
            onRowSelectionModelChange={(changed) => setSelectedUids(changed)}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            getDetailPanelHeight={({ row }) => "auto"}
            getDetailPanelContent={({ row }) => {
              return (
                <SampleRecordDetailPanel row={row as ISampleRecordPopulated} />
              );
            }}
          />
        </Box>
        {choroplethMaps?.length || choroplethMapAddingFieldName ? (
          <Box
            sx={{
              margin: "auto",
              border: "solid 1px #ddd",
              borderRadius: "7px",
              mt: 3,
              p: 2,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
              <Tabs
                value={mapRegionMode}
                onChange={(e, val) => setMapRegionMode(val)}
                aria-label="basic tabs example"
              >
                <Tab key={`tab-region`} label="Input Region" value={"region"} />
                <Tab
                  key={`tab-dietRegion`}
                  label="Diet Region"
                  value={"dietRegion"}
                />
              </Tabs>
            </Box>
            <Box sx={{ display: "flex", mb: 1 }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {choroplethMaps.map((choroplethMap) => (
                  <Chip
                    key={choroplethMap.field}
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>{choroplethMap.field}</div>
                      </div>
                    }
                    onDelete={(e) =>
                      setChoroplethMaps(
                        choroplethMaps.filter(
                          (h) => h.field != choroplethMap.field
                        )
                      )
                    }
                    sx={{ mr: "4px", my: "2px" }}
                  />
                ))}
                <Chip
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CloseIcon sx={{ mr: "4px" }} />
                      Remove All
                    </div>
                  }
                  sx={{ cursor: "pointer", mr: 1 }}
                  onClick={() => setChoroplethMaps([])}
                />
                <Box
                  sx={{
                    display: "inline-flex",
                    minWidth: "200px",
                    alignItems: "center",
                    "& .MuiInputBase-root": {
                      py: "2px !important",
                      borderRadius: "100px",
                    },
                  }}
                >
                  <Autocomplete
                    key={`${choroplethMaps.length}-add-field`}
                    value={""}
                    onChange={(e, val) => {
                      addFieldToChoroplethMap({
                        fieldName: val || "",
                        prefix: "chemicals",
                        onlySelectedRows: choroplethMaps?.[0]?.onlySelectedRows,
                      });
                    }}
                    getOptionDisabled={(option) =>
                      choroplethMaps?.map((h) => h.field)?.includes(option)
                    }
                    options={ChemicalFields.map((f) => f.fieldName)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Add Field"
                        size="small"
                        sx={{
                          "& input": { padding: "5px 10px 5px 0px" },
                          mr: 1,
                          my: "4px",
                          minWidth: "200px",
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <div>
                <IconButton
                  onClick={() => setCollapseHeatmaps(!collapseHeatmaps)}
                  sx={
                    !collapseHeatmaps ? { mt: "-12px", mr: "-12px" } : undefined
                  }
                >
                  {!collapseHeatmaps ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ display: collapseHeatmaps ? "none" : "flex" }}
            >
              {choroplethMaps.map((choroplethMap) => (
                <Grid item xs={12} sm={12} key={choroplethMap.field}>
                  {choroplethMap.field ? (
                    <ChemicalChoroplethMap
                      filterModel={choroplethMap.filterModel}
                      selectedSamples={choroplethMap.selectedSamples}
                      allAdditiveAlgorithms={allAdditiveAlgorithms}
                      key={`choropleth-map-${choroplethMap.field}-${mapRegionMode}`}
                      regions={
                        mapRegionMode == "region"
                          ? choroplethMap.regions
                          : choroplethMap.dietRegions || []
                      }
                      chemicalFieldName={choroplethMap.field}
                      center={[38.27312, -98.5821872]}
                      zoom={mapRegionMode == "region" ? 2 : 4}
                      onClose={() =>
                        setChoroplethMaps(
                          choroplethMaps.filter(
                            (h) => h.field != choroplethMap.field
                          )
                        )
                      }
                    />
                  ) : null}
                </Grid>
              ))}
              {choroplethMapAddingFieldName ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        ) : null}

        {distributions?.length ? (
          <Box
            sx={{
              margin: "auto",
              border: "solid 1px #ddd",
              borderRadius: "7px",
              mt: 3,
              p: 2,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {distributions.map((distribution) => (
                  <Chip
                    key={distribution.field}
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>{distribution.field}</div>
                      </div>
                    }
                    onDelete={(e) =>
                      setDistributions(
                        distributions.filter(
                          (d) => d.field != distribution.field
                        )
                      )
                    }
                    sx={{ mr: "4px", my: "2px" }}
                  />
                ))}
                <Chip
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CloseIcon sx={{ mr: "4px" }} />
                      Remove All
                    </div>
                  }
                  sx={{ cursor: "pointer", mr: 1 }}
                  onClick={() => setDistributions([])}
                />
                <Box
                  sx={{
                    display: "inline-flex",
                    minWidth: "200px",
                    alignItems: "center",
                    "& .MuiInputBase-root": {
                      py: "2px !important",
                      borderRadius: "100px",
                    },
                  }}
                >
                  <Autocomplete
                    key={`${distributions.length}-add-field`}
                    value={""}
                    onChange={(e, val) => {
                      addFieldToDistributions({
                        fieldName: val || "",
                        prefix: "chemicals",
                        onlySelectedRows: distributions?.[0]?.isSelectedRows,
                      });
                    }}
                    getOptionDisabled={(option) =>
                      distributions?.map((d) => d.field)?.includes(option)
                    }
                    options={ChemicalFields.map((f) => f.fieldName)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Add Field"
                        size="small"
                        sx={{
                          "& input": { padding: "5px 10px 5px 0px" },
                          mr: 1,
                          my: "4px",
                          minWidth: "200px",
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <div>
                <IconButton
                  onClick={() =>
                    setCollapseDistributions(!collapseDistributions)
                  }
                  sx={
                    !collapseDistributions
                      ? { mt: "-12px", mr: "-12px" }
                      : undefined
                  }
                >
                  {!collapseDistributions ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ display: collapseDistributions ? "none" : "flex" }}
            >
              {distributions.map((distribution) => (
                <Grid item xs={12} sm={6} key={distribution.field}>
                  <Box
                    sx={{
                      margin: "auto",
                      border: "solid 1px #ddd",
                      borderRadius: "7px",
                      mt: 3,
                      pt: 1,
                      pr: 1,
                      position: "relative",
                      "&:hover .closeButton": {
                        opacity: 1,
                      },
                    }}
                  >
                    <DistributionChart
                      onClose={() =>
                        setDistributions(
                          distributions.filter(
                            (d) => d.field != distribution.field
                          )
                        )
                      }
                      fieldName={distribution.field}
                      numbers={distribution.numbers}
                    />
                    <IconButton
                      onClick={() =>
                        setDistributions(
                          distributions.filter(
                            (d) => d.field != distribution.field
                          )
                        )
                      }
                      className="closeButton"
                      size="small"
                      sx={{
                        transition: "0.2s",
                        opacity: 0,
                        position: "absolute",
                        top: "-15px",
                        left: "-15px",
                        background: "#ddd",
                        width: "25px",
                        height: "25px",
                        "&:hover": {
                          backgroundColor: "error.main",
                          color: "white",
                          opacity: 1,
                        },
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : null}
        {spectraData?.length || loadingSpectraData ? (
          <Box
            sx={{
              margin: "auto",
              border: "solid 1px #ddd",
              borderRadius: "7px",
              mt: 3,
            }}
          >
            {loadingSpectraData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <SpectraChart
                key={spectraData
                  ?.map((sDataItem) => sDataItem.uid.toString())
                  .join("-")}
                spectraDataItems={spectraData}
                onRemoveItem={(uid) =>
                  setSpectraData(spectraData.filter((sd) => sd.uid != uid))
                }
                onRemoveAll={() => setSpectraData([])}
              />
            )}
          </Box>
        ) : null}
        {openExportDialog && (
          <ExportDatasetFormDialog
            recordCount={
              selectedUids?.length ? selectedUids.length : totalCount
            }
            onComplete={(fileName, description, groupName, groupDescription) =>
              handleExportData(
                fileName,
                description,
                groupName,
                groupDescription
              )
            }
            onClose={() => setOpenExportDialog(false)}
          />
        )}
        {openBatchReportDialog && (
          <BatchReportDialog
            samples={chemDataOfAllRows}
            onComplete={(samplesToBatchReport) => {
              setOpenBatchReportDialog(false);
              executeBatchReport(samplesToBatchReport);
            }}
            onClose={() => setOpenBatchReportDialog(false)}
          />
        )}
        {openGroupCreationDialog && (
          <CreateNewSampleGroupDialog
            recordCount={
              selectedUids?.length ? selectedUids.length : totalCount
            }
            onComplete={(groupName, description) =>
              saveSearchResultsAsGroup(groupName, description)
            }
            onClose={() => setOpenGroupCreationDialog(false)}
          />
        )}
        {openDeleteConfirmationDialog && (
          <ConfirmDialog
            title={<>Delete Sample Records</>}
            content={
              <>{`Are you sure you want to delete ${selectedUids.length} sample records?`}</>
            }
            onCancel={() => setOpenDeleteConfirmationDialog(false)}
            onConfirm={() => {
              handleDeleteSampleRecords();
              setOpenDeleteConfirmationDialog(false);
            }}
          />
        )}
        {openGasAnalysisReportDialog && targetSampleRecord ? (
          <GasAnalysisReportDialog
            sampleRecord={targetSampleRecord}
            open
            onClose={() => setOpenGasAnalysisReportDialog(false)}
          />
        ) : null}
        {openGasAnalysisViewDialog && targetGasAnalysis ? (
          <GasAnalysisFormDialog
            open
            onClose={() => setOpenGasAnalysisViewDialog(false)}
            gasAnalysis={targetGasAnalysis}
            onUpdated={() => {
              fetchData();
              setOpenGasAnalysisViewDialog(false);
            }}
          />
        ) : null}
        {openUpdateConfirmDialog && editingRow ? (
          <UpdateSampleRecordConfirmDialog
            open={openUpdateConfirmDialog}
            onClose={() => {
              setOpenUpdateConfirmDialog(false);
              setEditingRow(undefined);
            }}
            originalSampleRecord={rows.find((r) => r.uid == editingRow?.uid)}
            updatedSampleRecord={editingRow}
            onConfirm={(reason, reasonText) => {
              handleUpdateRow(
                editingRow.uid,
                editingRow,
                reason == SampleRecordManualUpdateReason.Other
                  ? reasonText
                  : reason
              );
              setOpenUpdateConfirmDialog(false);
            }}
          />
        ) : null}
        {openSampleBagCoverPrintDialog &&
        (targetSampleRecord || selectedUids.length) ? (
          <SampleBagCoverPrintDialog
            open
            samples={
              selectedUids?.length
                ? rows.filter((r) => r.uid && selectedUids.includes(r.uid))
                : targetSampleRecord
                ? [targetSampleRecord]
                : []
            }
            onClose={() => setOpenSampleBagCoverPrintDialog(false)}
          />
        ) : null}
      </WhitePanel>
      <Box
        style={{
          width: "900px",
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
          padding: "16px",
        }}
        ref={batchReportAllowed ? reportPdfRef : undefined}
      ></Box>
    </div>
  );
}
