/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useStorageContext";
import { actionTypes } from "../../../types/state";
import UserFormDialog from "../../pages/users/UserFormDialog";
import { IUser } from "../../../types/user";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ActionButtonGroup from "../../pages/home/ActionButtonGroup";
import { Button, Tooltip } from "@mui/material";
import companyService from "../../../service/company.service";
import sampleRecordService from "../../../service/sampleRecord.service";
import { ICompany } from "../../../types/company";
import userService from "../../../service/user.service";
import { NotificationIconButton } from "./NotificationIconButton";
import QuickActionsDialog from "../QuickActions/QuickActionsDialog";

const Navbar = styled(AppBar, {
  shouldForwardProp: (prop: string) => prop !== "openSideMenu",
})<{
  openSideMenu?: boolean;
  drawerwidth?: number;
}>(({ theme, openSideMenu, drawerwidth }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `-${drawerWidth}px`,
  marginLeft: "0px",
  width: "100%",
  ...(openSideMenu && {
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: 0,
    marginLeft: `${drawerwidth}px`,
  }),
}));

export default function NavBar({ drawerWidth }: { drawerWidth?: number }) {
  const { dispatch, state } = useAppContext();
  const navigation = useNavigate();
  const { openSideDrawer, loggedInUserInfo, myCompanyProfile } = state;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [openUserFormDialog, setOpenUserFormDialog] = React.useState(false);
  const [lastChemSsid, setLastChemSsid] = React.useState<number>(0);
  const [lastSpecSsid, setLastSpecSsid] = React.useState<number>(0);
  const [loadingLastSsids, setLoadingLastSsids] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState<
    ICompany | undefined
  >();
  const [openQuickActionsDialog, setOpenQuickActionsDialog] =
    React.useState(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const fetchLastSSIDs = async () => {
    const companyId = selectedCompany?._id || selectedCompany?._id;
    if (!companyId) {
      setLastChemSsid(0);
      setLastSpecSsid(0);
      return;
    }
    setLoadingLastSsids(true);
    const result = await sampleRecordService.getLastSSIDs(companyId);
    setLastChemSsid(result.chem || 0);
    setLastSpecSsid(result.spec || 0);
    setLoadingLastSsids(false);
  };

  const fetchMyCompanyProfile = async () => {
    if (!loggedInUserInfo?.company) return;
    const [myCompanyResult, myProfile, myClients] = await Promise.all([
      companyService.getMyCompany(),
      userService.getMyProfile(),
      companyService.getMyClients(),
    ]);
    dispatch!({
      type: actionTypes.setLoggedInUserInfo,
      value: { ...(loggedInUserInfo || {}), ...(myProfile || {}) },
    });
    setSelectedCompany(myCompanyResult.data);
    if (myCompanyResult.data?._id) {
      dispatch!({
        type: actionTypes.setMyCompanyProfile,
        value: myCompanyResult.data,
      });
    }
    dispatch!({ type: actionTypes.setMyClients, value: myClients.data });
  };

  React.useEffect(() => {
    fetchLastSSIDs();
  }, [selectedCompany, myCompanyProfile]);

  React.useEffect(() => {
    fetchMyCompanyProfile();
  }, []);

  return (
    <Navbar
      openSideMenu={openSideDrawer}
      drawerwidth={drawerWidth == undefined ? 270 : drawerWidth}
      position="fixed"
      elevation={1}
      sx={{
        bgcolor: "navbar.default",
        borderBottom: "solid 1px #e5e5e5",
        flex: "unset",
        zIndex: 1,
      }}
    >
      <Box>
        <Box sx={{ px: { xs: 0, sm: 2 } }}>
          <Toolbar disableGutters sx={{ minHeight: { xl: "72px" } }}>
            <IconButton
              onClick={() =>
                dispatch!({
                  type: actionTypes.setOpenSideDrawer,
                  value: !openSideDrawer,
                })
              }
              className="sm:mr-3"
            >
              <MenuIcon
                sx={{
                  color: "button.black",
                  fontSize: { xs: "30px", lg: "35px" },
                }}
              />
            </IconButton>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: { xs: 1, md: 2 },
                maxWidth: {
                  xs: "45px",
                  sm: "unset",
                },
                overflow: "hidden",
              }}
            >
              <img
                src="/light-logo.svg"
                alt="Orugen Datalake Logo"
                style={{ maxHeight: "50px" }}
              />
            </Box>

            <Box
              sx={{
                px: { xs: 0, sm: 1 },
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "flex-end",
                display: { xs: "flex" },
              }}
            >
              {/* <CompanyAutoComplete
                value={selectedCompany}
                onChange={(selected) =>
                  setSelectedCompany(selected || undefined)
                }
              /> */}
              {/* <Box
                sx={{
                  color: "#888",
                  fontSize: "12px",
                  marginRight: "20px",
                  borderLeft: "solid 2px #dedede",
                  paddingLeft: "6px",
                  display: { xs: "none", sm: "block" },
                  ml: 1,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>Last Chem SSID: </div>
                  {loadingLastSsids ? (
                    <CircularProgress size={12} sx={{ ml: 1 }} />
                  ) : (
                    <Box sx={{ color: "warning.main", ml: "5px" }}>
                      {lastChemSsid || "?"}
                    </Box>
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>Last Spectra SSID: </div>
                  {loadingLastSsids ? (
                    <CircularProgress size={12} sx={{ ml: 1 }} />
                  ) : (
                    <Box sx={{ color: "warning.main", ml: "5px" }}>
                      {lastSpecSsid || "?"}
                    </Box>
                  )}
                </div>
              </Box> */}
              <div>
                <Button
                  onClick={() => setOpenQuickActionsDialog(true)}
                  color="warning"
                  sx={{
                    display: { xs: "none", md: "flex" },
                    padding: { xs: "4px 4px 4px 12px" },
                    minHeight: "32px",
                    mr: 1,
                  }}
                  variant="outlined"
                  startIcon={<RocketLaunchIcon />}
                >
                  <Box sx={{ display: { xs: "none", md: "block" }, mr: "4px" }}>
                    Ledger
                  </Box>
                </Button>
                <Tooltip title="Ledger (Quick Actions)">
                  <IconButton
                    onClick={() => setOpenQuickActionsDialog(true)}
                    color="warning"
                    sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    <RocketLaunchIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <ActionButtonGroup
                lastChemSsid={lastChemSsid}
                lastSpecSsid={lastSpecSsid}
              />
            </Box>
            <Box sx={{ flexGrow: 0, ml: 1 }}>
              <NotificationIconButton />
              <IconButton onClick={handleOpenUserMenu} sx={{ mr: "4px" }}>
                <PermIdentityIcon
                  sx={
                    {
                      // color: "button.black",
                      // fontSize: { xs: "30px", lg: "35px" },
                    }
                  }
                />
              </IconButton>

              <Popover
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box sx={{ minWidth: 250 }}>
                  {/* <Box
                    sx={{
                      color: "#888",
                      background: "#f1f1f1",
                      fontSize: "12px",
                      paddingLeft: "6px",
                      p: 2,
                      pb: 1,
                      display: { xs: "block", sm: "none" },
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>Last Chem SSID: </div>
                      {loadingLastSsids ? (
                        <CircularProgress size={12} sx={{ ml: 1 }} />
                      ) : (
                        <Box sx={{ color: "warning.main", ml: "5px" }}>
                          {lastChemSsid || "N/A"}
                        </Box>
                      )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>Last Spectra SSID: </div>
                      {loadingLastSsids ? (
                        <CircularProgress size={12} sx={{ ml: 1 }} />
                      ) : (
                        <Box sx={{ color: "warning.main", ml: "5px" }}>
                          {lastSpecSsid || "N/A"}
                        </Box>
                      )}
                    </div>
                  </Box> */}
                  <Box p={2} sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        background: "#f3f3f3",
                        borderRadius: "100px",
                        backgroundImage: `url(${
                          loggedInUserInfo?.avatar || ""
                        })`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                      }}
                    />
                    <Box sx={{ flex: 1, ml: 1 }}>
                      <Typography variant="h6">
                        {loggedInUserInfo?.fullname}
                      </Typography>
                      <Typography variant="body2">
                        {loggedInUserInfo?.email}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          setOpenUserFormDialog(true);
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText>Edit Profile</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          dispatch!({
                            type: actionTypes.setLoggedInUserInfo,
                            value: undefined,
                          });
                          navigation("/");
                        }}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Log out</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Toolbar>
        </Box>
      </Box>
      {openUserFormDialog && (
        <UserFormDialog
          open
          user={{ ...loggedInUserInfo, company: myCompanyProfile } as IUser}
          onClose={() => setOpenUserFormDialog(false)}
          onUpdated={(updatedUser) => {
            dispatch!({
              type: actionTypes.setLoggedInUserInfo,
              value: { ...(loggedInUserInfo || {}), ...updatedUser },
            });
            setOpenUserFormDialog(false);
          }}
        />
      )}
      {openQuickActionsDialog && (
        <QuickActionsDialog onClose={() => setOpenQuickActionsDialog(false)} />
      )}
    </Navbar>
  );
}
