/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import { Stack, TextField } from "@mui/material";
import SampleIdSingleAutocomplete from "../Common/SampleIdSingleutocomplete";
import { ISampleRecordPopulated } from "../../../types/sample";
import ProgressButton from "../Common/ProgressButton";
import sampleRecordService from "../../../service/sampleRecord.service";
import { showSnackbar } from "../../../service/snackbar.service";

export default function DrySampleForm() {
  const [selectedSample, setSelectedSample] =
    React.useState<ISampleRecordPopulated>();
  const [drySampleWeight, setDrySampleWeight] = React.useState<number>();
  const [saving, setSaving] = React.useState(false);

  const handleSubmit = async () => {
    if (!selectedSample) return;
    if (!drySampleWeight) return;
    try {
      setSaving(true);
      await sampleRecordService.updateRecord(
        selectedSample.uid,
        {
          drySampleWeight,
        },
        [
          {
            fieldName: "drySampleWeight",
            oldValue: selectedSample.drySampleWeight,
            newValue: drySampleWeight,
          },
        ],
        "Dry Sample Weight Input"
      );
      setSaving(false);
      showSnackbar(
        "success",
        "Success",
        "Dry Sample Weight saved successfully"
      );
      setDrySampleWeight(undefined);
      setSelectedSample(undefined);
    } catch (e) {
      setSaving(false);
      showSnackbar("danger", "Error", "Failed to save dry sample weight");
    }
  };

  React.useEffect(() => {
    if (selectedSample) {
      setDrySampleWeight(selectedSample.drySampleWeight);
    }
  }, [selectedSample]);
  return (
    <Stack spacing={3}>
      <SampleIdSingleAutocomplete
        includeChemicals
        fullWidth
        value={selectedSample}
        onSelectValue={setSelectedSample}
        label="Find Sample By Sample Number"
      />
      <TextField
        type="number"
        size="small"
        label="Dry Sample Weight (g)"
        value={drySampleWeight || ""}
        onChange={(e) =>
          setDrySampleWeight(
            e.target.value != "" && e.target.value != undefined
              ? Number(e.target.value)
              : undefined
          )
        }
      />
      <ProgressButton
        inProgress={saving}
        disabled={!selectedSample || !drySampleWeight}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        title="Save"
      />
    </Stack>
  );
}
