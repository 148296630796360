/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  IResearchLabDevice,
  IResearchSchedule,
  ResearchLabDeviceType,
} from "../../../types/researchLabSchedule";
import researchLabScheduleService from "../../../service/researchLabSchedule.service";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddIconButton from "../Common/AddIconButton";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import * as XLSX from "xlsx";
import {
  Box,
  IconButton,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
  Button,
  Stack,
} from "@mui/material";
import { fDashDate } from "../../../helpers/formatTime";
import Chip from "@mui/material/Chip";
import ConfirmDialog from "../Common/ConfirmDialog";
import { sortBy } from "lodash";
import { ICow } from "../../../types/intake/cow";
import { IDiet } from "../../../types/intake/diet";
import SchedulesGanttViewDialog from "./SchedulesGanttViewDialog";
import AttachedFileItem from "../Common/AttachedFileItem";
import ResearchScheduleFormDialog from "./ResearchScheduleFormDialog";

export default function ResearchScheduleManagement() {
  const [isLoading, setIsLoading] = useState(false);
  const [popoverKey, setPopoverKey] = useState<number>(0);
  const [schedules, setSchedules] = useState<IResearchSchedule[]>([]);
  const [editingSchedule, setEditingSchedule] = useState<IResearchSchedule>();
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [openGanttViewDialog, setOpenGanttViewDialog] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const columns = [
    { field: "name", headerName: "Name" },
    {
      field: "documents",
      headerName: "",
      width: 40,
      renderCell: (params: any) => {
        const documents = params.row.documents || [];
        if (documents.length) {
          return (
            <GeneralPopoverWrapper
              triggerElement={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DescriptionIcon
                    sx={{ mr: "3px", opacity: 0.7, fontSize: "22px" }}
                  />
                  {documents.length}
                </Box>
              }
              popoverContent={
                <Box sx={{ minWidth: "250px", p: 1 }}>
                  {documents.map((d: string) => (
                    <AttachedFileItem key={d} url={d} />
                  ))}
                </Box>
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      valueGetter: (params: any) =>
        params.value ? fDashDate(params.value) : "",
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      valueGetter: (params: any) =>
        params.value ? fDashDate(params.value) : "",
    },
    {
      field: "habituationDays",
      headerName: "Habituation",
      width: 70,
      renderCell: (params: any) => {
        return `${params.row.habituationDays || 0} days`;
      },
    },
    {
      field: "participatedDevices",
      headerName: "Devices",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <GeneralPopoverWrapper
            triggerElement={
              <Chip
                sx={{ cursor: "pointer" }}
                label={`${params.row.participatedDevices?.length || 0} devices`}
              />
            }
            popoverContent={
              <Box p={2}>
                <Table size="small" sx={{ border: "solid 1px #ddd" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Type #</TableCell>
                      <TableCell>Device ID</TableCell>
                      <TableCell>Sample Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {params.row.participatedDevices?.map(
                      (device: IResearchLabDevice) => (
                        <TableRow key={device._id}>
                          <TableCell>
                            {
                              Object.keys(ResearchLabDeviceType)[
                                Object.values(ResearchLabDeviceType).findIndex(
                                  (v) => v == device.type
                                )
                              ]
                            }
                          </TableCell>
                          <TableCell>{device.typeIndex}</TableCell>
                          <TableCell>{device.deviceId}</TableCell>
                          <TableCell>
                            {params.row.participatedSamples?.find(
                              (s: any) => s.deviceId == device._id
                            )?.sample?.sampleNumber || ""}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            }
          />
        );
      },
    },
    {
      field: "participatedAnimals",
      headerName: "Animals",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <GeneralPopoverWrapper
            triggerElement={
              <Chip
                sx={{ cursor: "pointer" }}
                label={`${params.row.participatedAnimals?.length || 0} cows`}
              />
            }
            popoverContent={
              <Box p={2}>
                <Table size="small" sx={{ border: "solid 1px #ddd" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cow</TableCell>
                      <TableCell>Breed</TableCell>
                      <TableCell>Diet</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {params.row.participatedAnimals?.map(
                      (animal: { cow: ICow; diet: IDiet }) => (
                        <TableRow key={animal.cow._id}>
                          <TableCell>{animal.cow?.name}</TableCell>
                          <TableCell>{animal.cow?.breed?.name}</TableCell>
                          <TableCell>{animal.diet?.name}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            }
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              size="small"
              onClick={() => {
                setEditingSchedule(params.row);
                setOpenFormDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditingSchedule(params.row);
                setDeleteConfirmDialog(true);
              }}
              size="small"
              sx={{ ml: 1 }}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const getSchedules = async () => {
    setIsLoading(true);
    const devices = await researchLabScheduleService.getSchedules();
    setSchedules(sortBy(devices, "startDate"));
    setIsLoading(false);
  };

  const handleDeleteSchedule = async (id: string) => {
    setIsLoading(true);
    await researchLabScheduleService.deleteSchedule(id);
    setIsLoading(false);
    getSchedules();
  };

  const handleExportSchedules = async () => {
    const itemsToExport = selectedIds.length
      ? schedules.filter((s) => selectedIds.includes(s._id || ""))
      : schedules;
    const exportData: { [key: number]: string }[] = [
      {
        1: "Name",
        2: "Start Date",
        3: "End Date",
        4: "Habituation Days",
        5: "Device",
        6: "Sample #",
        7: "Animal",
        8: "Diet",
      },
    ];
    itemsToExport.map((item) => {
      exportData.push({
        1: item.name,
        2: fDashDate(item.startDate),
        3: fDashDate(item.endDate),
        4: item.habituationDays ? item.habituationDays?.toString() : "",
        5: item.participatedDevices?.[0]
          ? `${
              Object.keys(ResearchLabDeviceType)[
                Object.values(ResearchLabDeviceType).findIndex(
                  (v) => v == item.participatedDevices?.[0]?.type
                )
              ]
            } ${item.participatedDevices?.[0]?.typeIndex || ""}`
          : "",
        6:
          item.participatedSamples?.find(
            (s) => s.deviceId == item.participatedDevices?.[0]?._id
          )?.sample?.sampleNumber || "",
        7: item.participatedAnimals?.[0]
          ? `${item.participatedAnimals?.[0]?.cow?.name}`
          : "",
        8: item.participatedAnimals?.[0]?.diet?.name || "",
      });
      const maxLength = Math.max(
        item.participatedDevices?.length || 0,
        item.participatedAnimals?.length || 0
      );
      if (maxLength < 2) {
        return;
      }
      for (let i = 1; i < maxLength; i++) {
        exportData.push({
          1: "",
          2: "",
          3: "",
          4: "",
          5: item.participatedDevices?.[i]
            ? `${
                Object.keys(ResearchLabDeviceType)[
                  Object.values(ResearchLabDeviceType).findIndex(
                    (v) => v == item.participatedDevices?.[i]?.type
                  )
                ]
              } ${item.participatedDevices?.[i]?.typeIndex || ""}`
            : "",
          6:
            item.participatedSamples?.find(
              (s) => s.deviceId == item.participatedDevices?.[i]?._id
            )?.sample?.sampleNumber || "",
          7: item.participatedAnimals?.[i]
            ? `${item.participatedAnimals?.[i]?.cow?.name}`
            : "",
          8: item.participatedAnimals?.[i]?.diet?.name || "",
        });
      }
      exportData.push({
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
      });
    });
    const ws = XLSX.utils.json_to_sheet(exportData, { skipHeader: true });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `R&D_Trials_${fDashDate(new Date())}.xlsx`);
  };

  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <AddIconButton
          onClick={() => {
            setEditingSchedule(undefined);
            setOpenFormDialog(true);
          }}
        />
        <h2
          style={{
            margin: "0px 0px 0px 10px",
            display: "flex",
            alignItems: "center",
            opacity: 0.8,
          }}
        >
          R&D Trials
          <PendingActionsIcon sx={{ marginLeft: "8px" }} />
        </h2>
        <div style={{ flex: 1 }} />
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              handleExportSchedules();
            }}
            startIcon={<FileDownloadIcon />}
          >
            {`Export ${
              selectedIds.length == schedules.length || selectedIds.length == 0
                ? "All"
                : `${selectedIds.length} item${
                    selectedIds.length > 1 ? "s" : ""
                  }`
            }`}
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => setOpenGanttViewDialog(true)}
            startIcon={<CalendarMonthIcon />}
          >
            Gantt View
          </Button>
        </Stack>
      </div>

      <div>
        <DataGridPro
          checkboxSelection
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedIds(newSelectionModel as string[]);
          }}
          rowSelectionModel={selectedIds}
          autoHeight
          rows={schedules}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
        />
      </div>
      {openFormDialog && (
        <ResearchScheduleFormDialog
          onClose={() => setOpenFormDialog(false)}
          schedules={schedules}
          editingSchedule={editingSchedule}
          onUpdated={(updated) => {
            getSchedules();
            setOpenFormDialog(false);
          }}
          onNewCreated={(created) => {
            getSchedules();
            setOpenFormDialog(false);
          }}
        />
      )}

      {deleteConfirmDialog && editingSchedule?._id && (
        <ConfirmDialog
          expanded
          title="Delete R&D Trial"
          content="Are you sure you want to delete this R&D Trial?"
          onConfirm={() => {
            handleDeleteSchedule(editingSchedule?._id || "");
            setDeleteConfirmDialog(false);
          }}
          onCancel={() => setDeleteConfirmDialog(false)}
        />
      )}
      {openGanttViewDialog && (
        <SchedulesGanttViewDialog
          schedules={schedules}
          open={openGanttViewDialog}
          onClose={() => setOpenGanttViewDialog(false)}
          onUpdated={(updated) => {
            setSchedules(
              schedules.map((s) => (s._id == updated._id ? updated : s))
            );
          }}
          onNewCreated={(created) => {
            setSchedules([...schedules, created]);
          }}
        />
      )}
    </div>
  );
}
