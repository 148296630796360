/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IResearchSchedule } from "../../../types/researchLabSchedule";
import Dialog from "@mui/material/Dialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Gantt,
  // Task,
  // EventOption,
  // StylingOption,
  // ViewMode,
  // DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import ResearchScheduleFormDialog from "./ResearchScheduleFormDialog";
import { fDashDate } from "../../../helpers/formatTime";
import { checkDateDisabled } from "./ResearchScheduleForm";
import { showSnackbar } from "../../../service/snackbar.service";
import researchLabScheduleService from "../../../service/researchLabSchedule.service";
import LightTooltip from "../Common/LightTooltip";
import ScheduleTooltipContent from "./ScheduleTooltipContent";
import Paper from "@mui/material/Paper";

const CustomDialog = styled(Dialog)(({}) => ({
  overflow: "visible",
  "& .MuiPaper-root": {
    overflow: "visible",
  },
}));

interface IGanttChartTask {
  start: Date;
  end: Date;
  name: string;
  progress: number;
  id: string;
  type: "task" | "milestone" | "project";
  styles?: any;
}

export default function SchedulesGanttViewDialog({
  schedules,
  open,
  onClose,
  onUpdated,
  onNewCreated,
}: {
  schedules: IResearchSchedule[];
  open: boolean;
  onClose: () => void;
  onUpdated?: (updated: IResearchSchedule) => void;
  onNewCreated?: (created: IResearchSchedule) => void;
}) {
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [editingSchedule, setEditingSchedule] = useState<IResearchSchedule>();
  const [updateProcessing, setUpdateProcessing] = useState(false);
  const [ganttChartKey, setGanttChartKey] = useState(0);

  const handleUpdateSchedule = async (updated: IResearchSchedule) => {
    setUpdateProcessing(true);
    await researchLabScheduleService.updateSchedule(updated);
    setUpdateProcessing(false);
    onUpdated?.(updated);
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => onClose()}
      maxWidth="xl"
      fullWidth
      sx={{ overflow: "visible" }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Schedules</Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          cursor: updateProcessing ? "progress" : undefined,
          overflow: "visible",
        }}
      >
        <Box
          sx={{
            background: "#eff2f5",
            "&>div:first-child>div:first-child>div:first-child": {
              background: "#e8edf1",
            },
          }}
        >
          <Gantt
            key={`schedule-gantt-chart-${ganttChartKey}`}
            ganttHeight={400}
            timeStep={(60 * 60 * 24 * 1000) / 4}
            tasks={schedules.map((schedule) => ({
              start: new Date(schedule.startDate),
              end: new Date(
                new Date(schedule.endDate).getTime() + 24 * 60 * 60 * 1000 - 1
              ),
              name: schedule.name,
              progress: 0,
              id: schedule._id || "",
              type: "task" as any,
              styles: {
                progressColor: "#1086e2",
                progressSelectedColor: "#1086e2",
                backgroundSelectedColor: "#1086e2",
              },
            }))}
            viewDate={new Date(new Date().getTime() - 23 * 60 * 60 * 1000)}
            viewMode={"Day" as any}
            barBackgroundColor="#1086e2"
            onDoubleClick={(task: IGanttChartTask) => {
              setEditingSchedule(schedules.find((s) => s._id == task.id));
              setOpenFormDialog(true);
            }}
            onDateChange={(task: IGanttChartTask) => {
              const matchedSchedule = schedules.find((s) => s._id == task.id);
              if (!matchedSchedule) return;
              const startDate = new Date(task.start);
              const endDate = new Date(task.end);
              const dates = [];
              for (
                let date = new Date(startDate);
                date <= endDate;
                date.setDate(date.getDate() + 1)
              ) {
                dates.push(new Date(date));
              }
              const disabledDates = dates.filter((d) =>
                checkDateDisabled(
                  d,
                  schedules.filter((s) => s._id != task.id),
                  matchedSchedule?.participatedAnimals || [],
                  matchedSchedule?.participatedDevices || [],
                  matchedSchedule?.habituationDays || undefined
                )
              );
              if (disabledDates?.length) {
                showSnackbar(
                  "danger",
                  "Schedule Conflict!",
                  `These dates have conflicts: ${disabledDates
                    .map((d) => fDashDate(d, undefined, true))
                    .join(", ")}`
                );
                setGanttChartKey(ganttChartKey + 1);
              } else {
                handleUpdateSchedule({
                  ...matchedSchedule,
                  startDate: startDate,
                  endDate: new Date(
                    new Date(endDate).getTime() - 24 * 60 * 60 * 1000 + 1
                  ),
                } as IResearchSchedule);
              }
            }}
            TooltipContent={({ task }) => {
              const matchedSchedule = schedules.find((s) => s._id == task.id);
              if (!matchedSchedule) return null;
              return (
                <Paper elevation={6} sx={{ background: "#fff" }}>
                  <ScheduleTooltipContent
                    schedule={matchedSchedule}
                    maxWidth={300}
                  />
                </Paper>
              );
            }}
            listCellWidth="150"
            TaskListTable={({ tasks, rowHeight }) => {
              return (
                <div style={{ background: "#fff" }}>
                  {tasks.map((task) => {
                    const matchedSchedule = schedules.find(
                      (s) => s._id == task.id
                    );
                    if (!matchedSchedule) return null;
                    return (
                      <LightTooltip
                        placement="right"
                        key={`task-list-item-${task.id}`}
                        title={
                          <ScheduleTooltipContent schedule={matchedSchedule} />
                        }
                      >
                        <Box
                          sx={{
                            height: `${rowHeight - 1}px`,
                            display: "flex",
                            alignItems: "center",
                            border: "solid 1px #e0e5e8",
                            borderTop: "none",
                            padding: "0px 12px",
                            position: "relative",
                            "&:hover .gantt-task-list-item-date": {
                              height: "13px !important",
                            },
                          }}
                        >
                          <Box>
                            {task.name}
                            <div
                              className="gantt-task-list-item-date"
                              style={{
                                height: "0px",
                                overflow: "hidden",
                                fontSize: "12px",
                                opacity: 0.5,
                                marginTop: "5px",
                                transition: "0.2s",
                              }}
                            >{`${fDashDate(
                              task.start,
                              undefined,
                              true
                            )} ~ ${fDashDate(task.end, undefined, true)}`}</div>
                          </Box>
                          <IconButton
                            size="small"
                            sx={{
                              position: "absolute",
                              right: "2px",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                            onClick={() => {
                              setEditingSchedule(matchedSchedule);
                              setOpenFormDialog(true);
                            }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </LightTooltip>
                    );
                  })}
                </div>
              );
            }}
            TaskListHeader={({
              headerHeight,
              rowWidth,
              fontFamily,
              fontSize,
            }) => {
              return (
                <div
                  style={{
                    background: "#fff",
                    height: `${headerHeight - 1}px`,
                    width: `${rowWidth}px`,
                    fontFamily: fontFamily,
                    fontSize: fontSize,
                    display: "flex",
                    alignItems: "center",
                    border: "solid 1px #e0e5e8",
                    padding: "0px 12px",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "500",
                      fontSize: "20px",
                      color: "primary.main",
                    }}
                  ></Box>
                </div>
              );
            }}
          />
        </Box>
      </DialogContent>

      {openFormDialog && (
        <ResearchScheduleFormDialog
          onClose={() => setOpenFormDialog(false)}
          schedules={schedules}
          editingSchedule={editingSchedule}
          onUpdated={(updated) => {
            onUpdated?.(updated);
          }}
          onNewCreated={(created) => {
            onNewCreated?.(created);
          }}
        />
      )}
    </CustomDialog>
  );
}
