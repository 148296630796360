/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import {
  IResearchLabDevice,
  IResearchSchedule,
  ResearchLabDeviceType,
} from "../../../types/researchLabSchedule";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { MultiInputDateRangeField } from "@mui/x-date-pickers-pro/MultiInputDateRangeField";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import { cloneDeep, uniqBy } from "lodash";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DietAutocomplete from "../Intake/DietAutocomplete";
import researchLabScheduleService from "../../../service/researchLabSchedule.service";
import ProgressButton from "../Common/ProgressButton";
import { twoTimePeriodsOverlap } from "../../../helpers/utils";
import animalService from "../../../service/intakeServices/animal.service";
import { ICow } from "../../../types/intake/cow";
import { fDashDate } from "../../../helpers/formatTime";
import dietService from "../../../service/intakeServices/diet.service";
import { IDiet } from "../../../types/intake/diet";
import SampleIdSingleAutocomplete from "../Common/SampleIdSingleutocomplete";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers-pro";
import LightTooltip from "../Common/LightTooltip";
import AnyAssetFileUploader from "../Common/AnyAssetFileUploader";
import AttachedFileItem from "../Common/AttachedFileItem";

const getActualStartDate = (
  startDate: Date | undefined,
  habituationDays: number | undefined
) => {
  if (!habituationDays) return startDate;
  if (!startDate) return undefined;
  return dayjs(startDate).add(habituationDays, "day").toDate();
};

export const checkDateDisabled = (
  eachDate: Date,
  otherFutureSchedules: IResearchSchedule[],
  participatedAnimals: {
    cow: ICow;
    diet?: IDiet;
  }[],
  participatedDevices: IResearchLabDevice[],
  currentScheduleHabituationDays: number | undefined
) => {
  const isDiabled =
    participatedAnimals?.some(
      (a) =>
        otherFutureSchedules?.filter(
          (s) =>
            s.habituationDays &&
            s.participatedAnimals?.some((c) => c.cow?._id == a.cow?._id) &&
            new Date(s.startDate) <= eachDate &&
            new Date(s.endDate) >= eachDate
        )?.length ||
        (otherFutureSchedules?.filter(
          (s) =>
            !s.habituationDays &&
            s.participatedAnimals?.some((c) => c.cow?._id == a.cow?._id) &&
            new Date(s.startDate) <= eachDate &&
            new Date(s.endDate) >= eachDate
        )?.length || 0) > (currentScheduleHabituationDays ? 0 : 1)
    ) ||
    participatedDevices?.some((d) =>
      otherFutureSchedules?.some(
        (s) =>
          s.participatedDevices?.some((c) => c._id == d._id) &&
          new Date(
            getActualStartDate(s.startDate, s.habituationDays) || s.endDate
          ) <= eachDate &&
          new Date(s.endDate) >= eachDate
      )
    ) ||
    false;
  return isDiabled;
};

const checkDateScheduledButNotDisabled = (
  eachDate: Date,
  otherFutureSchedules: IResearchSchedule[],
  participatedAnimals: {
    cow: ICow;
    diet?: IDiet;
  }[],
  participatedDevices: IResearchLabDevice[],
  currentScheduleHabituationDays: number | undefined
) => {
  const isAnimalScheduled = participatedAnimals?.some(
    (a) =>
      otherFutureSchedules?.filter(
        (s) =>
          s.participatedAnimals?.some((c) => c.cow?._id == a.cow?._id) &&
          new Date(s.startDate) <= eachDate &&
          new Date(s.endDate) >= eachDate
      )?.length
  );

  return (
    isAnimalScheduled &&
    !checkDateDisabled(
      eachDate,
      otherFutureSchedules,
      participatedAnimals,
      participatedDevices,
      currentScheduleHabituationDays
    )
  );
};

function CustomizedDayElement(
  props: PickersDayProps<Dayjs> & {
    startDate?: Date;
    endDate?: Date;
    otherFutureSchedules?: IResearchSchedule[];
    participatedAnimals?: {
      cow: ICow;
      diet?: IDiet;
    }[];
    participatedDevices?: IResearchLabDevice[];
    currentScheduleHabituationDays?: number;
  }
) {
  const {
    day,
    startDate,
    endDate,
    outsideCurrentMonth,
    otherFutureSchedules,
    participatedAnimals,
    participatedDevices,
    currentScheduleHabituationDays,
    ...other
  } = props;

  const disabled = checkDateDisabled(
    day.toDate(),
    otherFutureSchedules || [],
    participatedAnimals || [],
    participatedDevices || [],
    currentScheduleHabituationDays
  );

  const isScheduled = checkDateScheduledButNotDisabled(
    day.toDate(),
    otherFutureSchedules || [],
    participatedAnimals || [],
    participatedDevices || [],
    currentScheduleHabituationDays
  );

  const otherSchedulesOnThisDay = otherFutureSchedules?.filter(
    (s) =>
      s.startDate &&
      s.endDate &&
      day.toDate() >= new Date(s.startDate) &&
      day.toDate() <= new Date(s.endDate)
  );

  const isBetween =
    startDate &&
    endDate &&
    (dayjs(day).isSame(dayjs(startDate), "day") ||
      dayjs(day).isSame(dayjs(endDate), "day") ||
      (dayjs(day).isAfter(dayjs(startDate)) &&
        dayjs(day).isBefore(dayjs(endDate))));
  const isSameAsStartDate = startDate
    ? dayjs(day).isSame(dayjs(startDate), "day")
    : false;
  const isSameAsEndDate = endDate
    ? dayjs(day).isSame(dayjs(endDate), "day")
    : false;

  return (
    <LightTooltip
      enterDelay={1000}
      enterNextDelay={700}
      title={
        <Box sx={{ minWidth: "400px" }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Research Name</TableCell>
                  <TableCell align="right">Start Date</TableCell>
                  <TableCell align="right">End Date</TableCell>
                  <TableCell align="right">Habituation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {otherSchedulesOnThisDay?.map((schedule) => (
                  <TableRow
                    key={schedule._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {schedule.name}
                    </TableCell>
                    <TableCell align="right">
                      {dayjs(schedule.startDate).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell align="right">
                      {dayjs(schedule.endDate).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell align="right">
                      {schedule.habituationDays
                        ? `${schedule.habituationDays} day${
                            schedule.habituationDays > 1 ? "s" : ""
                          }`
                        : "---"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
    >
      <Box
        sx={{
          "& .MuiPickersDay-root": {
            color: isScheduled ? "#ff881e" : undefined,
          },
          "& .MuiPickersDay-root:not(.Mui-selected)": {
            backgroundColor: isScheduled ? "#f1e3d2" : undefined,
          },
          "& .MuiPickersDay-root.Mui-selected": {
            marginRight:
              startDate && endDate && isSameAsEndDate ? "0px" : undefined,
            marginLeft:
              startDate && endDate && isSameAsStartDate ? "0px" : undefined,
          },
          backgroundColor:
            !outsideCurrentMonth && isBetween ? "#29736f91" : undefined,
          borderTopLeftRadius: isSameAsStartDate ? "100px" : "",
          borderBottomLeftRadius: isSameAsStartDate ? "100px" : "",
          borderTopRightRadius: isSameAsEndDate ? "100px" : "",
          borderBottomRightRadius: isSameAsEndDate ? "100px" : "",
        }}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          disabled={disabled}
        />
      </Box>
    </LightTooltip>
  );
}

export default function ResearchScheduleForm({
  onChangeFormData,
  initialData,
  onNewCreated,
  onUpdated,
  otherFutureSchedules,
}: {
  onChangeFormData?: (formData: Partial<IResearchSchedule>) => void;
  initialData?: IResearchSchedule;
  onNewCreated?: (created: IResearchSchedule) => void;
  onUpdated?: (updated: IResearchSchedule) => void;
  otherFutureSchedules: IResearchSchedule[];
}) {
  const [formData, setFormData] = useState<
    Partial<IResearchSchedule> | undefined
  >(initialData);
  const [addingDevice, setAddingDevice] = useState<
    { type?: ResearchLabDeviceType; stock?: number } | undefined
  >(undefined);
  const [popoverKey, setPopoverKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState<IResearchLabDevice[]>([]);
  const [animals, setAnimals] = useState<ICow[]>([]);
  const [fetchingAnimals, setFetchingAnimals] = useState(false);
  const [diets, setDiets] = useState<IDiet[]>([]);
  const [editingNote, setEditingNote] = useState<string>("");
  const [targetDeviceId, setTargetDeviceId] = useState<string | undefined>(
    undefined
  );
  const [openEditingNoteDialog, setOpenEditingNoteDialog] = useState(false);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);

  const getLabDevices = async () => {
    setIsLoading(true);
    const devices = await researchLabScheduleService.getLabDevices();
    setDevices(devices);
    setIsLoading(false);
  };

  const getDiets = async () => {
    const diets = await dietService.getAllDiets();
    setDiets(diets);
  };

  const fetchAnimals = async () => {
    setFetchingAnimals(true);
    const animals = await animalService.fetchAllCows();
    setAnimals(animals);
    setFetchingAnimals(false);
  };

  const handleCreateSchedule = async () => {
    if (!formData) return;
    setIsLoading(true);
    const result = await researchLabScheduleService.addNewSchedule(
      formData as any
    );
    onNewCreated && onNewCreated(result);
    setIsLoading(false);
  };

  const handleUpdateSchedule = async () => {
    if (!formData) return;
    setIsLoading(true);
    const result = await researchLabScheduleService.updateSchedule(
      formData as any
    );
    onUpdated && onUpdated(result);
    setIsLoading(false);
  };

  const availableCowIds = React.useMemo(() => {
    return animals
      .filter(
        (animal) =>
          !formData?.startDate ||
          !formData?.endDate ||
          (!otherFutureSchedules?.filter(
            (s) =>
              s.habituationDays &&
              twoTimePeriodsOverlap(
                formData?.startDate || new Date(),
                formData?.endDate || new Date(),
                s.startDate,
                s.endDate
              ) &&
              s.participatedAnimals?.some((a) => a.cow?._id == animal._id)
          )?.length &&
            (otherFutureSchedules?.filter(
              (s) =>
                !s.habituationDays &&
                twoTimePeriodsOverlap(
                  formData?.startDate || new Date(),
                  formData?.endDate || new Date(),
                  s.startDate,
                  s.endDate
                ) &&
                s.participatedAnimals?.some((a) => a.cow?._id == animal._id)
            )?.length || 0) < 2)
      )
      .map((a) => a._id);
  }, [animals, formData?.startDate, formData?.endDate, otherFutureSchedules]);

  const availableDeviceIds = React.useMemo(() => {
    return devices
      .filter(
        (device) =>
          device.lastCalibratedDate &&
          dayjs().diff(dayjs(device.lastCalibratedDate), "day") <= 90 &&
          (!formData?.startDate ||
            !formData?.endDate ||
            !otherFutureSchedules?.filter(
              (s) =>
                twoTimePeriodsOverlap(
                  getActualStartDate(
                    formData?.startDate,
                    formData?.habituationDays
                  ) || new Date(),
                  formData?.endDate || new Date(),
                  getActualStartDate(s.startDate, s.habituationDays) ||
                    s.endDate,
                  s.endDate
                ) && s.participatedDevices?.some((d) => d._id == device._id)
            )?.length)
      )
      .map((a) => a._id);
  }, [devices, formData?.startDate, formData?.endDate, otherFutureSchedules]);

  React.useEffect(() => {
    if (onChangeFormData && formData) {
      onChangeFormData(formData as any);
    }
  }, [formData]);

  React.useEffect(() => {
    getLabDevices();
    fetchAnimals();
    getDiets();
  }, []);

  return (
    <div>
      <Stack spacing={3}>
        <Stack direction="row" spacing={2}>
          <TextField
            sx={{ minWidth: "250px" }}
            size="small"
            label="Name"
            value={formData?.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            size="small"
            fullWidth
            label="Description"
            value={formData?.description || ""}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
        </Stack>
        <Box
          sx={{
            "& .MuiMultiInputDateRangeField-separator": {
              marginLeft: "2px !important",
              marginRight: "2px !important",
            },
            "& .MuiFormControl-fullWidth": {
              marginLeft: "0px !important",
            },
          }}
        >
          <Stack direction="row" spacing={2}>
            <div>
              <GeneralPopoverWrapper
                triggerElement={
                  fetchingAnimals ? (
                    <Box
                      sx={{
                        width: "150px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Button
                      fullWidth
                      variant="outlined"
                      color={availableCowIds.length > 0 ? "primary" : "error"}
                      sx={{ whiteSpace: "nowrap", height: "40px" }}
                    >
                      Animals Available ({availableCowIds.length})
                    </Button>
                  )
                }
                popoverContent={
                  <Box p={2} sx={{ height: "350px", overflow: "auto" }}>
                    <DataGridPro
                      autoHeight
                      getRowId={(params) => params._id || ""}
                      rows={
                        animals?.filter((a) =>
                          availableCowIds.includes(a._id)
                        ) || []
                      }
                      columns={[
                        {
                          field: "name",
                          headerName: "Name",
                          width: 150,
                          valueGetter: (params) => params.row.name,
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "breed",
                          headerName: "Breed",
                          width: 80,
                          valueGetter: (params) => params.row.breed?.name,
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "scheduled",
                          headerName: "Scheduled",
                          width: 130,
                          renderCell: (params) => {
                            const scheduledTrials =
                              otherFutureSchedules?.filter((s) =>
                                s.participatedAnimals?.some(
                                  (a) => a.cow?._id == params.row._id
                                )
                              );
                            if (scheduledTrials?.length) {
                              return (
                                <GeneralPopoverWrapper
                                  triggerElement={
                                    <Chip
                                      sx={{ cursor: "pointer" }}
                                      label={`${scheduledTrials?.length} trial${
                                        scheduledTrials?.length > 1 ? "s" : ""
                                      } scheduled`}
                                      variant="outlined"
                                      size="small"
                                      color={
                                        !formData?.startDate ||
                                        !formData?.endDate ||
                                        scheduledTrials.some((s) =>
                                          twoTimePeriodsOverlap(
                                            formData?.startDate || new Date(),
                                            formData?.endDate || new Date(),
                                            s.startDate,
                                            s.endDate
                                          )
                                        )
                                          ? "warning"
                                          : twoTimePeriodsOverlap(
                                              formData?.startDate || new Date(),
                                              formData?.endDate || new Date(),
                                              scheduledTrials[0].startDate,
                                              scheduledTrials[0].endDate
                                            )
                                          ? "error"
                                          : undefined
                                      }
                                    />
                                  }
                                  popoverContent={
                                    <Box
                                      p={2}
                                      sx={{
                                        height: "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      <DataGridPro
                                        autoHeight
                                        getRowId={(params) => params._id || ""}
                                        rows={scheduledTrials || []}
                                        columns={[
                                          {
                                            field: "name",
                                            headerName: "Name",
                                            width: 150,
                                            sortable: false,
                                            editable: false,
                                          },
                                          {
                                            field: "period",
                                            headerName: "Period",
                                            width: 200,
                                            valueGetter: (params) =>
                                              `${fDashDate(
                                                params.row.startDate
                                              )} - ${fDashDate(
                                                params.row.endDate
                                              )}`,

                                            renderCell: (params) => {
                                              return (
                                                <Box
                                                  sx={{
                                                    color:
                                                      formData?.startDate &&
                                                      formData?.endDate &&
                                                      twoTimePeriodsOverlap(
                                                        formData?.startDate,
                                                        formData?.endDate,
                                                        params.row.startDate,
                                                        params.row.endDate
                                                      )
                                                        ? "warning.main"
                                                        : "inherit",
                                                  }}
                                                >{`${fDashDate(
                                                  params.row.startDate
                                                )} - ${fDashDate(
                                                  params.row.endDate
                                                )}`}</Box>
                                              );
                                            },
                                            sortable: false,
                                            editable: false,
                                          },
                                          {
                                            field: "habituationDays",
                                            headerName: "Habituation Days",
                                            width: 100,
                                            sortable: false,
                                            editable: false,
                                          },
                                        ]}
                                        columnHeaderHeight={32}
                                        rowHeight={32}
                                        disableColumnMenu
                                        hideFooter
                                        disableRowSelectionOnClick
                                      />
                                    </Box>
                                  }
                                />
                              );
                            } else {
                              return (
                                <div style={{ opacity: 0.2, fontSize: "14px" }}>
                                  No schedules
                                </div>
                              );
                            }
                          },
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "action",
                          headerName: "",
                          width: 50,
                          renderCell: (params) => (
                            <Checkbox
                              checked={formData?.participatedAnimals?.some(
                                (a) => a.cow?._id == params.row._id
                              )}
                              onChange={(e, checked) => {
                                const tempFormData = formData
                                  ? cloneDeep(formData)
                                  : {};
                                if (checked) {
                                  tempFormData.participatedAnimals = [
                                    ...(tempFormData.participatedAnimals || []),
                                    { cow: params.row },
                                  ];
                                } else {
                                  tempFormData.participatedAnimals =
                                    tempFormData.participatedAnimals?.filter(
                                      (a) => a.cow?._id != params.row._id
                                    );
                                }
                                setFormData(tempFormData);
                              }}
                            />
                          ),
                        },
                      ]}
                      columnHeaderHeight={32}
                      rowHeight={32}
                      disableColumnMenu
                      hideFooter
                      disableRowSelectionOnClick
                    />
                  </Box>
                }
              />
            </div>
            <div>
              <GeneralPopoverWrapper
                // anchorHorizontal="right"
                // transformHorizontal="right"
                triggerElement={
                  isLoading ? (
                    <Box
                      sx={{
                        width: "150px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Button
                      fullWidth
                      variant="outlined"
                      color={
                        availableDeviceIds.length > 0 ? "primary" : "error"
                      }
                      sx={{ whiteSpace: "nowrap", height: "40px" }}
                    >
                      Devices Available ({availableDeviceIds.length})
                    </Button>
                  )
                }
                popoverContent={
                  <Box p={2} sx={{ height: "350px", overflow: "auto" }}>
                    <DataGridPro
                      autoHeight
                      getRowId={(params) => params._id || ""}
                      rows={
                        devices?.filter((d) =>
                          availableDeviceIds.includes(d._id)
                        ) || []
                      }
                      columns={[
                        {
                          field: "type",
                          headerName: "Type",
                          width: 80,
                          valueGetter: (params) =>
                            Object.keys(ResearchLabDeviceType)[
                              Object.values(ResearchLabDeviceType).findIndex(
                                (v) => v == params.row.type
                              )
                            ],
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "deviceId",
                          headerName: "Device ID",
                          width: 150,
                          valueGetter: (params) => params.row.deviceId,
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "scheduled",
                          headerName: "Scheduled",
                          width: 130,
                          renderCell: (params) => {
                            const scheduledTrials =
                              otherFutureSchedules?.filter((s) =>
                                s.participatedDevices?.some(
                                  (d) => d._id == params.row._id
                                )
                              );
                            if (scheduledTrials?.length) {
                              return (
                                <GeneralPopoverWrapper
                                  triggerElement={
                                    <Chip
                                      sx={{ cursor: "pointer" }}
                                      label={`${scheduledTrials?.length} trial${
                                        scheduledTrials?.length > 1 ? "s" : ""
                                      } scheduled`}
                                      variant="outlined"
                                      size="small"
                                      color={
                                        !formData?.startDate ||
                                        !formData?.endDate
                                          ? "warning"
                                          : scheduledTrials?.some((ss) =>
                                              twoTimePeriodsOverlap(
                                                getActualStartDate(
                                                  formData?.startDate,
                                                  formData?.habituationDays
                                                ) || new Date(),
                                                formData?.endDate || new Date(),
                                                getActualStartDate(
                                                  ss.startDate,
                                                  ss.habituationDays
                                                ) || ss.endDate,
                                                ss.endDate
                                              )
                                            )
                                          ? "error"
                                          : undefined
                                      }
                                    />
                                  }
                                  popoverContent={
                                    <Box
                                      p={2}
                                      sx={{
                                        height: "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      <DataGridPro
                                        autoHeight
                                        getRowId={(params) => params._id || ""}
                                        rows={scheduledTrials || []}
                                        columns={[
                                          {
                                            field: "name",
                                            headerName: "Name",
                                            width: 150,
                                            sortable: false,
                                            editable: false,
                                          },
                                          {
                                            field: "period",
                                            headerName: "Period",
                                            width: 200,
                                            valueGetter: (params) =>
                                              `${fDashDate(
                                                getActualStartDate(
                                                  params.row.startDate,
                                                  params.row.habituationDays
                                                ) || params.row.endDate
                                              )} - ${fDashDate(
                                                params.row.endDate
                                              )}`,
                                          },
                                        ]}
                                        columnHeaderHeight={32}
                                        rowHeight={32}
                                        disableColumnMenu
                                        hideFooter
                                        disableRowSelectionOnClick
                                      />
                                    </Box>
                                  }
                                />
                              );
                            } else {
                              return (
                                <div style={{ opacity: 0.2, fontSize: "14px" }}>
                                  No schedules
                                </div>
                              );
                            }
                          },
                        },
                        {
                          field: "action",
                          headerName: "",
                          width: 50,
                          renderCell: (params) => (
                            <Checkbox
                              checked={formData?.participatedDevices?.some(
                                (d) => d._id == params.row._id
                              )}
                              onChange={(e, checked) => {
                                const tempFormData = formData
                                  ? cloneDeep(formData)
                                  : {};
                                if (checked) {
                                  tempFormData.participatedDevices = [
                                    ...(tempFormData?.participatedDevices ||
                                      []),
                                    params.row,
                                  ];
                                } else {
                                  tempFormData.participatedDevices =
                                    tempFormData.participatedDevices?.filter(
                                      (d) => d._id != params.row._id
                                    );
                                }
                                setFormData(tempFormData);
                              }}
                            />
                          ),
                        },
                      ]}
                      columnHeaderHeight={32}
                      rowHeight={32}
                      disableColumnMenu
                      hideFooter
                      disableRowSelectionOnClick
                    />
                  </Box>
                }
              />
            </div>
            <TextField
              size="small"
              type="number"
              label="Habituation Days"
              value={formData?.habituationDays}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  habituationDays: e.target.value
                    ? Number(e.target.value)
                    : undefined,
                })
              }
              sx={{ width: "150px" }}
            />
            <Box
              sx={{
                width: "300px",
                "& .MuiPickersDay-root.Mui-disabled:not(.Mui-selected)": {
                  background: "#e400001f",
                  color: "#dd0000",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  closeOnSelect={false}
                  showDaysOutsideCurrentMonth
                  formatDensity="dense"
                  slots={{
                    field: MultiInputDateRangeField,
                    day: CustomizedDayElement,
                  }}
                  slotProps={{
                    day: {
                      startDate: formData?.startDate,
                      endDate: formData?.endDate,
                      otherFutureSchedules: otherFutureSchedules,
                      participatedAnimals: formData?.participatedAnimals || [],
                      participatedDevices: formData?.participatedDevices || [],
                      currentScheduleHabituationDays: formData?.habituationDays,
                    } as any,

                    textField: {
                      size: "small",

                      fullWidth: true,
                      error:
                        formData?.startDate &&
                        formData?.endDate &&
                        Array.from({
                          length:
                            dayjs(formData.endDate).diff(
                              dayjs(formData.startDate),
                              "day"
                            ) + 1,
                        }).some((_, index) => {
                          const date = dayjs(formData.startDate).add(
                            index,
                            "day"
                          );
                          return checkDateDisabled(
                            date.toDate(),
                            otherFutureSchedules,
                            formData?.participatedAnimals || [],
                            formData?.participatedDevices || [],
                            formData?.habituationDays
                          );
                        }),
                    },
                    desktopPaper: {
                      sx: {
                        "& .MuiPickersDay-root.Mui-disabled:not(.Mui-selected)":
                          {
                            background: "#fce0e0",
                            color: "#dd0000",
                          },
                      },
                    },
                    mobilePaper: {
                      sx: {
                        "& .MuiPickersDay-root.Mui-disabled:not(.Mui-selected)":
                          {
                            background: "#fce0e0",
                            color: "#dd0000",
                          },
                      },
                    },
                  }}
                  value={[
                    formData?.startDate ? dayjs(formData?.startDate) : null,
                    formData?.endDate ? dayjs(formData?.endDate) : null,
                  ]}
                  onChange={(val) => {
                    const tempFormData = formData ? cloneDeep(formData) : {};
                    if (val[0]) {
                      tempFormData.startDate = val[0]?.toDate();
                    }
                    if (val[1]) {
                      tempFormData.endDate = val[1]?.toDate();
                    }
                    setFormData(tempFormData);
                  }}
                />
              </LocalizationProvider>
            </Box>
            {formData?.startDate && formData?.endDate && (
              <IconButton
                onClick={() => {
                  setFormData({
                    ...(formData || {}),
                    startDate: undefined,
                    endDate: undefined,
                  });
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        </Box>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "8px",
                  paddingLeft: "8px",
                  opacity: 0.9,
                }}
              >
                Participated Animals (
                {formData?.participatedAnimals?.length || 0})
              </div>
              <Box
                sx={{
                  border: "solid 1px #ddd",
                  borderRadius: "5px",
                  overflow: "hidden",
                  height: "400px",
                }}
              >
                <DataGridPro
                  getRowId={(params) => params.cow._id || ""}
                  rows={formData?.participatedAnimals || []}
                  columns={[
                    {
                      field: "cow",
                      headerName: "Cow",
                      width: 150,
                      valueGetter: (params) => params.row.cow.name,
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "diet",
                      headerName: "Diet",
                      width: 200,
                      valueGetter: (params) => params.row.diet?.name || "N/A",
                      renderCell: (params: any) => (
                        <Box>
                          {params.row.diet ? (
                            <Chip
                              variant="outlined"
                              label={params.row.diet.name}
                              onDelete={() => {
                                const tempAnimals = cloneDeep(
                                  formData?.participatedAnimals || []
                                );
                                const animalIndex = tempAnimals?.findIndex(
                                  (a) => a.cow?._id == params.row.cow?._id
                                );
                                tempAnimals[animalIndex].diet = undefined;
                                setFormData({
                                  ...formData,
                                  participatedAnimals: tempAnimals,
                                });
                              }}
                            />
                          ) : (
                            <Box sx={{ minWidth: "250px" }}>
                              <DietAutocomplete
                                allDiets={diets}
                                size="small"
                                label=""
                                placeholder="Select Diet"
                                onSelectValue={(diet) => {
                                  const tempAnimals = cloneDeep(
                                    formData?.participatedAnimals || []
                                  );
                                  const animalIndex = tempAnimals?.findIndex(
                                    (a) => a.cow?._id == params.row.cow?._id
                                  );
                                  tempAnimals[animalIndex].diet = diet;
                                  setFormData({
                                    ...formData,
                                    participatedAnimals: tempAnimals,
                                  });
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      ),
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "actions",
                      headerName: "",
                      width: 40,
                      renderCell: (params) => (
                        <Box>
                          <Tooltip
                            title="Remove Animal"
                            placement="right"
                            arrow
                          >
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => {
                                const tempAnimals = cloneDeep(
                                  formData?.participatedAnimals || []
                                );
                                const animalIndex = tempAnimals?.findIndex(
                                  (a) => a.cow?._id == params.row.cow?._id
                                );
                                tempAnimals.splice(animalIndex, 1);
                                setFormData({
                                  ...formData,
                                  participatedAnimals: tempAnimals,
                                });
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ),
                    },
                  ]}
                  columnHeaderHeight={35}
                  rowHeight={35}
                  disableColumnMenu
                  hideFooter
                  disableRowSelectionOnClick
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "8px",
                  paddingLeft: "8px",
                  opacity: 0.9,
                }}
              >
                Participated Devices (
                {formData?.participatedDevices?.length || 0})
              </div>
              <Box
                sx={{
                  border: "solid 1px #ddd",
                  borderRadius: "5px",
                  overflow: "hidden",
                  height: "400px",
                }}
              >
                <DataGridPro
                  getRowId={(params) => params._id || ""}
                  rows={formData?.participatedDevices || []}
                  columns={[
                    {
                      field: "type",
                      headerName: "Type",
                      width: 80,
                      valueGetter: (params) =>
                        Object.keys(ResearchLabDeviceType)[
                          Object.values(ResearchLabDeviceType).findIndex(
                            (v) => v == params.row.type
                          )
                        ],
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "typeIndex",
                      headerName: "Type #",
                      width: 70,
                      valueGetter: (params) => params.row.typeIndex,
                      renderCell: (params) => (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {params.row.typeIndex}
                          {formData?.notes?.some(
                            (n) => n.deviceId == params.row._id
                          ) ? (
                            <ChatIcon
                              color="primary"
                              sx={{
                                ml: 2,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setEditingNote(
                                  formData?.notes?.find(
                                    (n) => n.deviceId == params.row._id
                                  )?.note || ""
                                );
                                setTargetDeviceId(params.row._id);
                                setOpenEditingNoteDialog(true);
                              }}
                            />
                          ) : (
                            <ChatBubbleOutlineIcon
                              sx={{
                                ml: 2,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setEditingNote(
                                  formData?.notes?.find(
                                    (n) => n.deviceId == params.row._id
                                  )?.note || ""
                                );
                                setTargetDeviceId(params.row._id);
                                setOpenEditingNoteDialog(true);
                              }}
                            />
                          )}
                        </Box>
                      ),
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "sample",
                      headerName: "Sample",
                      flex: 1,
                      renderCell: (params: any) => {
                        const matchedSample =
                          formData?.participatedSamples?.find(
                            (s) => s.deviceId == params.row._id
                          )?.sample;
                        return (
                          <Box>
                            {matchedSample ? (
                              <Chip
                                variant="outlined"
                                label={`${matchedSample.sampleNumber}`}
                                onDelete={() => {
                                  setFormData({
                                    ...formData,
                                    participatedSamples:
                                      formData?.participatedSamples?.filter(
                                        (s) =>
                                          s.sample?._id != matchedSample._id
                                      ),
                                  });
                                }}
                              />
                            ) : (
                              <Box sx={{ minWidth: "250px" }}>
                                <SampleIdSingleAutocomplete
                                  getErrorText={(s) =>
                                    formData?.participatedSamples?.some(
                                      (ps) => ps.sample?._id == s._id
                                    )
                                      ? "Already added to other device"
                                      : ""
                                  }
                                  placeholder="Find sample and attach"
                                  value={matchedSample}
                                  onSelectValue={(sample) => {
                                    setFormData({
                                      ...formData,
                                      participatedSamples: [
                                        ...(formData?.participatedSamples ||
                                          []),
                                        { sample, deviceId: params.row._id },
                                      ],
                                    });
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        );
                      },
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "actions",
                      headerName: "",
                      width: 40,
                      renderCell: (params) => (
                        <Box>
                          <Tooltip
                            title="Remove Device"
                            placement="right"
                            arrow
                          >
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => {
                                const tempDevices = cloneDeep(
                                  formData?.participatedDevices || []
                                );
                                const deviceIndex = tempDevices?.findIndex(
                                  (d) => d._id == params.row._id
                                );
                                tempDevices.splice(deviceIndex, 1);
                                setFormData({
                                  ...formData,
                                  participatedDevices: tempDevices,
                                });
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ),
                    },
                  ]}
                  columnHeaderHeight={35}
                  rowHeight={35}
                  disableColumnMenu
                  hideFooter
                  disableRowSelectionOnClick
                />
                {openEditingNoteDialog && targetDeviceId ? (
                  <Dialog
                    open={openEditingNoteDialog}
                    onClose={() => setOpenEditingNoteDialog(false)}
                  >
                    <DialogTitle>
                      {`${
                        Object.keys(ResearchLabDeviceType)[
                          Object.values(ResearchLabDeviceType).findIndex(
                            (v) =>
                              v ==
                              formData?.participatedDevices?.find(
                                (d) => d._id == targetDeviceId
                              )?.type
                          )
                        ]
                      } - ${
                        formData?.participatedDevices?.find(
                          (d) => d._id == targetDeviceId
                        )?.typeIndex
                      }`}
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        placeholder="Enter Note"
                        rows={4}
                        multiline
                        fullWidth
                        value={editingNote}
                        onChange={(e) => {
                          setEditingNote(e.target.value || "");
                        }}
                      />
                      <Button
                        sx={{ mt: 2 }}
                        fullWidth
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            notes: [
                              ...(formData?.notes || []).filter(
                                (n) => n.deviceId != targetDeviceId
                              ),

                              {
                                deviceId: targetDeviceId,
                                note: editingNote,
                              },
                            ],
                          });
                          setEditingNote("");
                          setOpenEditingNoteDialog(false);
                        }}
                      >
                        Save
                      </Button>
                    </DialogContent>
                  </Dialog>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {formData?.documents?.length ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GeneralPopoverWrapper
                triggerElement={
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    sx={{ mr: 1 }}
                    startIcon={<NoteAddIcon />}
                  >
                    {`${formData?.documents?.length} Document${
                      formData?.documents?.length > 1 ? "s" : ""
                    }`}
                  </Button>
                }
                popoverContent={
                  <Box sx={{ minWidth: "250px", p: 1 }}>
                    {formData?.documents?.map((d) => (
                      <AttachedFileItem
                        key={d}
                        url={d}
                        onRemove={() => {
                          setFormData({
                            ...formData,
                            documents: formData?.documents?.filter(
                              (doc) => doc != d
                            ),
                          });
                        }}
                      />
                    ))}
                  </Box>
                }
              />
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  setOpenFileUploadDialog(true);
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Box>
          ) : (
            <Button
              onClick={() => {
                setOpenFileUploadDialog(true);
              }}
              variant="outlined"
              size="small"
              color="primary"
              sx={{ mr: 1 }}
              startIcon={<NoteAddIcon />}
            >
              Add Documents
            </Button>
          )}
          <div style={{ flex: 1 }} />
          <Button
            onClick={() => {
              setFormData({});
            }}
            variant="outlined"
            size="small"
            color="error"
            sx={{ mr: 1 }}
          >
            Empty All Fields
          </Button>
          <ProgressButton
            variant="contained"
            size="small"
            disabled={!formData?.name || !formData?.startDate}
            onClick={
              initialData?._id ? handleUpdateSchedule : handleCreateSchedule
            }
            inProgress={isLoading}
            title={initialData?._id ? "Update" : "Create"}
          />
        </Box>
      </Stack>
      {openFileUploadDialog && (
        <AnyAssetFileUploader
          directory="researchSchedule"
          open
          onClose={() => setOpenFileUploadDialog(false)}
          onSave={(urls) => {
            const updatedDocUrls = uniqBy(
              [...(formData?.documents || []), ...urls],
              (v) => v
            );
            setFormData({
              ...formData,
              documents: updatedDocUrls,
            });
            setOpenFileUploadDialog(false);
          }}
          title="Upload Documents"
          multiple
        />
      )}
    </div>
  );
}
