/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddIconButton from "../Common/AddIconButton";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { Box, Chip, IconButton } from "@mui/material";
import {
  IAdditiveEffect,
  IAdditiveEffectAlgorithm,
} from "../../../types/additiveAlgorithm";
import additiveAlgorithmService from "../../../service/additiveAlgorithm.service";
import AdditiveAlgorithmFormDialog from "./AdditiveAlgorithmFormDialog";
import ConfirmDialog from "../Common/ConfirmDialog";

export default function ResearchScheduleManagement() {
  const [isLoading, setIsLoading] = useState(false);
  const [popoverKey, setPopoverKey] = useState<number>(0);
  const [additiveAlgorithms, setAdditiveAlgorithms] = useState<
    IAdditiveEffectAlgorithm[]
  >([]);
  const [editingAdditiveAlgorithm, setEditingAdditiveAlgorithm] =
    useState<IAdditiveEffectAlgorithm>();
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const columns = [
    { field: "name", headerName: "Name" },
    {
      field: "additiveEffects",
      headerName: "Additive Effects",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Box>
            {params.row.additiveEffects.map((effect: IAdditiveEffect) => {
              return (
                <Chip
                  key={effect.chemicalFieldName}
                  sx={{ mr: "4px" }}
                  variant="outlined"
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ mr: 1 }}>{effect.chemicalFieldName}</Box>
                      <Box
                        sx={{
                          background:
                            effect.operator == "+"
                              ? "#caffda"
                              : effect.operator == "-"
                              ? "#ffe0ca"
                              : undefined,
                          padding: "1px 8px",
                          borderRadius: "5px",
                        }}
                      >{`${effect.operator} ${effect.value} ${
                        effect.isPercentage ? "%" : ""
                      }`}</Box>
                    </Box>
                  }
                />
              );
            })}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              size="small"
              onClick={() => {
                setEditingAdditiveAlgorithm(params.row);
                setOpenFormDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditingAdditiveAlgorithm(params.row);
                setDeleteConfirmDialog(true);
              }}
              size="small"
              sx={{ ml: 1 }}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const getAdditiveAlgorithms = async () => {
    const data = await additiveAlgorithmService.getAdditiveAlgorithms();
    setAdditiveAlgorithms(data || []);
  };

  const handleDelete = async () => {
    if (!editingAdditiveAlgorithm?._id) {
      return;
    }
    await additiveAlgorithmService.deleteAdditiveAlgorithm(
      editingAdditiveAlgorithm?._id
    );
    setAdditiveAlgorithms(
      additiveAlgorithms.filter((a) => a._id != editingAdditiveAlgorithm?._id)
    );
    setDeleteConfirmDialog(false);
    setEditingAdditiveAlgorithm(undefined);
  };

  useEffect(() => {
    getAdditiveAlgorithms();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <AddIconButton
          onClick={() => {
            setEditingAdditiveAlgorithm(undefined);
            setOpenFormDialog(true);
          }}
        />
        <h2
          style={{
            margin: "0px 0px 0px 10px",
            display: "flex",
            alignItems: "center",
            opacity: 0.8,
          }}
        >
          Additive Effect Algorithms
          <PendingActionsIcon sx={{ marginLeft: "8px" }} />
        </h2>
        <div style={{ flex: 1 }} />
      </div>

      <div>
        <DataGridPro
          getRowId={(row) => row._id}
          checkboxSelection
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedIds(newSelectionModel as string[]);
          }}
          rowSelectionModel={selectedIds}
          autoHeight
          rows={additiveAlgorithms}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
        />
      </div>
      {openFormDialog && (
        <AdditiveAlgorithmFormDialog
          existingAlgorithmNames={additiveAlgorithms
            .filter(
              (a) =>
                !editingAdditiveAlgorithm ||
                a.name != editingAdditiveAlgorithm?.name
            )
            .map((algorithm) => algorithm.name)}
          open={openFormDialog}
          onClose={() => setOpenFormDialog(false)}
          initialFormData={editingAdditiveAlgorithm}
          handleUpdate={(updated) => {
            setAdditiveAlgorithms(
              additiveAlgorithms.map((algorithm) =>
                algorithm._id === updated._id ? updated : algorithm
              )
            );
            setEditingAdditiveAlgorithm(undefined);
            setOpenFormDialog(false);
          }}
          handleCreate={(created) => {
            setAdditiveAlgorithms([...additiveAlgorithms, created]);
            setEditingAdditiveAlgorithm(undefined);
            setOpenFormDialog(false);
          }}
        />
      )}
      {deleteConfirmDialog && editingAdditiveAlgorithm ? (
        <ConfirmDialog
          onConfirm={handleDelete}
          onCancel={() => setDeleteConfirmDialog(false)}
          title={`Delete Additive Algorithm "${editingAdditiveAlgorithm?.name}"`}
          content="Are you sure you want to delete this additive algorithm?"
        />
      ) : null}
    </div>
  );
}
