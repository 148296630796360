/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import { Stack, TextField } from "@mui/material";
import SampleIdSingleAutocomplete from "../Common/SampleIdSingleutocomplete";
import { ISampleRecordPopulated } from "../../../types/sample";
import ProgressButton from "../Common/ProgressButton";
import sampleRecordService from "../../../service/sampleRecord.service";
import { showSnackbar } from "../../../service/snackbar.service";

export default function PostWeightInputForm() {
  const [selectedSample, setSelectedSample] =
    React.useState<ISampleRecordPopulated>();
  const [bagWeight48, setBagWeight48] = React.useState<number>();
  const [NDF48, setNDF48] = React.useState<number>();
  const [saving, setSaving] = React.useState(false);

  const handleSubmit = async () => {
    if (!selectedSample) return;
    if (!bagWeight48 && !NDF48) return;
    try {
      setSaving(true);
      await sampleRecordService.updateRecord(
        selectedSample.uid,
        {
          bagWeight48,
          chemicals: {
            ...selectedSample.chemicals,
            NDF48: NDF48,
          },
        },
        [
          {
            fieldName: "bagWeight48",
            oldValue: selectedSample.bagWeight48,
            newValue: bagWeight48,
          },
          {
            fieldName: "NDF48",
            oldValue: selectedSample.chemicals?.["NDF48"],
            newValue: NDF48,
            isChemical: true,
          },
        ],
        "Weight Input After Processing"
      );
      setSaving(false);
      showSnackbar("success", "Success", "Weights saved successfully");
      setBagWeight48(undefined);
      setNDF48(undefined);
      setSelectedSample(undefined);
    } catch (e) {
      setSaving(false);
      showSnackbar("danger", "Error", "Failed to save weights");
    }
  };

  React.useEffect(() => {
    if (selectedSample) {
      setBagWeight48(selectedSample.bagWeight48);
      setNDF48(selectedSample.chemicals?.["NDF48"]);
    }
  }, [selectedSample]);
  return (
    <Stack spacing={3}>
      <SampleIdSingleAutocomplete
        includeChemicals
        fullWidth
        value={selectedSample}
        onSelectValue={setSelectedSample}
        label="Find Sample By Sample Number"
      />
      <TextField
        type="number"
        size="small"
        label="Bag Weight after 48 hours (mg)"
        value={bagWeight48 || ""}
        onChange={(e) =>
          setBagWeight48(
            e.target.value != "" && e.target.value != undefined
              ? Number(e.target.value)
              : undefined
          )
        }
      />
      <TextField
        type="number"
        size="small"
        label="NDF after 48 hours (mg)"
        value={NDF48 || ""}
        onChange={(e) =>
          setNDF48(
            e.target.value != "" && e.target.value != undefined
              ? Number(e.target.value)
              : undefined
          )
        }
      />
      <ProgressButton
        inProgress={saving}
        disabled={!selectedSample || (!bagWeight48 && !NDF48)}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        title="Save"
      />
    </Stack>
  );
}
