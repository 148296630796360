/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CropTypes } from "./chemicals";
import { ICompany } from "./company";
import { IGasAnalysis } from "./gasAnalysis";
import { IPredictionAiModel } from "./predictionModel";
import { ISpectraItem } from "./spectra";
import { IUser } from "./user";

export const DietRegions: { regionName: string; value: number }[] = [
  { regionName: 'montana', value: 3 },
  { regionName: 'nebraska', value: 7 },
  { regionName: 'ohio', value: 7 },
  { regionName: 'texas', value: 2 },
  { regionName: 'idaho', value: 3 },
  { regionName: 'oklahoma', value: 7 },
  { regionName: 'new mexico', value: 2 },
  { regionName: 'kentucky', value: 7 },
  { regionName: 'illinois', value: 7 },
  { regionName: 'oregon', value: 4 },
  { regionName: 'south dakota', value: 6 },
  { regionName: 'minnesota', value: 6 },
  { regionName: 'pennsylvania', value: 8 },
  { regionName: 'tennessee', value: 7 },
  { regionName: 'connecticut', value: 8 },
  { regionName: 'massachusetts', value: 8 },
  { regionName: 'washington', value: 4 },
  { regionName: 'california', value: 1 },
  { regionName: 'arizona', value: 2 },
  { regionName: 'kansas', value: 7 },
  { regionName: 'west virginia', value: 8 },
  { regionName: 'delaware', value: 8 },
  { regionName: 'vermont', value: 8 },
  { regionName: 'wisconsin', value: 6 },
  { regionName: 'north carolina', value: 8 },
  { regionName: 'maryland', value: 8 },
  { regionName: 'arkansas', value: 7 },
  { regionName: 'new hampshire', value: 8 },
  { regionName: 'wyoming', value: 3 },
  { regionName: 'michigan', value: 6 },
  { regionName: 'new york', value: 8 },
  { regionName: 'new jersey', value: 8 },
  { regionName: 'utah', value: 2 },
  { regionName: 'georgia', value: 5 },
  { regionName: 'iowa', value: 6 },
  { regionName: 'florida', value: 5 },
  { regionName: 'north dakota', value: 6 },
  { regionName: 'south carolina', value: 8 },
  { regionName: 'louisiana', value: 5 },
  { regionName: 'maine', value: 8 },
  { regionName: 'missouri', value: 7 },
  { regionName: 'nevada', value: 2 },
  { regionName: 'indiana', value: 6 },
  { regionName: 'colorado', value: 3 },
  { regionName: 'virginia', value: 8 }
];

export enum SampleGroupType {
  UploadLog = "Upload Log",
  DownloadLog = "Download Log",
  Collection = "Collection",
}

export enum SampleRecordManualUpdateReason {
  ManualInput = "Initial Manual Input",
  ImprovedAnalysis = "Improved Analysis",
  FixEntryError = "Fix Entry Error",
  Other = "Other",
}

export interface ISampleRecordManualUpdate {
  reason?: string;
  updatedBy: { fullName: string; companyName: string; userId: string };
  updatedAt: Date;
  updatedFields: {
    isChemical?: boolean;
    fieldName: string;
    oldValue: any;
    newValue: any;
  }[];
}
export interface ISampleRecordGroup {
  _id?: string;
  name: string;
  description?: string;
  uids: number[];
  companyId?: string;
  type?: SampleGroupType;
  createdAt?: Date;
  actionBy?: IUser;
  company?: ICompany;
}
export interface ISampleRecordBaseFields {
  product?: string;
  application?: string;
  companyUid?: number;
  sampleNumber?: string;
  sampleSource?: string;
  dataSource?: string;
  sampleClient?: string;
  description?: string;
  reportType?: string;
  bagWeight?: number;
  bagWeight48?: number;
  wetSampleWeight?: number;
  drySampleWeight?: number;
  panWeight?: number;
  dataset?: string;
  country?: string;
  region?: string;
  dietRegion?: number;
  coordinates?: { lat: number; lon: number };
  year?: number;
  type?: CropTypes;
  chemicals?: { [key: string]: number | undefined };
  spectraItems?: ISpectraItem[];
  spectraData?: string;
  sampleTakenDate?: string;
  scannerCalibrationFiles?: string[];
  manualUpdates?: ISampleRecordManualUpdate[];
  predictedType?: CropTypes;
}

export interface ISampleRecordCreationPayload extends ISampleRecordBaseFields {
  temporaryId?: number;
  predictionAiModel?: IPredictionAiModel;
  // ...base fields here
}

export interface ISampleRecordPopulated extends ISampleRecordBaseFields {
  _id?: string;
  uid: number;
  company?: ICompany;
  vendorCompany?: ICompany;
  // ...base fields here
  createdAt?: Date;
  gasAnalysis?: IGasAnalysis;
  predictionAiModel?: IPredictionAiModel;
}

export interface IArchivedPrediction {
  product?: string;
  type?: string;
  sampleNumber: string;
  chemicals?: { [key: string]: number };
  spectraItems?: ISpectraItem[];
  predictionAiModel?: IPredictionAiModel;
  company?: ICompany;
  createdAt?: Date;
}
