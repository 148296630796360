import * as React from "react";
import { Box, Chip, Divider, Typography } from "@mui/material";
import {
  IResearchSchedule,
  ResearchLabDeviceType,
} from "../../../types/researchLabSchedule";
import { fDashDate } from "../../../helpers/formatTime";

export default function ScheduleTooltipContent({
  schedule,
  maxWidth,
}: {
  schedule: IResearchSchedule;
  maxWidth?: number;
}) {
  return (
    <Box sx={{ px: 2, py: 1, maxWidth: `${maxWidth || 400}px` }}>
      <Typography variant="h6">{schedule.name}</Typography>
      <Box
        sx={{
          mt: "3px",
          display: "flex",
          alignItems: "center",
          fontSize: "13px",
        }}
      >
        <div>{`${fDashDate(schedule.startDate)} ~ ${fDashDate(
          schedule.endDate
        )}`}</div>
        <div style={{ marginLeft: "8px", opacity: 0.7 }}>{`(${Math.ceil(
          (new Date(schedule.endDate).getTime() -
            new Date(schedule.startDate).getTime()) /
            (1000 * 60 * 60 * 24)
        )} days)`}</div>
      </Box>
      {schedule?.habituationDays && (
        <Box
          sx={{
            color: "warning.main",
            fontSize: "12px",
            marginTop: "6px",
          }}
        >
          {`Habituation: ${schedule?.habituationDays} day${
            schedule?.habituationDays > 1 ? "s" : ""
          }`}
        </Box>
      )}
      <Divider sx={{ my: "8px" }} />
      <Box>
        <Box
          sx={{
            mb: "4px",
            fontSize: "13px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Animals
        </Box>
        <div>
          {schedule?.participatedAnimals?.map((animal) => {
            return (
              <Chip
                key={animal.cow?._id}
                label={animal.cow?.name}
                size="small"
                sx={{ my: "2px", mr: "3px" }}
              />
            );
          })}
        </div>
      </Box>
      <Box>
        <Box
          mt={2}
          sx={{
            mb: "4px",
            fontSize: "13px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Devices
        </Box>
        <div>
          {schedule?.participatedDevices?.map((device) => {
            return (
              <Chip
                key={device._id}
                label={`${
                  Object.keys(ResearchLabDeviceType)[
                    Object.values(ResearchLabDeviceType).findIndex(
                      (v) => v == device.type
                    )
                  ]
                } ${device.typeIndex}`}
                size="small"
                sx={{ my: "2px", mr: "3px" }}
              />
            );
          })}
        </div>
      </Box>
    </Box>
  );
}
