/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from "lodash";
import {
  IAdditiveEffectAlgorithm,
  MathOperators,
} from "../../../types/additiveAlgorithm";
import { ISampleRecordPopulated } from "../../../types/sample";

export const checkAdditiveApplicableToSample = (
  additive: IAdditiveEffectAlgorithm,
  sample: ISampleRecordPopulated
): {
  satisfied: boolean;
  unsatisfiedReason?: string;
  additionalCondition?: string;
} => {
  if (!additive?.conditionSets?.length) return { satisfied: true };
  const conditionSets = additive.conditionSets;
  let unsatisfiedReason = "";
  const satisfiedConditionSet = conditionSets.find((conditionSet) => {
    let satisfied = true;
    conditionSet.chemicalConditions?.map((condition) => {
      const chemicalValue = sample?.chemicals?.[condition.chemicalFieldName];
      if (!chemicalValue) {
        unsatisfiedReason = `Chemical Value for ${condition.chemicalFieldName} not found`;
        satisfied = false;
        return;
      }
      if (condition.minValue && chemicalValue < condition.minValue) {
        unsatisfiedReason = `Chemical Value for ${condition.chemicalFieldName} is less than Min Value: ${condition.minValue}`;
        satisfied = false;
        return;
      }
      if (condition.maxValue && chemicalValue > condition.maxValue) {
        unsatisfiedReason = `Chemical Value for ${condition.chemicalFieldName} is greater than Max Value: ${condition.maxValue}`;
        satisfied = false;
        return;
      }
    });
    return satisfied;
  });
  return {
    satisfied: !!satisfiedConditionSet,
    unsatisfiedReason,
    additionalCondition: satisfiedConditionSet?.additionalCondition,
  };
};

export const getAdditiveAppliedSampleRecord = (
  additive: IAdditiveEffectAlgorithm,
  sample: ISampleRecordPopulated
) => {
  const { satisfied } = checkAdditiveApplicableToSample(additive, sample);
  if (!satisfied) return undefined;
  const tempSample = cloneDeep(sample);
  additive.additiveEffects?.map((effect) => {
    if (!effect.chemicalFieldName || !effect.operator || !effect.value) return;
    const chemicalValue = tempSample?.chemicals?.[effect.chemicalFieldName];
    if (chemicalValue === undefined) return;
    if (effect.isPercentage) {
      if (effect.operator === MathOperators.Add) {
        (tempSample.chemicals as any)[effect.chemicalFieldName] +=
          chemicalValue * (effect.value / 100);
      } else if (effect.operator === MathOperators.Subtract) {
        (tempSample.chemicals as any)[effect.chemicalFieldName] -=
          chemicalValue * (effect.value / 100);
      } else if (effect.operator === MathOperators.Multiply) {
        (tempSample.chemicals as any)[effect.chemicalFieldName] *=
          1 + effect.value / 100;
      } else if (effect.operator === MathOperators.Divide) {
        (tempSample.chemicals as any)[effect.chemicalFieldName] /=
          1 + effect.value / 100;
      }
    } else {
      if (effect.operator === MathOperators.Add) {
        (tempSample.chemicals as any)[effect.chemicalFieldName] += effect.value;
      } else if (effect.operator === MathOperators.Subtract) {
        (tempSample.chemicals as any)[effect.chemicalFieldName] -= effect.value;
      } else if (effect.operator === MathOperators.Multiply) {
        (tempSample.chemicals as any)[effect.chemicalFieldName] *= effect.value;
      } else if (effect.operator === MathOperators.Divide) {
        (tempSample.chemicals as any)[effect.chemicalFieldName] /= effect.value;
      }
    }
  });
  return tempSample;
};
