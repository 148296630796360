/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@mui/material/styles/styled";
import { useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import SideDrawer from "../components/Navbar/SideDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppContext } from "../../contexts/useStorageContext";
import { actionTypes } from "../../types/state";

const DrawerWidth = 270;

const Main = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "openSideMenu" && prop !== "drawerWidth",
})<{
  openSideMenu?: boolean;
  drawerWidth?: number;
}>(({ theme, openSideMenu, drawerWidth }) => ({
  height: "100%",
  backgroundColor: theme.palette?.background?.default,
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `-${drawerWidth}px`,
  marginLeft: "0px",
  ...(openSideMenu && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: 0,
    marginLeft: `${drawerWidth}px`,
  }),
}));

export default function Layout() {
  const location = useLocation();
  const { dispatch, state } = useAppContext();
  const navigation = useNavigate();
  const isLoggedIn = state.loggedInUserInfo?.email;
  const isLargeScreen = useMediaQuery("(min-width:961px)");
  const { openSideDrawer } = state;
  const [backendVersions, setBackendVersions] = React.useState({});

  React.useEffect(() => {
    if (isLoggedIn) {
      if (location.pathname.length < 2) {
        navigation(`/dashboard`);
      }
    } else {
      navigation(`/`);
    }
  }, []);

  React.useEffect(() => {
    if (!isLargeScreen) {
      dispatch!({ type: actionTypes.setOpenSideDrawer, value: false });
    } else {
      dispatch!({ type: actionTypes.setOpenSideDrawer, value: false });
    }
  }, [isLargeScreen]);

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
        id="app-layout-container"
      >
        <Navbar drawerWidth={isLargeScreen ? DrawerWidth : 0} />
        <Box
          sx={{
            flex: 1,
            background: "primary.main",
            paddingTop: "56px",
            backgroundColor: "#dedede",
          }}
        >
          <Main
            openSideMenu={openSideDrawer}
            drawerWidth={isLargeScreen ? DrawerWidth : 0}
            sx={{ boxSizing: "border-box", p: { xs: 1, sm: 2, lg: 3 } }}
          >
            <Outlet />
          </Main>
          <SideDrawer width={DrawerWidth} backendVersions={backendVersions} />
        </Box>
      </Box>
    </>
  );
}
