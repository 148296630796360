import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "tss-react/mui";

const LightTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[10],
    maxWidth: "500px",
  },
}));

export default LightTooltip;
