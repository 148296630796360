/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import BalanceIcon from "@mui/icons-material/Balance";
import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ISampleRecordPopulated } from "../../../../types/sample";
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import ProgressButton from "../ProgressButton";
import GasReport2 from "./AnalysisReportVariations/GasReport2";
import SampleIdSingleAutocomplete from "../SampleIdSingleutocomplete";
import GasReport3 from "./AnalysisReportVariations/GasReport3";
import { showSnackbar } from "../../../../service/snackbar.service";
import sampleRecordService from "../../../../service/sampleRecord.service";
import { IAdditiveEffectAlgorithm } from "../../../../types/additiveAlgorithm";
import additiveAlgorithmService from "../../../../service/additiveAlgorithm.service";
import AdditiveAlgorithmAutocomplete from "../../AdditiveAlgorithm/AdditiveAlgorithmAutocomplete";
import {
  checkAdditiveApplicableToSample,
  getAdditiveAppliedSampleRecord,
} from "../../AdditiveAlgorithm/AdditiveAlgorithmUtils";

export default function GasAnalysisReportDialog({
  open,
  onClose,
  sampleRecord,
}: {
  sampleRecord: ISampleRecordPopulated;
  open: boolean;
  onClose: () => void;
}): React.ReactElement {
  const printRef = React.useRef<HTMLElement>();
  const [downloadingReport, setDownloadingReport] = React.useState(false);
  const [reportVariation, setReportVariation] = React.useState(
    !sampleRecord.reportType
      ? 1
      : sampleRecord.reportType?.toLowerCase()?.includes("dairy") &&
        sampleRecord.reportType?.toLowerCase()?.includes("carbon")
      ? 1
      : sampleRecord.reportType?.toLowerCase()?.includes("dairy") &&
        !sampleRecord.reportType?.toLowerCase()?.includes("carbon")
      ? 2
      : sampleRecord.reportType?.toLowerCase()?.includes("beef") &&
        sampleRecord.reportType?.toLowerCase()?.includes("carbon")
      ? 3
      : 1
  );
  const [compareSampleRecord, setCompareSampleRecord] =
    React.useState<ISampleRecordPopulated>();
  const [appliedAdditiveAlgorithm, setAppliedAdditiveAlgorithm] =
    React.useState<IAdditiveEffectAlgorithm>();
  const [allSampleAlgorithms, setAllSampleAlgorithms] = React.useState<
    IAdditiveEffectAlgorithm[]
  >([]);
  const [fetchingAdditiveAlgorithms, setFetchingAdditiveAlgorithms] =
    React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const additiveAppliedSampleRecord = React.useMemo(() => {
    if (!appliedAdditiveAlgorithm) return undefined;
    const { satisfied } = checkAdditiveApplicableToSample(
      appliedAdditiveAlgorithm,
      sampleRecord
    );
    if (!satisfied) return undefined;
    return getAdditiveAppliedSampleRecord(
      appliedAdditiveAlgorithm,
      sampleRecord
    );
  }, [sampleRecord, appliedAdditiveAlgorithm]);

  const additiveApplicableToSample = React.useMemo(() => {
    if (!appliedAdditiveAlgorithm) return undefined;
    return checkAdditiveApplicableToSample(
      appliedAdditiveAlgorithm,
      sampleRecord
    );
  }, [sampleRecord, appliedAdditiveAlgorithm]);

  const handleGeneratePdf = async () => {
    try {
      const element = printRef.current;
      if (!element || !sampleRecord.sampleNumber) return;
      setDownloadingReport(true);
      const canvas = await html2canvas(element, { scale: 1 });
      const data = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = 8.5; // Width of PDF in inches
      const pdfHeight = 11; // Height of PDF in inches
      const imgWidth = imgProperties.width;
      const imgHeight = imgProperties.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const newWidth = imgWidth * ratio;
      const newHeight = imgHeight * ratio;
      const xOffset = (pdfWidth - newWidth) / 2;
      const yOffset = (pdfHeight - newHeight) / 2;
      pdf.addImage(data, "PNG", xOffset, yOffset, newWidth, newHeight);
      pdf.save(`${sampleRecord.sampleNumber}_gas_report.pdf`);
      // const pdf = new jsPDF();
      // const imgProperties = pdf.getImageProperties(data);
      // const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight = pdf.internal.pageSize.getHeight();
      // const imgHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      // const scaledHeight = imgHeight > pdfHeight ? pdfHeight : imgHeight; // Ensure the image height does not exceed the page height
      // pdf.addImage(data, "PNG", 0, 0, pdfWidth, scaledHeight);
      // pdf.save(`${sampleRecord.sampleNumber}_gas_report.pdf`);
      setDownloadingReport(false);
    } catch (error) {
      showSnackbar("danger", "Error", "Error generating PDF");
    } finally {
      setDownloadingReport(false);
    }
  };

  const handleMarkAsReportMade = async () => {
    setLoading(true);
    await sampleRecordService.markAsReportMade([sampleRecord.uid]);
    setLoading(false);
  };

  const getAdditiveAlgorithms = async () => {
    setFetchingAdditiveAlgorithms(true);
    const data = await additiveAlgorithmService.getAdditiveAlgorithms();
    setAllSampleAlgorithms(data || []);
    setFetchingAdditiveAlgorithms(false);
  };

  React.useEffect(() => {
    handleMarkAsReportMade();
    getAdditiveAlgorithms();
  }, []);

  return (
    <>
      <Dialog open={open} maxWidth="md" scroll="paper">
        <DialogTitle sx={{ py: 1, px: 2, pt: 2, paddingRight: "8px" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ProgressButton
              inProgress={downloadingReport}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              onClick={() => handleGeneratePdf()}
              title="PDF"
              sx={{ mr: 2 }}
            />
            <div style={{ flex: 1 }} />
            {fetchingAdditiveAlgorithms ? (
              <CircularProgress size={20} sx={{ mr: 2 }} />
            ) : (
              <div style={{ width: "250px", marginRight: "8px" }}>
                <AdditiveAlgorithmAutocomplete
                  label="Additive"
                  value={appliedAdditiveAlgorithm}
                  onSelectValue={setAppliedAdditiveAlgorithm}
                  allAdditiveAlgorithms={allSampleAlgorithms || []}
                />
              </div>
            )}

            <div style={{ width: "250px", marginRight: "8px" }}>
              <SampleIdSingleAutocomplete
                startIcon={
                  <BalanceIcon
                    sx={{ fontSize: "31px", mr: "-8px" }}
                    color={compareSampleRecord ? "primary" : "disabled"}
                  />
                }
                placeholder="Search sample number"
                label="Compared to"
                value={
                  appliedAdditiveAlgorithm ? undefined : compareSampleRecord
                }
                onSelectValue={setCompareSampleRecord}
                includeChemicals
                disabled={!!appliedAdditiveAlgorithm}
              />
            </div>
            <TextField
              select
              label={""}
              value={reportVariation}
              size="small"
              onChange={(e) => {
                setReportVariation(Number(e.target.value));
              }}
              sx={{
                minWidth: "200px",
                mr: 1,
                "& input": { padding: "5px 10px 5px 0px" },
              }}
            >
              <MenuItem value={1}>Dairy Carbon</MenuItem>
              <MenuItem value={2}>Dairy</MenuItem>
              <MenuItem value={3}>Beef Carbon</MenuItem>
            </TextField>

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {appliedAdditiveAlgorithm &&
          !additiveApplicableToSample?.satisfied ? (
            <Box
              sx={{
                background: "#fd604b",
                fontSize: "13px",
                fontWeight: "400",
                color: "#fff",
                padding: "5px 8px",
                marginTop: "8px",
              }}
            >
              {`"${appliedAdditiveAlgorithm.name}" can not be applied to this sample. (${additiveApplicableToSample?.unsatisfiedReason})`}
            </Box>
          ) : null}
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "1200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box p={2} ref={printRef}>
              {reportVariation == 1 ? (
                <GasReport2
                  sampleRecord={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord
                      ? additiveAppliedSampleRecord
                      : sampleRecord
                  }
                  comparedSampleRecord={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord
                      ? sampleRecord
                      : compareSampleRecord
                  }
                  topBar={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord ? (
                      <Box
                        sx={{
                          background:
                            additiveApplicableToSample.additionalCondition
                              ? "#da9d42"
                              : "#50c450",
                          color: "#fff",
                          fontSize: "14px",
                          padding: "5px 8px",
                          marginBottom: "9px",
                        }}
                      >
                        {`"${
                          appliedAdditiveAlgorithm.name
                        }" has been applied. ${
                          additiveApplicableToSample.additionalCondition
                            ? `(${additiveApplicableToSample.additionalCondition})`
                            : ""
                        }`}
                      </Box>
                    ) : undefined
                  }
                />
              ) : reportVariation == 2 ? (
                <GasReport3
                  sampleRecord={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord
                      ? additiveAppliedSampleRecord
                      : sampleRecord
                  }
                  comparedSampleRecord={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord
                      ? sampleRecord
                      : compareSampleRecord
                  }
                  topBar={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord ? (
                      <Box
                        sx={{
                          background:
                            additiveApplicableToSample.additionalCondition
                              ? "#da9d42"
                              : "#50c450",
                          color: "#fff",
                          fontSize: "14px",
                          padding: "5px 8px",
                          marginBottom: "9px",
                        }}
                      >
                        {`${appliedAdditiveAlgorithm.name} has been applied. ${
                          additiveApplicableToSample.additionalCondition
                            ? `(${additiveApplicableToSample.additionalCondition})`
                            : ""
                        }`}
                      </Box>
                    ) : undefined
                  }
                />
              ) : (
                <GasReport3
                  sampleRecord={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord
                      ? additiveAppliedSampleRecord
                      : sampleRecord
                  }
                  comparedSampleRecord={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord
                      ? sampleRecord
                      : compareSampleRecord
                  }
                  topBar={
                    appliedAdditiveAlgorithm &&
                    additiveApplicableToSample?.satisfied &&
                    additiveAppliedSampleRecord ? (
                      <Box
                        sx={{
                          background:
                            additiveApplicableToSample.additionalCondition
                              ? "#da9d42"
                              : "#50c450",
                          color: "#fff",
                          fontSize: "14px",
                          padding: "5px 8px",
                          marginBottom: "9px",
                        }}
                      >
                        {`${appliedAdditiveAlgorithm.name} has been applied. ${
                          additiveApplicableToSample.additionalCondition
                            ? `(${additiveApplicableToSample.additionalCondition})`
                            : ""
                        }`}
                      </Box>
                    ) : undefined
                  }
                />
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
