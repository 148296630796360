/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import { Stack, TextField } from "@mui/material";
import SampleIdSingleAutocomplete from "../Common/SampleIdSingleutocomplete";
import { ISampleRecordPopulated } from "../../../types/sample";
import ProgressButton from "../Common/ProgressButton";
import sampleRecordService from "../../../service/sampleRecord.service";
import { showSnackbar } from "../../../service/snackbar.service";

export default function BagWeightForm() {
  const [selectedSample, setSelectedSample] =
    React.useState<ISampleRecordPopulated>();
  const [bagWeight, setBagWeight] = React.useState<number>();
  const [saving, setSaving] = React.useState(false);

  const handleSubmit = async () => {
    if (!selectedSample) return;
    if (!bagWeight) return;
    try {
      setSaving(true);
      await sampleRecordService.updateRecord(
        selectedSample.uid,
        {
          bagWeight,
        },
        [
          {
            fieldName: "bagWeight",
            oldValue: selectedSample.bagWeight,
            newValue: bagWeight,
          },
        ],
        "Bag Weight Input"
      );
      setSaving(false);
      showSnackbar("success", "Success", "Bag Weight saved successfully");
      setBagWeight(undefined);
      setSelectedSample(undefined);
    } catch (e) {
      setSaving(false);
      showSnackbar("danger", "Error", "Failed to save bag weight");
    }
  };

  React.useEffect(() => {
    if (selectedSample) {
      setBagWeight(selectedSample.bagWeight);
    }
  }, [selectedSample]);
  return (
    <Stack spacing={3}>
      <SampleIdSingleAutocomplete
        includeChemicals
        fullWidth
        value={selectedSample}
        onSelectValue={setSelectedSample}
        label="Find Sample By Sample Number"
      />
      <TextField
        type="number"
        size="small"
        label="Bag Weight (mg)"
        value={bagWeight || ""}
        onChange={(e) =>
          setBagWeight(
            e.target.value != "" && e.target.value != undefined
              ? Number(e.target.value)
              : undefined
          )
        }
      />
      <ProgressButton
        inProgress={saving}
        disabled={!selectedSample || !bagWeight}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        title="Save"
      />
    </Stack>
  );
}
