/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppContext } from "../../../contexts/useStorageContext";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce } from "lodash";
import Chip from "@mui/material/Chip";
import dietService from "../../../service/intakeServices/diet.service";
import { IAdditiveEffectAlgorithm } from "../../../types/additiveAlgorithm";
import { MenuItem } from "@mui/material";

const InputThresholdBeforeSearch = 1;
export interface IAdditiveAlgorithmAutocompleteBaseProps {
  label?: string;
  onSelectValue: (cow: IAdditiveEffectAlgorithm) => void;
  value?: IAdditiveEffectAlgorithm;
  error?: boolean;
  helperText?: any;
  companyId?: string;
  includeChemicals?: boolean;
  allAdditiveAlgorithms?: IAdditiveEffectAlgorithm[];
  fieldName?: string;
}
type OmittedAutocompleteProps =
  | "value"
  | "onChange"
  | "renderInput"
  | "options";
const NewOptionPrefix = "New Sample ID: ";

export type IAdditiveAlgorithmAutocompleteProps =
  IAdditiveAlgorithmAutocompleteBaseProps &
    Omit<
      AutocompleteProps<IAdditiveEffectAlgorithm, true, false, true>,
      OmittedAutocompleteProps
    >;

export default function AdditiveAlgorithmAutocomplete({
  value,
  onSelectValue,
  fullWidth = false,
  label,
  companyId,
  includeChemicals,
  allAdditiveAlgorithms,
  fieldName,
  ...otherProps
}: IAdditiveAlgorithmAutocompleteProps) {
  const [open, toggleOpen] = React.useState(false);
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [options, setOptions] = React.useState<IAdditiveEffectAlgorithm[]>(
    allAdditiveAlgorithms || []
  );
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searching, setSearching] = React.useState(false);

  async function search(searchText: string): Promise<void> {
    if (allAdditiveAlgorithms?.length) {
      setOptions(
        allAdditiveAlgorithms.filter(
          (d) =>
            !searchText ||
            d.name?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      return;
    }
    if (searchText.length < InputThresholdBeforeSearch) return;
  }

  const debouncedSearch = debounce(search, 300);

  const handleSelectOption = (val: IAdditiveEffectAlgorithm) => {
    onSelectValue(val);
  };

  React.useEffect(() => {
    if (allAdditiveAlgorithms?.length) {
      setOptions(allAdditiveAlgorithms);
    }
  }, [allAdditiveAlgorithms]);

  return (
    <>
      <Autocomplete<any, any, any, any>
        value={value}
        {...otherProps}
        options={options}
        clearOnBlur
        onChange={(e, v) => {
          handleSelectOption(v as IAdditiveEffectAlgorithm);
        }}
        getOptionLabel={(option) =>
          (option as IAdditiveEffectAlgorithm)?.name || ""
        }
        renderOption={(props, option: IAdditiveEffectAlgorithm) => {
          const effect = fieldName
            ? option?.additiveEffects?.find(
                (e) => e.chemicalFieldName == fieldName
              )
            : undefined;
          return (
            <MenuItem
              {...props}
              disabled={!!fieldName && !effect}
              sx={{ p: 0, borderTop: "solid 1px #ddd" }}
            >
              <Box sx={{ flex: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>{option?.name || ""}</div>
                  {fieldName && effect ? (
                    <Chip
                      size="small"
                      color="success"
                      label={`${fieldName} ${effect.operator} ${effect.value}${
                        effect.isPercentage ? "%" : ""
                      }`}
                    />
                  ) : fieldName && !effect ? (
                    <Chip
                      size="small"
                      color="warning"
                      label={`No effect on "${fieldName}"`}
                    />
                  ) : null}
                </div>
              </Box>
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <Box
            sx={{ display: "flex", alignItems: "center", position: "relative" }}
          >
            <TextField
              {...params}
              label={label}
              fullWidth
              placeholder={otherProps.placeholder}
              size="small"
              error={otherProps.error}
              helperText={otherProps.helperText}
              focused={value ? true : undefined}
            />
          </Box>
        )}
        onInputChange={(
          event: React.ChangeEvent<unknown>,
          searchText: string,
          reason: "input" | "reset" | "clear"
        ) => {
          if (
            searchText.length >= InputThresholdBeforeSearch &&
            reason === "input"
          ) {
            debouncedSearch(searchText);
            setSearchTerm(searchText);
          }
        }}
        filterOptions={(options, params) => {
          return options;
        }}
        popupIcon={
          searching ? (
            <CircularProgress size={25} color="primary" />
          ) : (
            <ExpandMoreIcon />
          )
        }
      />
    </>
  );
}
